import React, { useState, useEffect } from 'react';
import { useMainContext } from '../../../providers/MainProvider';
import { useTheme } from '@mui/material/styles';
import { Box, Button, InputBase, Collapse, Tabs, Tab, CircularProgress } from '@mui/material';
import { useGetCareerPaths } from '../../../api/careerPathApi';
import AnimPopIn from '../../../global_components/Animated/AnimPopIn';

export default () => {

	const getCareerPathsQuery = useGetCareerPaths();
	const { selectedCareerPathId, setSelectedCareerPathId } = useMainContext();
	const [ searchInput, setSearchInput ] = useState("");
	const theme = useTheme();

	return (
		<AnimPopIn>
			<Box
				sx={{
					position: "relative",
					width: "100%",
					height: "64px",
					display: "flex",
					padding: "0 32px 0 8px",
					borderRadius: "16px",
					justifyContent: "space-between",
					gap: "6px",
					alignItems: "center",
				}}
			>
				<Box sx={{
					width: "100%",
					display: "flex",
					position: "relative",
					margin: "12px",
					marginTop: "24px"
				}}>
					<Tabs
						sx={{ 
							maxWidth: "100%", 
							".MuiTabs-scrollButtons": {
								width: "24px",
								height: "32px",
								margin: "auto 0",
								borderRadius: "6px"
							},
							".MuiTab-root": {
								padding: "0 6px"
							}
						}}
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab disableRipple label={
							<Button
								variant="light-white-fill"
								onClick={()=>setSelectedCareerPathId(null)}
								sx={{ 
									fontWeight: 550,
									width: "64px",
									color: selectedCareerPathId===null && theme.palette.primary.main,
									fill: selectedCareerPathId===null && theme.palette.primary.main
								}}
							>
								All
							</Button>
						}/>
						{ getCareerPathsQuery.isSuccess ? getCareerPathsQuery.data.data.map((careerPath) => (
							<Tab disableRipple label={
							<Button 
								key={careerPath.name} 
								variant="light-white-fill"
								sx={{ 
									display: "flex", 
									alignItems: "center",
									gap: "6px",
									color: selectedCareerPathId===careerPath._id && theme.palette.primary.main,
									fill: selectedCareerPathId===careerPath._id && theme.palette.primary.main
								}}
								onClick={()=>setSelectedCareerPathId(careerPath._id)}
							>
								<Box 
									sx={{ minWidth: "20px", display: "flex", alignItems: "center" }}
									dangerouslySetInnerHTML={{ __html: careerPath.media.icon }}
								/>
								{careerPath.name}
							</Button>
							}/>
						)) : (
							<CircularProgress size={40} />
						)}
					</Tabs>
				</Box>
			</Box>
		</AnimPopIn>
	)
}
