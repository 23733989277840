import React, { useState, useEffect, useReducer } from 'react';
import { Box, Collapse } from '@mui/material';
import { AnimatePresence, AnimateSharedLayout, LayoutGroup, motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import Main from './Main';
import Question from './Question';
import Sidebar from './components/Sidebar';
import AnimWrapperFromHome from '../../global_components/Animated/AnimWrapperFromHome';
import Profile from '../../global_components/Profile';
import { useGetQuestions } from '../../api/questionApi';
import { useMainContext } from '../../providers/MainProvider';

export default () => {

	const theme = useTheme();
	const { selectedQuestion, setSelectedQuestion } = useMainContext();

	return (
		<AnimWrapperFromHome duration={0.45} delay={450} height="calc(100% - 50px)">
			<motion.div
				style={{
					width: "100%",
					height: "100%",
					padding: "26px 30px",
					display: "flex",
					gap: "20px"
				}}
			>	
				<motion.div 
					key={selectedQuestion ? selectedQuestion : "none"}
					style={{
						flex: 4,
						display: "flex",
						flexDirection: "column"
					}}
				>
					{ selectedQuestion ? (
						<Question 
							selectedQuestion={selectedQuestion}
							setSelectedQuestion={setSelectedQuestion}
						/>
					) : (
						<Main 
							selectedQuestion={selectedQuestion}
							setSelectedQuestion={setSelectedQuestion}
						/>
					)}
				</motion.div>
				<Box sx={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					gap: "16px"
				}}>
					<Box sx={{ backgroundColor: "white", borderRadius: "16px", padding: "16px", boxShadow: "rgba(149, 157, 165, 0.1) 0px 1px 6px" }}>
						<Profile />
					</Box>
					<Sidebar/>
				</Box>
			</motion.div>
		</AnimWrapperFromHome>
	)
}
