import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import mentoringImg from '../../../assets/misc_photos/mentoring.jpg';

export default () => {

	const theme = useTheme();

	return (
		<Box sx={{
			display: "flex",
			width: "100vw",
			justifyContent: "center"
		}}>
			<Box sx={{
				width: "1100px",
				display: "flex"
			}}>
				<Box sx={{
					width: "50%",
					height: "100px",
					color: "#3B3B3B"
				}}>
					<Box sx={{ 
						display: "flex", 
						alignItems: "center", 
						fontWeight: 500,
						marginBottom: "12px"
					}}>
						Faq & Questions 
						<Box sx={{ 
							width: "48px", 
							height: "2px", 
							marginLeft: "6px",
							backgroundColor: "#3B3B3B", 
							borderRadius: "2px", 
							opacity: 0.6 
						}}/>
					</Box>
					<Box sx={{ 
						fontWeight: 600, 
						fontSize: "36px" 
					}}>
						Have any questions?
					</Box>
					<Box sx={{ 
						fontWeight: 600, 
						fontSize: "36px",
						lineHeight: "100%",
						color: theme.palette.primary.main,
						marginBottom: "24px"
					}}>
						Find out more here!
					</Box>
					<Accordion sx={{
						boxShadow: "rgba(149, 157, 165, 0.2) 0px 1px 6px",
						marginBottom: "24px"
					}}>
						<AccordionSummary>
							<Box sx={{ 
								color: theme.palette.primary.main,
								fill: theme.palette.primary.main,
								fontWeight: 550,
								display: "flex",
								gap: "8px"
							}}>
								{/*<HeartIcon width="18px" />*/}
							Where do you run workshops?
						</Box>
						</AccordionSummary>
						<AccordionDetails>
							NSW only for now, but we are definitely open to going interstate in reaching a greater youth audience!
						</AccordionDetails>
					</Accordion>
					<Accordion sx={{
						boxShadow: "rgba(149, 157, 165, 0.2) 0px 1px 6px",
						marginBottom: "24px"
					}}>
						<AccordionSummary>
							<Box sx={{ 
								color: theme.palette.primary.main,
								fill: theme.palette.primary.main,
								fontWeight: 550,
								display: "flex",
								gap: "8px"
							}}>
								{/*<HeartIcon width="18px" />*/}
								How do we use your webapp?
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							It's easy! For students and teachers, all you have to do is sign up using your name, email, school, year level, and choose a password. Then, you will have access to our career trees, modules and online forum sections where you will be able to gain insights from our experienced and talented team!
						</AccordionDetails>
					</Accordion>
					<Accordion sx={{
						boxShadow: "rgba(149, 157, 165, 0.2) 0px 1px 6px",
						marginBottom: "24px"
					}}>
						<AccordionSummary>
							<Box sx={{ 
								color: theme.palette.primary.main,
								fill: theme.palette.primary.main,
								fontWeight: 550,
								display: "flex",
								gap: "8px"
							}}>
								{/*<HeartIcon width="18px" />*/}
								What does an Advance Career's workshop look like?
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							Our career workshops are unique because of our experienced team and thorough post-high school pathway breakdown sessions that cover a range of different career opportunities. Our team of university students are not far removed from a high school setting, and have experience gaining admission into careers such as medicine, law, business, arts, engineering and much more!
						</AccordionDetails>
					</Accordion>
				</Box>
				<Box sx={{ width: "32px" }} />
				<Box sx={{
					width: "50%",
					height: "100px",
				}}>
					<img 
						width="100%" 
						src={mentoringImg}
						style={{ borderRadius: "24px" }}
					/>
				</Box>
			</Box>
		</Box>
	)
}
