import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default ({ answer, setAnswer, value, isError, children }) => {

	const theme = useTheme();

	return ( 
		<Button 
			variant="heavy-outline" 
			color="error"
			sx={{ 
				width: "300px",
				color: answer===value ? ( isError ? theme.palette.error.light : theme.palette.primary.main) : theme.palette.secondary.light,
				borderColor: answer===value ? "" : "#E4E4E4",
				boxShadow: "rgba(149, 157, 165, 0.35) 0px 2px 16px",
			}}
			onClick={()=>setAnswer(value)}
		>
			{children}
		</Button>	
	)
}
