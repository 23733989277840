import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLearningContext } from './providers/LearningProvider';
import { useGetUser } from '../../api/userApi';
import { useGetModule } from '../../api/modulesApi';
import QuizFinished from './components/QuizFinished';
import QuizImg from '../../assets/misc_icons/ideas.png';
import QuestionTrueFalse from './components/QuestionTrueFalse';
import QuestionTextInput from './components/QuestionTextInput';
import QuestionFillInBlankSelect from './components/QuestionFillInBlankSelect';
import QuestionMultiSingle from './components/QuestionMultiSingle';
import QuestionMultiList from './components/QuestionMultiList';

export default ({ quizSlide }) => {

	const { completeQuiz, handleNextSlide, setError, selectedModuleId, quizFinished, setQuizFinished } = useLearningContext();
	const [ answers, setAnswers ] = useState(quizSlide.questions.map((question) => question.questionType === "FILL_IN_BLANK_SELECT" || question.questionType === "MULTI_LIST" ? [] : null));
	const [ validation, setValidation ] = useState(quizSlide.questions.map((question) => true));
	const theme = useTheme();
	const getUserQuery = useGetUser();
	const [ quizPassed, setQuizPassed ] = useState(false);

	const updateAnswers = (index, newValue) => {
		setAnswers((prev) => {
			const newAnswers = prev.slice();
			newAnswers.splice(index, 1, newValue);
			return newAnswers;
		}); 	
	}
	const updateAnswersNested = (indexOne, indexTwo, newValue) => {
		setAnswers((prev) => {
			const newAnswers = prev.slice();
			const newAnswersNest = prev[indexOne] ? prev[indexOne].slice() : [];
			for(let i = 0; i < indexTwo; i++) {
				if(newAnswersNest[i] === undefined) {
					newAnswersNest[i] = null;
				}
			}
			newAnswersNest[indexTwo] = newValue;
			newAnswers.splice(indexOne, 1, newAnswersNest);
			return newAnswers;
		}); 	
	}
	const validateAnswers = () => {
		const newValidation = validation.slice();
		const cleanedAnswers = []
		answers.forEach((answer, index) => {
			const correctAns = quizSlide.questions[index].answer;
			newValidation[index] = Array.isArray(answer) ? (
				answer.filter((a)=>a!==null).toString() === correctAns.toString()
			) : (
				typeof answer==='string' ? answer.toUpperCase()===correctAns.toUpperCase() : answer===correctAns
			)
		})
		setValidation(newValidation);
		return newValidation;
	}

	return (
		<Box sx={{ padding: "30px 40px", color: "#3b3b3b", display: "flex", flexDirection: "column", maxHeight: "100%", position: "relative" }}> 
			<Box sx={{ fontWeight: "700", fontSize: "30px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "20px", marginBottom: "60px" }}>
				Quiz
				<img src={QuizImg} style={{ width: "40px", height: "40px", transform: "translateY(3px)" }}/>
			</Box>
			{ quizFinished ? (
				<Box sx={{ height: "75%", transform: "translateY(-170px)" }}>
					<QuizFinished 
						handleClick={handleNextSlide}
						handleClick={()=>{ handleNextSlide(); setQuizFinished(false) }}
					/>
				</Box>
			) : (
				<Box sx={{ overflowY: "scroll", display: "flex", flexDirection: "column", gap: "30px" }}>
					{ quizSlide.questions.map((question, index) => {
						
						return (
							<Box sx={{ margin: "15px 0" }}>
								<Box sx={{ fontWeight: 600, fontSize: "24px", marginBottom: "10px", color: validation[index]===false ? theme.palette.error.light : "#3b3b3b" }}>Question {index+1}</Box>
								{ question.questionType === "TRUE_FALSE" && (
									<QuestionTrueFalse
										question={question.content}
										answers={answers}
										correctAnswer={question.answer}
										updateAnswers={updateAnswers}
										validation={validation}
										index={index}
									/>
								)}
								{ question.questionType === "FILL_IN_BLANK_SELECT" && (
									<>
										{(()=>console.log("multi select quiz is being rendered", answers))()}
										<QuestionFillInBlankSelect 
											question={question.content}
											answers={answers}
											correctAnswer={question.answer}
											options={question.options}
											updateAnswersNested={updateAnswersNested}
											validation={validation}
											index={index}
										/>
									</>
								)}
								{ question.questionType === "TEXT_INPUT" && (
									<QuestionTextInput 
										question={question.content}
										answers={answers}
										correctAnswer={question.answer}
										updateAnswers={updateAnswers}
										validation={validation}
										index={index}
									/>
								)}
								{ question.questionType === "MULTI_SINGLE" && (
									<QuestionMultiSingle
										question={question.content}
										answers={answers}
										correctAnswer={question.answer}
										updateAnswers={updateAnswers}
										options={question.options}
										validation={validation}
										index={index}
									/>
								)}
								{ question.questionType === "MULTI_LIST" && (
									<QuestionMultiList
										question={question.content}
										answers={answers}
										correctAnswer={question.answer}
										updateAnswersNested={updateAnswersNested}
										options={question.options}
										validation={validation}
										index={index}
									/>
								)}
							</Box>
						)
					})}
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Button 
							variant="contained" 
							sx={{ width: "300px", margin: "40px", height: "46px"}}
							onClick={()=>{
								const validatedAnswers = validateAnswers();
								let allCorrect = true;
								validatedAnswers.forEach((answer) => { allCorrect = allCorrect && answer });
								if(allCorrect) {
									completeQuiz()
								}
								else {
									setError({ show: true, msg: "You've entered in one or more wrong answers!" });
								}
							}}
						>
								Check your answers
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	)
}
