import {Box, Button} from '@mui/material';
import { useTheme } from '@mui/material/styles'
// import {  } from '@mui/icons-material';
import { ReactComponent as XIcon } from '../../../assets/misc_icons/x-solid.svg';
import { ReactComponent as CheckIcon } from '../../../assets/misc_icons/check-solid.svg';
import { usePatchUserPicture } from '../../../api/userApi';


export default ({setButtonShow, setPreview, setImage, image}) => {

    const patchUserPicture = usePatchUserPicture();
    const theme = useTheme();

    const handleCancel = () => {
        setButtonShow(false);
        setPreview(null);
    }

    const handleUpload = () => {
        const formData = new FormData();
        formData.append("picture", image)
        formData.append("picturePath", image.name)
        patchUserPicture.mutate({
            data: formData
        })
    }

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width: '150px', 
        }}>
            <Button
                variant="light-outline" 
                sx={{
                    height: '50px',
                    width: '50px',
                    borderRadius: '50%',
                    maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px',
                    '& svg': { transition: "all 200ms ease-in-out", maxWidth: '25px', maxHeight: '25px', minWidth: '25px', minHeight: '25px'},
                    "&:hover": { "svg" : { fill: theme.palette.primary.main }, borderColor: theme.palette.primary.main}
                }}
                onClick={handleCancel}
            >
                <XIcon/>
            </Button>
            <Button
                variant="light-outline" 
                sx={{
                    height: '50px',
                    width: '50px',
                    borderRadius: '50%',
                    maxWidth: '50px', maxHeight: '50px', minWidth: '50px', minHeight: '50px',
                    '& svg': { transition: "all 200ms ease-in-out", maxWidth: '25px', maxHeight: '25px', minWidth: '25px', minHeight: '25px'},
                    "&:hover": { "svg" : { fill: theme.palette.primary.main }, borderColor: theme.palette.primary.main}
                }}
                onClick={handleUpload}
            >
                <CheckIcon/>
            </Button>
        </Box>
    )
}