import { Box, CircularProgress } from '@mui/material';
import CareerSpecCard from './CareerSpecCard';
import NoCareerSpecsCard from './NoCareerSpecsCard';
import { getCareerSpecProgress } from '../../../api/careerSpecialisationApi';
import { useGetUser } from '../../../api/userApi';

export default ({ getCareerSpecsQuery, selectedCareerSpecId, setSelectedCareerSpecId, setRightPanelContent }) => {

	const getUserQuery = useGetUser();

	return (
		<Box sx = {{padding: '0 20px 0 20px', minHeight: "0px", overflowY: "scroll"}}>
			{ getCareerSpecsQuery.isSuccess ? (	
				getCareerSpecsQuery.data.data.length > 0 ? (
					getCareerSpecsQuery.data.data.map((spec, index) => 
						<CareerSpecCard
							index={index}
							id={spec._id}
							selectedCareerSpecId={selectedCareerSpecId}
							setSelectedCareerSpecId={setSelectedCareerSpecId}
							setRightPanelContent={setRightPanelContent}
							name={spec.name}
							lessons={spec.moduleIds.length}
							desc={spec.descBrief}
							image={spec.media.cardMedia}
							color={spec.color}
							progress={getCareerSpecProgress(getUserQuery.data.data, spec)} 
							key={index}
							modules={spec.moduleIds}/>
					)) : (
						<Box sx={{ 
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%", 
							height: "100%"
						}}>
							{(()=>console.log(getCareerSpecsQuery))()}
							<NoCareerSpecsCard/>
						</Box>
					)
				) : (
					<Box sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}>
						<CircularProgress
							variant="indeterminate"
							size="60px"
						/>
					</Box>
				)
			}
		</Box>
	)
}
