import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ModuleSlidePopup from './ModuleSlidePopup';
import ModuleQuizPopup from './ModuleQuizPopup';

const contentColumns = [
	{ field: 'title', headerName: "Title", width: 250 },
	{ field: 'htmlContent', headerName: "HTML Code", width: 650 }
];

const quizColumns = [
	{ field: 'title', headerName: "Title", width: 350 },
	{ field: 'afterSlideNum', headerName: "After Content Slide Number", width: 300 },
	{ field: 'numQuestions', headerName: "Number of questions", width: 250 }
];

export default ({form, setForm}) => {

	const [ html, setHTML ] = useState("html will show here");
	const [ title, setTitle ] = useState("Introduction to...");
	const [ quiz, setQuiz ] = useState({
		afterSlideNum: 0,
		questions: [],
		title: ""
	});
	const [ showSlidePopup, setShowSlidePopup ] = useState(false);
	const [ showQuizPopup, setShowQuizPopup ] = useState(false);

	return (
		<>
			<AnimatePresence>
			{ showSlidePopup && (
				<ModuleSlidePopup 
					setOpen={setShowSlidePopup}
					form={form}
					setForm={setForm}
					title={title}
					setTitle={setTitle}
					html={html}
					setHTML={setHTML}
				/>
			)}
			{ showQuizPopup && (
				<ModuleQuizPopup 
					setOpen={setShowQuizPopup}
					form={form}
					setForm={setForm}
					quiz={quiz}
					setQuiz={setQuiz}
				/>
			)}
			</AnimatePresence>
		<Box>
			<Box sx={{ margin: "10px" }}>
				<Box>Content/Slides</Box>
				<Button 
					variant="outlined" 
					sx={{ width: "200px" }}
					onClick={()=>setShowSlidePopup(true)}
				>
					Add New Slide
				</Button>
			</Box>
			<Box sx={{ height: 400, width: '100%' }}>
				<DataGrid
					rows={form.content.map((content, index)=>({...content, id: index}))}
					columns={contentColumns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					experimentalFeatures={{ newEditingApi: true }}
				/>
			</Box>
			<Box sx={{ margin: "10px" }}>
				<Box>Quizzes</Box>
				<Button 
					variant="outlined" 
					sx={{ width: "200px" }}
					onClick={()=>setShowQuizPopup(true)}
				>
					Add New Quiz 
				</Button>
			</Box>
			<Box sx={{ height: 400, width: '100%' }}>
				<DataGrid
					rows={form.quizzes.map((quiz, index)=>({...quiz, id: index}))}
					columns={quizColumns}
					pageSize={5}
					rowsPerPageOptions={[5]}
					experimentalFeatures={{ newEditingApi: true }}
				/>
			</Box>
    </Box>
		</>
	)
}

