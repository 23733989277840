import { Box } from '@mui/material';
import { motion } from 'framer-motion';

export default () => {
	return (
		<motion.div 
			style={{
				width: "100%",
				height: "700px",
				backgroundColor: "black",
				position: "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}
			initial={{ opacity: 0 }}
			whileInView={{ opacity: 1 }}
		>
			<Box sx={{
				borderRadius: "24px",
				overflow: "hidden"
			}}>
				<iframe 
					width="840" 
					height="472" 
					src="https://www.youtube.com/embed/yVVf9Mna2Is" 
					title="YouTube video player" 
					frameborder="0" 
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
					allowfullscreen
				>
				</iframe>
			</Box>
			<motion.div
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "black",
					pointerEvents: "none"
				}}
				initial={{ opacity: 1 }}
				whileInView={{ opacity: 0 }}
				transition={{ delay: 2, duration: 0.2 }}
			>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "20px"
				}}>
					<Box sx={{ 
						color: "white",
						fontWeight: 550,
						fontSize: "36px",
						lineHeight: "120%",
						textAlign: "center"
					}}>
						Our Mission
					</Box>
					<Box sx={{
						color: "white",
						fontWeight: 400,
						fontSize: "18px",
						textAlign: "center",
						width: "600px"
					}}>
					To provide Australian youth with the tools and information necessary to grow and realise their potential as they transition into life post-high school, and to empower them to make prudent decisions to be leaders in a rapidly evolving world.</Box>
				</Box>
			</motion.div>
		</motion.div>
	)
}
