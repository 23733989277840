import LearningProvider from './providers/LearningProvider';
import Learning from './Learning';

export default () => {

	return (
		<LearningProvider>
			<Learning/>
		</LearningProvider>
	)
}
