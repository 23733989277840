import React, { useState } from 'react';
import { Box, Collapse, Button } from '@mui/material';
import ForumProfile from './ForumProfile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default ({ getRepliesQuery }) => {

	const [ show, setShow ] = useState(false);
	if(getRepliesQuery.data.data.length <= 2) {
		return getRepliesQuery.data.data.map((reply, index) => (
			<>
				<Box sx={{ height: "0.5px", width: "100%", backgroundColor: "#A4A4A4", margin: "4px auto" }}/>
				<Box sx={{ 
					display: "flex",
					alignItems: "center",
					transform: "scale(0.75) translateX(-100px)" 
				}}>
					<ForumProfile 
						userId={reply.userId}
						postDate={reply.createdAt}
					/>
					{ reply.description }
				</Box>
			</>
		))
	}
	else {
		const repliesFirstTwo = getRepliesQuery.data.data.slice(0, 2);
		const repliesAfterTwo = getRepliesQuery.data.data.slice(2, getRepliesQuery.data.data.length);
		return (
		<> 
			{ repliesFirstTwo.map((reply) => (
			<>
				<Box sx={{ height: "0.5px", width: "100%", backgroundColor: "#A4A4A4", margin: "4px auto" }}/>
				<Box sx={{ 
					display: "flex",
					alignItems: "center",
					transform: "scale(0.75) translateX(-100px)" 
				}}>
					<ForumProfile 
						userId={reply.userId}
						postDate={reply.createdAt}
					/>
					{ reply.description }
				</Box>
			</>
			))}
			<Collapse in={show}>
				{ repliesAfterTwo.map((reply) => (
					<>
						<Box sx={{ height: "0.5px", width: "100%", backgroundColor: "#A4A4A4", margin: "4px auto" }}/>
						<Box sx={{ 
							display: "flex",
							alignItems: "center",
							transform: "scale(0.75) translateX(-100px)" 
						}}>
							<ForumProfile 
								userId={reply.userId}
								postDate={reply.createdAt}
							/>
							{ reply.description }
						</Box>
					</>
				)
			)}
			</Collapse>
			<Button 
				variant="contained"
				sx={{ 
					padding: "2px",
					width: "64px",
					margin: "auto"
				}}
				onClick={()=>setShow((prev)=>!prev)}
			>
				<ExpandMoreIcon sx={{
					transform: `rotate(${show ? "180deg" : "0"})`,
					transition: "all 200ms ease-out"
				}}/>
			</Button>
		</>
	)}
}
