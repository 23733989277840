import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from './axiosClient';

// Getting all questions
export const useGetQuestions = ({ category, results, page, sort }) => useQuery({
    queryKey: ['questions', category, results, page, sort],
    queryFn: async () => await axiosClient.get(`questions?sort=${sort}&page=${page}&results=${results}` + (category ? `&category=${category}` : ""))	
})

/*
export const useGetQuestionCategories = () => useQuery({
	queryKey: ['questionCategories'],
	queryFn: async () => await axiosClient.get("questions?categoriesOnly=true")
});
*/

// Getting user questions
export const useGetUserQuestions = (userId) => useQuery({
		queryKey: ['userQuestions', userId],
		queryFn: async () => await axiosClient.get(`questions/${userId}/userQuestions`),
})


export const useCreateQuestion = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ data }) => await axiosClient.post('questions', data, { 
						headers: {
								'Content-type': 'multipart/form-data'
						}
				}),
        onSuccess: (data) => {
					queryClient.invalidateQueries(['questions']);
					setTimeout(() => { 
						axiosClient.post("user/activity", {
							type: "QUESTION",
							activityId: data.data._id
						});	
					}, 500);
        }
    })
}

export const useGetSelectedQuestion = (id) => useQuery({
    queryKey: ['selectedQuestion', id],
    queryFn: async () => await axiosClient.get(`questions/${id}`)
})

export const useGetLikedQuestion = (id) => useQuery({
		queryKey: ['likedQuestion', id],
		queryFn: async () => await axiosClient.get(`questions/${id}/likedQuestions`),
})

export const useAnswerQuestion = (id) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({comments}) => {
            await axiosClient.patch(`questions/${id}/comment`, {
                comments
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['selectedQuestion'])
        }
    })
}

export const useViewQuestion = (id) => useMutation({
    mutationFn: async () => {
        await axiosClient.get(`questions/${id}/viewQuestion`)
    }
})

export const useLikeQuestion = (id) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            await axiosClient.get(`questions/${id}/likeQuestion`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['selectedQuestion'])
        }
    })
}

export const useGetAdminQuestions = () => useQuery({
	queryKey: ['adminQuestions'],
	queryFn: async () => await axiosClient.get('questions/admin')
});

export const usePatchAdminQuestion = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ id, data }) => {
			await axiosClient.patch(`questions/admin/${id}`, data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['adminQuestions'])
		}
	});
}

export const useDeleteAdminQuestion = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ id }) => {
			await axiosClient.delete(`questions/admin/${id}`);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['adminQuestions'])
		}
	});
}

