import { useState, useContext } from 'react';
import { Button, Box, InputBase, Typography, Select, MenuItem } from '@mui/material';
import { AdminContext } from '../../Admin';

export default ({ quiz, setQuiz }) => {

	const [ question, setQuestion ] = useState({});
	const { openBar, setOpenBar, barContents, setBarContents } = useContext(AdminContext);

	return (
		<Box>
			<Typography>Text Input Question</Typography>
			Question text
			<InputBase onChange={(e)=>setQuestion((prev) => (
				{ ...prev, content: e.target.value }
			))}/>
			<Box>Answer (Case insensitive)</Box>
			<InputBase onChange={(e)=>setQuestion((prev) => (
				{ ...prev, answer: e.target.value }
			))}/>
			<Button 
				variant="outlined"
				onClick={()=>{ 
					if(!question.content || !question.answer) { 
						setBarContents({ msg: "Something is missing, please try again", severity: "error" });
						setOpenBar(true);
						return;
					};
					setQuiz((prev)=>({
						...prev, questions: [...prev.questions, {
							questionType: "TEXT_INPUT",
							content: question.content,
							options: [],
							answer: question.answer
						}]
					}))
				}}
			>
				Add to quiz
			</Button>
		</Box>
	)	
}

