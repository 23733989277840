import { useState, useMemo, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from '../../../providers/MainProvider';
import { useGetModule, getModuleProgress, updateModuleProgress } from '../../../api/modulesApi';
import { useGetUser, usePatchUser } from '../../../api/userApi';

const LearningContext = createContext();

export const PageStates = {
	HOME_SPECS: 0,
	HOME_MODULES: 1,
	CONTENT: 2
}

export default ({children}) => {

	const navigate = useNavigate();
	const { selectedCareerSpecId, setSelectedCareerSpecId, selectedModuleId, setSelectedModuleId } = useMainContext();

	const getModuleQuery = useGetModule({ moduleId: selectedModuleId });
	const getUserQuery = useGetUser();
	const patchUserMutation = usePatchUser();

	const [ swiperSlide, setSwiperSlide ] = useState(0);
	const [ error, setError ] = useState({ show: false, msg: "" });
	const [ pageState, setPageState ] = useState(
		selectedCareerSpecId ? ( 
			selectedModuleId ? PageStates.CONTENT : PageStates.HOME_MODULES
		) : PageStates.HOME_SPECS
	);
	const [ quizFinished, setQuizFinished ] = useState(false);
	const [ moduleFinished, setModuleFinished ] = useState(false);

	const completeQuiz = () => {
		const newData = updateModuleProgress({ user: getUserQuery.data.data, careerSpecId: selectedCareerSpecId, moduleId: selectedModuleId, slideNum: swiperSlide })
		patchUserMutation.mutate(newData);
		setQuizFinished(true);
	}
	const completeModule = () => {
		const newData = updateModuleProgress({ user: getUserQuery.data.data, careerSpecId: selectedCareerSpecId, moduleId: selectedModuleId, slideNum: swiperSlide })
		patchUserMutation.mutate(newData);
		setPageState(PageStates.HOME_MODULES);
		setModuleFinished(false);
	}
	const handleCareerSpecClick = (careerSpecId) => {
		setSelectedCareerSpecId(careerSpecId);
		setPageState(PageStates.HOME_MODULES);
	}
	const handleModuleClick = (selectedModuleId) => {
		setSelectedModuleId(selectedModuleId);
		setPageState(PageStates.CONTENT);
	}
	const handleBackClick = () => {
		setPageState((prev) => {
			console.log("HANDLED BACK CLICK");
			console.log(prev);
			if(prev===0) {
				navigate("/app/careerpath");	
				setSelectedCareerSpecId(null);
				setSelectedModuleId(null);
				return prev
			}
			if(prev===PageStates.CONTENT) {
				console.log("A");
				setSelectedModuleId(null);
			}
			if(prev===PageStates.HOME_MODULES) {
				console.log("B");
				setSelectedCareerSpecId(null);
			}
			return prev-1;
		});
	}

	const handleNextSlide = () => {
		if(getModuleQuery.data.slides[swiperSlide].type==="QUIZ" && 
			getModuleProgress(getUserQuery.data.data, selectedModuleId) < swiperSlide) {
			setError({ show: true, msg: "Must complete quiz before moving on" });
			return;
		}
		setSwiperSlide((prev) => prev+1 >= getModuleQuery.data.slides.length ? prev : prev + 1);
	}

	const handlePrevSlide = () => { 
		setSwiperSlide((prev) => prev === 0 ? 0 : prev-1);
	}
	
	const handleExitClick = () => {
		navigate("/app/careerpath");	
	}

	const value = {
		pageState,
		swiperSlide,
		setSwiperSlide,
		error,
		setError,
		quizFinished,
		setQuizFinished,
		moduleFinished,
		setModuleFinished,
		completeQuiz, 
		completeModule,
		handleCareerSpecClick,
		handleModuleClick,
		handleBackClick,
		handleExitClick,
		handlePrevSlide,
		handleNextSlide
	}
	const valueMemo = useMemo(()=>value, [{...value}])

	return (
		<LearningContext.Provider value={valueMemo}>
			{children}
		</LearningContext.Provider>
	)
}

export const useLearningContext = () => useContext(LearningContext);
