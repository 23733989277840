import { Box } from '@mui/material';
import { styled } from '@mui/system';

const GradientBar = styled('div')(({ width }) => ({
  background: `repeating-linear-gradient(to right, #348AC7 0%, #5858b0 50%, #348AC7 100%)`,
  width,
  backgroundSize: '200% auto',
  backgroundPosition: '0 100%',
  animation: 'gradient 1.5s infinite',
  animationFillMode: 'forwards',
  animationTimingFunction: 'linear',
  color: '#ffffff00',
  height: '100%',
  borderRadius: '6px',
  '@keyframes gradient': {
    '0%': { backgroundPosition: '0 0' },
    '100%': { backgroundPosition: '-200% 0' },
  },
}));

export default ({ progress, sx }) => (
  <Box sx={{
    display: "flex",
    position: "relative",
    borderRadius: "6px",
    backgroundColor: "#348AC722",
    ...sx
  }}>
    <GradientBar width={progress} />
  </Box>
);

