import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import MainProvider from "./providers/MainProvider";
import FlowProvider from "./providers/FlowProvider";
import { theme } from './providers/Theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

function App() {

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			}
		}
	});

  return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<MainProvider>
					<BrowserRouter>
					<FlowProvider>
						<AppRoutes />
						<ReactQueryDevtools />
					</FlowProvider>
					</BrowserRouter>
				</MainProvider>
			</ThemeProvider>
		</QueryClientProvider>
  );
}

export default App;
