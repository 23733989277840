import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Accordion, AccordionSummary, AccordionDetails  } from '@mui/material';
import QuestionSummarySidebar from './QuestionSummarySidebar';
import AnimPopIn from '../../../global_components/Animated/AnimPopIn';
import { ReactComponent as HeartIcon } from '../../../assets/misc_icons/heart-solid.svg';
import { ReactComponent as PenIcon } from '../../../assets/misc_icons/pen-solid.svg';
import { useGetLikedQuestion, useGetUserQuestions } from '../../../api/questionApi';
import { useGetUser } from '../../../api/userApi';

const questions = [
	{
		heading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean molestie maximus erat sit amet pretium?",
		category: "Engineering",
		icon: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z'/></svg>",
		comments: 5,
		views: 300
	},
	{
		heading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean molestie maximus erat sit amet pretium?",
		category: "Engineering",
		icon: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z'/></svg>",
		comments: 5,
		views: 300
	}
]

export default () => {

	const [ likedOpen, setLikedOpen ] = useState(false);
	const [ myPostsOpen, setMyPostsOpen ] = useState(false);
	const theme = useTheme();

	const getUserQuery = useGetUser();
	const getLikedQuestionQuery = useGetLikedQuestion(getUserQuery.isSuccess && getUserQuery.data.data._id);
	const getUserQuestionsQuery = useGetUserQuestions(getUserQuery.isSuccess && getUserQuery.data.data._id);
	
	// getLikedQuestionQuery.isSuccess && console.log(getLikedQuestionQuery.data.data)

	return (
		<Box>
			<Accordion expanded={likedOpen} onChange={()=>setLikedOpen((prev)=>!prev)}>
				<AccordionSummary>
					<Box sx={{ 
						color: theme.palette.primary.main,
						fill: theme.palette.primary.main,
						fontWeight: 550,
						display: "flex",
						gap: "8px"
					}}>
						<HeartIcon width="18px" />
						Liked
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Box sx={{ display: "flex", flexDirection: "column"}}>
					{ getLikedQuestionQuery.isSuccess && getLikedQuestionQuery.data.data.map((question, index) => (
						<>
							{ index > 0 && (
								<Box sx={{ height: "0.5px", width: "100px" }}/>
							)}
							<QuestionSummarySidebar
								heading={question.title}
							/>
						</>
					))}
					</Box>
				</AccordionDetails>
			</Accordion>
			<Box sx={{ height: "10px" }}/>
			<Accordion expanded={myPostsOpen} onChange={()=>setMyPostsOpen((prev)=>!prev)}>
				<AccordionSummary>
					<Box sx={{ 
						color: theme.palette.primary.main,
						fill: theme.palette.primary.main,
						fontWeight: 550,
						display: "flex",
						gap: "8px"
					}}>
						<PenIcon width="18px" />
						My Questions
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Box sx={{ display: "flex", flexDirection: "column"}}>
					{getUserQuestionsQuery.isSuccess && getUserQuestionsQuery.data.data.map((question, index) => (
						<>
							{ index > 0 && (
								<Box sx={{ height: "0.5px", width: "100px" }}/>
							)}
							<QuestionSummarySidebar
								heading={question.title}
							/>
						</>
					))}
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	)
}
