import { Box} from '@mui/material'

const dayList = (name, index) => {

    const day = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

    // If want ticks
    if (name) {
        return(
        <Box>
            ✓
        </Box>
        )
    }

    return (day[index]);
}

export default ({name, index}) => {

    if (!name) {
        var color = '#F3F7FF'
    } else {
        var color = '#F7DB36'
    }

    return(
        <Box sx = {{
            width: '45px', 
            height: '45px', 
            backgroundColor: color, 
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 10,
            }}
        >
            <h2 style = {{fontWeight: 600}}>{ dayList(name, index) }</h2>
        </Box>
    )
}