import { useState, useContext } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material'
import Popup from '../../../global_components/Animated/Popup';
import { AdminContext } from '../Admin';

export default ({html, setHTML, title, setTitle, form, setForm, setOpen}) => {

	const { openBar, setOpenBar, barContents, setBarContents } = useContext(AdminContext);

	return (
		<Popup setOpen={setOpen}>
			<Box sx={{
				display: "flex",
				width: "100%",
				gap: "20px"
			}}>
				<Box sx={{ 
					backgroundColor: "white",
					padding: "32px",
					borderRadius: "16px",
					boxShadow: "rgba(100, 100, 111, 0.05) 0px 7px 29px 0px",
					display: "flex",
					flexDirection: "column",
					gap: "12px",
					width: "100%"
				}}>
					<TextField
						id="outlined-multiline-static"
						label="Page title"
						onChange={(e)=>setTitle(e.target.value)}
						sx={{ width: "100%" }}
					/>
					<TextField
						id="outlined-multiline-static"
						label="HTML Code"
						multiline
						rows={4}
						onChange={(e)=>setHTML(e.target.value)}
						sx={{ width: "100%" }}
					/>
					<Button 
						variant="outlined" 
						sx={{ width: "160px" }}
						onClick={() => { 
							if(!html) {
								setBarContents({ msg: "You are missing HTML code to submit into the lesson", severity: "error" });
								setOpenBar(true);
								return;
							}
							if(!title) {
								setBarContents({ msg: "You are missing a heading for this page", severity: "error" });
								setOpenBar(true);
								return;
							}
							setForm((prev) => ({ ...prev, content: [...prev.content, { title, htmlContent: html }]}));
							setOpen(false);
						}}
					>
						Add to modules
					</Button>
				</Box>
				<Box sx={{ 
					minWidth: "900px",
					height: "600px",
					backgroundColor: "white",
					borderRadius: "16px",
					boxShadow: "rgba(100, 100, 111, 0.05) 0px 7px 29px 0px",
					overflow: "hidden",
					position: "relative"
				}}>
					<Box 
						sx={{ width: "100%", height: "100%", position: "relative"}}
						dangerouslySetInnerHTML={{__html: html}} 
					/>
				</Box>
			</Box>
		</Popup>
	)
}
