import { useState } from 'react';
import { Tabs, Tab, Box, ButtonBase, Collapse } from '@mui/material';
import { ReactComponent as LogoIcon } from '../../assets/sidebar_icons/logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/sidebar_icons/house-user-solid.svg'; 
import { ReactComponent as SuitcaseIcon } from '../../assets/sidebar_icons/suitcase-solid.svg';
import { ReactComponent as ChatIcon } from '../../assets/sidebar_icons/message-solid.svg';
import { ReactComponent as TreeIcon } from '../../assets/sidebar_icons/tree-solid.svg';
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { useMainContext } from "../../providers/MainProvider";
import { useGetCareerPaths } from "../../api/careerPathApi";

const MenuButton = ({ icon, name, path, handleClick, customSelected, children }) => {

	const location = useLocation();
	const open = location.pathname === path;
	const { setPrevPathname } = useMainContext();
	const selected = customSelected ? customSelected : path === location.pathname;

	return (
		<Box sx={{ width: "100%", padding: '0 20px 0 20px', marginBottom: '5px'}}>
			<Link 
				style={{ textDecoration: "none" }}
				to={path}
			>
				<ButtonBase 
					variant="text"
					sx={{
						backgroundColor: selected ? "#F3F7FF" : "none",
						color: selected ? "#1B56BA" : "#A4A4A4",
						"& svg": {
							width: "24px",
							fill: selected ? "#1B56BA" : '#A4A4A4',
							transition: "all 250ms ease-in-out !important"
						},
						"&:hover": {
							backgroundColor: "#F3F7FF",		
							color: "#1B56BA",
							transition: "all 250ms ease-in-out",
							"& svg": {
								transition: "all 250ms ease-in-out",
								fill: "#1B56BA",
							}
						},
						width: '100%',
						borderRadius: '15px',
						padding: '10px 0 10px 28px'
					}}
					onClick={() => {
						setPrevPathname(location.pathname);
						handleClick && handleClick();
					}}
				>
					<Box sx={{ padding: "4px 10px", display: "flex", width: "100%", alignItems: "center" }}>
						{icon}<Box sx={{ marginLeft: "8px", fontWeight: 530, fontSize: "13px" }}>{name}</Box>
					</Box>
				</ButtonBase>
			</Link>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<Box sx={{ paddingLeft: "10px"}}>
				{children}
				</Box>
			</Collapse>
		</Box>
	)
}

export default ({width="300px"}) => {
	
	const { prevPathname, selectedCareerPathId, openPathPopup, setSelectedCareerPathId, setOpenPathPopup } = useMainContext();
	const getCareerPathsQuery = useGetCareerPaths();
	const navigate = useNavigate();

	return (
		<Box sx={{
			width,
			minWidth: "300px",
			height: "100vh",
		}}>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Box 
					sx={{ display: "flex", alignItems: "center", margin: "54px", cursor: "pointer" }}
					onClick={()=>navigate("/")}
				>
					<Box sx={{ marginRight: "12px" }}><LogoIcon width="40px" height="40px" /></Box>					
					<Box sx={{ color: "#3B3B3B", fontWeight: 600, fontSize: "20px", lineHeight: "100%" }}>Advance</Box> 
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column"}}>
					<MenuButton icon={<HomeIcon />} name="Home" path="/app" />
					<MenuButton icon={<SuitcaseIcon />} name="Career Paths" path="/app/careerpath">
						<Tabs
							sx={{ 
								minWidth: "100%",
								maxHeight: "360px", 
								margin: "2px auto",
								".MuiButtonBase-root.MuiTab-root": {
									padding: "0"
								},
								".MuiTabs-scrollButtons": {
									width: "36px",
									height: "16px",
									margin: "4px auto",
									padding: "2px",
									borderRadius: "6px",
									opacity: "0.6 !important"
								},
								".MuiTab-root": {
									padding: "0 6px"
								},
								".Mui-disabled": {
									opacity: "0.25 !important"
								},
							}}
							variant="scrollable"
							orientation="vertical"
							scrollButtons="auto"
						>
						{ getCareerPathsQuery.isSuccess && getCareerPathsQuery.data.data.map((careerPath) => (
							<Tab sx={{ margin: "0", padding: "0" }}disableRipple label={
								<MenuButton 
									icon={<Box dangerouslySetInnerHTML={{ __html: careerPath.media.icon }}/>} 
									name={careerPath.name} 
									handleClick={() => {
										setSelectedCareerPathId(careerPath._id);
										setOpenPathPopup(true);
									}}
									customSelected={openPathPopup && selectedCareerPathId===careerPath._id}
								/>
							}/>
						)) 
						}
						</Tabs>
					</MenuButton>
					<MenuButton icon={<TreeIcon />} name="Career Tree" path="/app/careertree"/>
					<MenuButton icon={<ChatIcon />} name="Q & A" path="/app/qa"/>
				</Box>
			</Box>
		</Box>
	);
}
