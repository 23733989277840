import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Box, Button } from '@mui/material';

export default ({name, desc, picture, color, index, id, selectedCareerPathId, setSelectedCareerPathId, setOpen, setSelectedCareerPath}) => {

	const [ delay, setDelay ] = useState(0.6+index*0.15);
	useEffect(() => {
		setTimeout(()=>{ setDelay(0)}, 250);
	}, []);

	return (	
		<Box sx={{ height: "1000px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
			<motion.div 
				style={{
					width: "280px", 
					height: "560px", 
					margin: "auto 30px", 
					backgroundColor: color,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					borderRadius: "30px",
					padding: "30px 20px 20px 20px",
					justifyContent: "stretch",
					color: "white",
					boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 15px -3px'
				}}
				initial={{ translateY: -50, opacity: 0 }}
				animate={{ translateY: 0, opacity: 1 }}
				transition={{ delay, duration: 0.6, type: 'spring', bounce: 0.5 }}
				whileHover={ delay===0 ? { translateY: -5, transition: { duration: 0.2 }} : {} }
			>
				<Box sx={{
					flex: 3,
					padding: "30px 5px",
					display: "flex", 
					justifyContent: "center",
					alignItems: "center"
				}}>
					<img src={ picture } style={{ width: "100%"}} />
				</Box>
				<Box sx={{display: "flex", flexDirection: "column", alignItems: "center", flex: 2}}>
					<Box sx={{fontWeight: 600, fontSize: "30px" }}>{name}</Box>
					<Box sx={{padding: "5px", textAlign: "center", height: "120px", overflow: "hidden"}}>{desc}</Box>
					<Button 
						variant="filled-bold"
						onClick={()=> {
							setOpen(true);
							setSelectedCareerPathId(id);
							setSelectedCareerPath(name);
						}}
						sx={{ fontWeight: 550 }}
					>
						Get Started
					</Button>
				</Box>
			</motion.div>
		</Box>
	)
}
