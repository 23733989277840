import { useState, useEffect, useContext } from 'react';
import { Checkbox, Button, Tooltip, Box, InputBase, Typography, Select, MenuItem } from '@mui/material';
import { AdminContext } from '../../Admin';

export default ({ quiz, setQuiz,  }) => {

	const { openBar, setOpenBar, barContents, setBarContents } = useContext(AdminContext);
	const [ question, setQuestion ] = useState({ options: [], answer: [] });
	const [ numDropdowns, setNumDropdowns ] = useState(0);
	const [ checkBoxSelected, setCheckboxSelected ] = useState(question.options.map((option)=>false));

	return (
		<Box>
			<Typography>Fill in the blank (with select)</Typography>
			Question text - place * for dropdown select
			<Tooltip title="Place a * asterisk wherever a dropdown/select menu appears">
				<InputBase
					onChange={(e)=>{ 
						setNumDropdowns(e.target.value.split('').filter((content)=>content==='*').length);
						setQuestion((prev)=>({ ...prev, content: e.target.value }));
					}} 
				/>
			</Tooltip>
			<Box>Answers</Box>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				{ Array.from(Array(numDropdowns).keys()).map((questionIndex) => (
					<Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
						Options for dropdown {questionIndex+1}
						{ Array.from(Array(4).keys()).map((index) => (
							<Box sx={{ display: "flex", gap: "4px" }}>
								<InputBase 
									onChange={(e)=>{ 
										setQuestion((prev)=> { 
											const newOptionsArr = [...prev.options];
											if(!newOptionsArr[questionIndex]) {
												newOptionsArr[questionIndex] = [];
											}
											newOptionsArr[questionIndex][index] = e.target.value;
											return {
												...prev, options: newOptionsArr
											}
										});
										setCheckboxSelected((prev)=>{
											const newArr = [...prev];
											newArr[questionIndex] = false;	
											return newArr;
										});	
										console.log(checkBoxSelected);
									}}
								/>
								<Button
									onClick={(e)=>{ 
										setQuestion((prev)=> {
											if(!question.options[questionIndex]) {
												return prev;
											};	
											const newAnswersArr = prev.answer ? [...prev.answer] : [];
											newAnswersArr[questionIndex] = prev.options[questionIndex][index];
											return { ...prev, answer: newAnswersArr }
										});
										setCheckboxSelected((prev)=>{
											const newArr = [...prev];
											newArr[questionIndex] = true;	
											return newArr;
										});	
									}}
								>
									Set as answer
								</Button>
							</Box>
						))}
						{ checkBoxSelected[questionIndex] ? "VALID" : "INVALID" }
					</Box>
				)) }
			</Box>
			<Button 
				variant="outlined"
				onClick={()=>{ 
					if(checkBoxSelected.filter((item) => item===false).length > 0) {
						setBarContents({ msg: "Must ensure that every question has an answer first", severity: "error" });
						setOpenBar(true);
						return;
					}
					setQuiz((prev)=>({
					...prev, questions: [...prev.questions, {
						questionType: "FILL_IN_BLANK_SELECT",
						options: question.options,
						content: question.content,
						answer: question.answer
					}]}));
				}}
			>
				Add to quiz
			</Button>
		</Box>
	)	
}

