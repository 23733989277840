import { useContext } from 'react';
import { usePatchUser } from '../../../api/userApi.js';
import { Box, Button } from '@mui/material';
import { FormContext } from './FormContextProvider';

export default ({ setOpen }) => {

	const { state } = useContext(FormContext);
	const patchUserMutation = usePatchUser();

	return (
		<Box sx={{
			display: "flex",
			justifyContent: "flex-end"
		}}>
			<Button 
				variant="outlined" 
				sx={{
					height: "38px",
					width: "126px",
					fontSize: "12px"
				}}
				onClick={()=>setOpen(false)}
			>
				Cancel
			</Button>
			<Box sx={{ width: "10px" }}/>
			<Button
				variant="contained"
				sx={{
					height: "38px",
					width: "126px",
					fontSize: "12px"
				}}
				onClick={() => { 
					const newState = {};
					for(let key in state) {
						newState[key] = state[key].value;
					}
					newState.name = newState.firstName + " " + newState.lastName;
					delete newState.firstName;
					delete newState.lastName;
					delete newState.password;
					patchUserMutation.mutate(newState);
				}}
			>
				Update
			</Button>
		</Box>
	)
}
