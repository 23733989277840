import { Box, Typography, InputBase, Button, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import { usePostModuleSimple } from '../../../api/modulesApi';

const embedLinkProcessor = (htmlString) => {
  // Create a new DOM parser
  var parser = new DOMParser();
  
  // Parse the HTML string into a document
  var doc = parser.parseFromString(htmlString, 'text/html');
  
  // Find the iframe element
  var iframe = doc.querySelector('iframe');
  
  // If iframe is found, return its src attribute, otherwise return null
  return iframe ? iframe.getAttribute('src') : "";
}

export default ({setOpenBar, setBarContents}) => {

  const postModuleSimpleMutation = usePostModuleSimple();
  const [ module, setModule ] = useState({
    name: "",
    canva: "",
		icon: "sample",
		descLong: "sample",
		descBrief: "sample",
		content: [],
		quizzes: []
  });
  const [ status, setStatus ] = useState("default")
  const [ moduleId, setModuleId ] = useState("")

  return (
    <Box sx={{ 
      display: "flex", 
      flexDirection: "column",
      gap: "12px"
    }}>
      <Typography sx={{ marginBottom: "10px" }}>Add Module</Typography>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ maxWidth: "160px", width: "160px"}}>Module Name (brief name)</Box>
        <InputBase 
          onChange={(e)=>setModule({...module, name: e.target.value})}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ maxWidth: "160px", width: "160px"}}>Canva Embed Link</Box>
        <InputBase 
          value={module.canva}
          onChange={(e)=>setModule({...module, canva: embedLinkProcessor(e.target.value)})}
        />
      </Box>
      { status==="default" && (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Tooltip title="There is no confirmation, pressing this will immediately post the new module into the database">
            <Button 
              variant="contained" 
              onClick={async ()=>{
                if(module.name && module.canva) {
                  setStatus("posting")
                  const newModule = await postModuleSimpleMutation.mutateAsync(module);
                  setModuleId(newModule.data._id)
                  setStatus("complete")
                }
                else {
                  setOpenBar(true);
                  setBarContents({ severity: "error", msg: "You haven't filled out the form properly" });
                }
              }}
            >
              Post to database
            </Button>
          </Tooltip>
        </Box>
      )}
      { status==="posting" && (
        <Box sx={{ display: "flex", gap: "10px" }}>
          Processing
        </Box>
      )}
      { status==="complete" && (
        <Typography>{`Module ID: ${moduleId}`}</Typography>
      )}
    </Box>
  )
}
