import { useEffect } from 'react';

export default ({children, constrain, delay}) => {

	useEffect(() => {
		setTimeout(() => { 
			let mouseOverContainer = document.getElementById("ex1");
			let ex1Layer = document.getElementById("ex1-layer");

			function transforms(x, y, el) {
				let box = el.getBoundingClientRect();
				let calcX = -(y - box.y - (box.height / 2)) / constrain;
				let calcY = (x - box.x - (box.width / 2)) / constrain;
				
				return "perspective(100px) "
					+ "   rotateX("+ calcX +"deg) "
					+ "   rotateY("+ calcY +"deg) ";
			};

			 function transformElement(el, xyEl) {
				el.style.transform  = transforms.apply(null, xyEl);
			}

			mouseOverContainer.onmousemove = function(e) {
				let xy = [e.clientX, e.clientY];
				let position = xy.concat([ex1Layer]);

				window.requestAnimationFrame(function(){
					transformElement(ex1Layer, position);
				});
			};
		}, delay);
	});

	return (
		<div id="ex1" class="container"
			style={{
				height: "100%",
				width: "100%",
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{children}
		</div>
	)
}
