import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import PreviewSlideWrapper from './components/PreviewSlideWrapper';
import PreviewCarousel from '../Animated/PreviewCarousel';
import { Box, Pagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HomePageBlobsImg from '../../assets/img/home_page_blobified.png';

export default () => {

	const [ page, setPage ] = useState(0);
	const numPages = 3;
	const theme = useTheme();

	return (
		<Box sx={{ 
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			alignItems: "center",
			height: "100%",
			backgroundColor: theme.palette.primary.main
		}}>
			<AnimatePresence exitBeforeEnter>
				{ page===0 && (
					<PreviewSlideWrapper
						key="one"
					>
						<Box sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							height: "100%",
						}}>
							<img width="100%" src={HomePageBlobsImg} />
							<Box sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								color: "white",
								margin: "36px"
							}}>
								<Box sx={{ fontWeight: 500, fontSize: "16px", marginBottom: "6px" }}>Discover Your Learning and Career Navigator</Box>
								<Box sx={{ fontSize: "12px", textAlign: "center", width: "80%" }}>
									A central hub for career advice and future prep! Expert guidance and resources, making it easy to explore your academic journey and future career possibilities.
								</Box>
							</Box>
						</Box>
					</PreviewSlideWrapper>
				)}
				{ page===1 && (
					<PreviewSlideWrapper
						key="two"
					>
						Under Construction
					</PreviewSlideWrapper>
				)}
				{ page===2 && (
					<PreviewSlideWrapper
						key="three"
					>
						Under Construction
					</PreviewSlideWrapper>
				)}
			</AnimatePresence>
			<Box sx={{
				position: "absolute",
				bottom: "36px",
				padding: "6px 12px",
				borderRadius: "16px",
				transform: "scale(0.75)",
				display: "flex",
				gap: "6px"
			}}>
				{ Array.from(Array(3).keys()).map((index) => (
					<Box 
						sx={{
							backgroundColor: "white",
							opacity: page===index ? 1 : 0.75,
							"&:hover": {
								opacity: 1
							},
							width: "12px",
							height: "12px",
							borderRadius: "50%",
							transition: "all 200ms ease-out",
							cursor: "pointer"
						}}
						onClick={()=>setPage(index)}
					/>	
				))} 
			</Box>
		</Box>
	)
}
