import { Box, CircularProgress, Button, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Answer from './Answer';
import AnimPopIn from '../../../global_components/Animated/AnimPopIn';
import { useGetSelectedQuestion } from '../../../api/questionApi';
import { useGetComments } from '../../../api/commentApi';

export default ({ selectedQuestion, toggleShowComment }) => {

	const getSelectedQuestionQuery = useGetSelectedQuestion(selectedQuestion);
	const getCommentsQuery = useGetComments(selectedQuestion);
	const theme = useTheme();

	return getCommentsQuery.isSuccess ? (
		<>
			<AnimPopIn
				delay={0.2}
				sx={{
					marginTop: "20px",
					padding: "36px 36px 18px 36px",
					borderRadius: "16px 16px 0 0",
					backgroundColor: "white",
					boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px"
				}}
			>
				<Box sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "20px"
				}}>
					<Box sx={{ color: "#3B3B3B", fontWeight: 600, fontSize: "18px", padding: "6px" }}>
						{getCommentsQuery.data.data.length} Answers
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
						<Box sx={{ 
							display: "flex", 
							alignItems: "center", 
							gap: "10px", 
							fontWeight: 500, 
							color: theme.palette.secondary.light,
							fontSize: "13px",
							"& .MuiInputBase-input": { padding: "6px 22px 6px 10px" }
						}}>
							Sort by
							<Select
								size="small"
							>
								<MenuItem value="-updatedAt">Recent</MenuItem>
								<MenuItem value="-views">Views</MenuItem>
								<MenuItem value="-likes.length">Likes</MenuItem>
							</Select>
						</Box>
						<Box sx={{ 
							display: "flex", 
							alignItems: "center", 
							gap: "10px", 
							fontWeight: 500, 
							color: theme.palette.secondary.light,
							fontSize: "13px",
							"& .MuiInputBase-input": { padding: "6px 22px 6px 10px" }
						}}>
							Show	
							<Select
								size="small"
							>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={50}>50</MenuItem>
								<MenuItem value={100}>100</MenuItem>
							</Select>
						</Box>
					</Box>
					<Button 
						sx={{ width: "180px" }}
						variant="contained"
						onClick={()=>toggleShowComment()}
					>
						Type a reply
					</Button>
				</Box>
				<Box sx={{ height: "0.5px", width: "100%", backgroundColor: "#A4A4A4", margin: "24px 10px 0 10px" }}/>
			</AnimPopIn>
			<AnimPopIn
				delay={0.2}
				sx={{
					position: "relative",
					padding: "18px 36px 36px 36px",
					borderRadius: "0 0 16px 16px",
					backgroundColor: "white",
					overflowY: "scroll",
					overflowX: "hidden",
					boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px"
				}}
			>
				{getCommentsQuery.data.data.map((answer, index) => {
					
					return (
					<>
						{ index > 0 && (
							<Box sx={{ height: "0.5px", width: "100%", backgroundColor: "#A4A4A4", margin: "24px 10px" }}/>
						)}
						<Answer answer={answer}/>
					</>
					)
				})}
			</AnimPopIn>
		</>
	) : (
		<Box sx={{
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		}}>
			<CircularProgress
				variant="indeterminate"
				width="60px"
			/>
		</Box>
	)
}
