import "./style.css";
import { Box } from '@mui/material';
import quoteImg from '../../../front_pages/assets/misc_img/quote.png';

export default ({ iconImg, name, from, content, heading }) => (
  <div 
    className="card"
    style={{
      padding: "42px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    }}
  >
    <Box>
      <Box sx={{
        fontSize: "16px",
        fontWeight: 500,
        color: "#3b3b3b",
        marginBottom: "12px"
      }}>
        <span style={{ fontWeight: 600 }}>"</span>
          {heading}
        <span style={{ fontWeight: 600 }}>"</span>
      </Box>
      <Box sx={{
        fontSize: "12px",
        color: "#3b3b3baa"
      }}>
        {content}
      </Box>
    </Box>
    <Box sx={{
      height: "64px", 
      display: "flex",
      gap: "12px"
    }}>
      <Box sx={{
        height: "100%",
        aspectRatio: "1",
        backgroundImage: `url(${iconImg})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "50%"
      }}/>
      <Box sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}>
        <Box sx={{
          fontSize: "14px",
          fontWeight: 500,
        }}>
          {name}
        </Box>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "11px",
          fontWeight: 400,
          fontStyle: "italic",
          color: "#3b3b3baa",
          marginTop: "1px"
        }}>
          {from}
        </Box>
      </Box>
    </Box>
  </div>
);
