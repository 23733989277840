import { useState, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as LeftArrow} from '../../assets/misc_icons/left-arrow.svg'
import { ReactComponent as RightArrow} from '../../assets/misc_icons/right-arrow.svg'
import { FreeMode, Pagination, Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default ({breakpoints, children}) => {

	const [ active, setActive ] = useState(false);
	const leftRef = useRef(null);
	const rightRef = useRef(null);

	return (
		<Box 
			onMouseEnter={()=>setActive(true)}
			onMouseLeave={()=>setActive(false)}
			sx={{ display: "flex", alignItems: "center" }}
		>
			<Box sx={{ 
				position: "absolute", 
				zIndex: 100,
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "32px",
				pointerEvents: "none"
			}}>
				<Button 
					variant="light-circular"
					sx={{ 
						pointerEvents: "auto",
						opacity: active ? 1 : 0,
						transform: active ? "translateX(0px)" : "translateX(20px)",
						transition: "all 200ms cubic-bezier(0.12, 0.83, 0.58, 1)"
					}}
					ref={leftRef}
				>
					<LeftArrow />
				</Button>
				<Button 
					variant="light-circular"
					sx={{ 
						pointerEvents: "auto",
						opacity: active ? 1 : 0,
						transform: active ? "translateX(0px)" : "translateX(-20px)",
						transition: "all 200ms cubic-bezier(0.12, 0.83, 0.58, 1)"
					}}
					ref={rightRef}
				>
					<RightArrow />
				</Button>
			</Box>
			<Swiper
        spaceBetween={100}
				freeMode={true}
				navigation={{
					prevEl: leftRef.current,
					nextEl: rightRef.current
				}}
				pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        breakpoints={breakpoints}
        className="mySwiper"
      >
				{children}
			</Swiper>
		</Box>
	)
}
