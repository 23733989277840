import ResetPopper from './ResetPopper';
import { useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from '../../providers/MainProvider';
import { useTheme } from '@mui/material/styles';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { Box, Slider, Button, Switch, Typography, CircularProgress, Popover } from '@mui/material';
import InputField from './components/InputField';
import { useLogin } from '../../api/authApi.js';

export default ({ setOpen, setShowError, setErrorMsg }) => {

	const initialFormState = {
		email: { value: "", valid: true },
		password: { value: "", valid: true }
	}

	const regex = {
		email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
		password: /^.{6,}$/, 
	}

	const formReducer = (state, action) => {
		switch(action.type) {
			case "INPUT":
				return {...state, [action.field]: { ...state[action.field], value: action.payload }}
			case "VALIDATE":
				return {...state, [action.field]: { ...state[action.field], valid: true }}
		}
	}
	
	const theme = useTheme();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { authType, setAuthType } = useMainContext();
	const [ form, dispatchForm ] = useReducer(formReducer, initialFormState);
	const [ loggingin, setLoggingin] = useState(false);
	const [ passwordResetEl, setPasswordResetEl ] = useState(null);
	const loginMutation = useLogin({ navigate, setShowError, setErrorMsg, setLoggingin });

	if(loginMutation.isSuccess) {		
		return (
			<Box sx={{ fontWeight: 500, fontSize: "16px" }}>Success</Box>
		)
	}
	else if(loggingin && !loginMutation.isSuccess) {
		return (
			<Box sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}}>
				<CircularProgress
					variant="indeterminate"
					size="60px"
				/>
			</Box>
		)
	}
	else {
		return (
			<Box sx={{ 
				width: "500px",
				display: "flex",
				flexDirection: "column",
				borderRadius: "32px",
				color: "#3b3b3b",
				backgroundColor: "white"
			}}>
				<Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
					<Box sx={{ fontSize: "28px", fontWeight: 650 }}>Login</Box>
					<Box sx={{ fontSize: "16px", fontWeight: 400 }}>Learn about your future!</Box>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column"}}>
					<Box sx={{ margin: "10px 0"}}>
						<InputField 
							label="Email Address" 
							placeholder="Enter your email address"
							field="email"
							value={form.email.value}
							error={!form.email.valid}
							dispatch={dispatchForm}
						/>
					</Box>
					<Box sx={{ margin: "10px 0"}}>
						<InputField 
							label="Password" 
							placeholder="******" 
							type="password" 
							field="password"
							value={form.password.value}
							error={!form.password.valid}
							dispatch={dispatchForm}
						/>
					</Box>
					<Box sx={{ margin: "10px 0", display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "12px", fontWeight: 500 }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Switch/>
							<Box>Keep me signed in</Box>
						</Box>
						<Typography 
							color="primary" 
							sx={{ fontSize: "12px", fontWeight: 600, cursor: "pointer" }}
							onClick={(event)=>setPasswordResetEl(event.currentTarget)}
						>
							I forgot my password
						</Typography>
						<Popover
							open={Boolean(passwordResetEl)}
							anchorEl={passwordResetEl}
							onClose={()=>setPasswordResetEl(null)}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
						>
							<ResetPopper />
						</Popover>
					</Box>
					<Box sx={{ margin: "10px 0", display: "flex", alignItems: "center", fontSize: "12px", fontWeight: 500 }}>
					</Box>
				</Box>
				<Box sx={{ display: "flex", marginTop: "24px" }}>
					<Button 
						variant="contained" 
						sx={{ width: "100%" }}
						onClick={ async () => {
							let valid = true;
							Object.keys(form).forEach((key)=>{
								valid = valid && form[key].valid && form[key].value!==""
							});						
							if(valid) {
								await loginMutation.mutate({
									email: form.email.value,
									password: form.password.value
								});
								setLoggingin(true);
							}
						}}
					>
						Login
					</Button>
				</Box>
				<Box sx={{ display: "flex", fontSize: "12px", fontWeight: 500, marginTop: "10px" }}>
					Don't have an account yet?
					<Box 
						sx={{ 
							marginLeft: "5px", 
							fontWeight: 600, 
							color: theme.palette.primary.main,
							cursor: "pointer"
						}}
						onClick={()=>{
							setAuthType("REGISTER");
						}}
					>
						Register for one!
					</Box>
				</Box>
			</Box>
		)
	}
}
