import React, { useState } from 'react'
import { Box, Button, CircularProgress, Tooltip, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CareerSpecsSection from './components/CareerSpecsSection';
import Profile from '../../global_components/Profile';
import ProfileSection from './ProfileSection';
import LessonCard from './components/LessonCard';
import WeeklyGoals from './components/WeeklyGoals';
import { motion, AnimateSharedLayout, AnimatePresence} from 'framer-motion';
import { ReactComponent as PlusIcon } from '../../assets/sidebar_icons/plus-solid.svg';
import { ReactComponent as LeftArrow} from '../../assets/misc_icons/left-arrow.svg';
import { useGetCareerSpecsAll } from '../../api/careerSpecialisationApi';
import { useGetModulesByCareerSpec, getModuleProgress } from '../../api/modulesApi';
import { useGetUser } from '../../api/userApi';

export default () => {

	const theme = useTheme();
	
	//Animation settings
	const preExpandTime = 0.5	
	const [ selectedCareerSpecId, setSelectedCareerSpecId ] = useState();
	const [ rightPanelContent, setRightPanelContent ] = useState("PROFILE");
	
	//Queries
	const getCareerSpecsQuery=useGetCareerSpecsAll();
	const getModulesByCareerSpec = useGetModulesByCareerSpec(selectedCareerSpecId);
	const getUserQuery = useGetUser();

	// Current Date
	const current = new Date();
	const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
	
	// Days
	const days = [
		true,
		false,
		true,
		true,
		true,
		false,
		false
	]

	return (
		<motion.div 
			style={{
				width: "100%",
				maxWidth: "100%",
				height: "100vh",
				overflow: "hidden",
				display: "flex",

			}}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
		>
			{/* Career Activity */}
				<Box
					style={{ 
						flex: 2, 
						backgroundColor: "#F3F7FF", 
						height: "calc(100%-20px)", 
						margin: "10px 0 10px 0", 
						borderRadius: "30px", 
						width: '100%' 
					}}
				>
				<motion.div
					exit={{ opacity: 0 }}
					transition={{ duration: preExpandTime }}
				>
					<Box sx = {{display: 'flex', justifyContent: 'space-between', margin: '60px 0 0 20px'}}>
						<Box sx = {{display: 'flex', flexDirection: 'column', marginLeft: '15px', lineHeight: 1.3}}>
							<h2 style = {{fontSize: 22, fontWeight: '550', color: '#2b2b2b'}}>Career Activity</h2>
							<h4 style = {{fontSize: 18, fontWeight: '500', color: theme.palette.primary.main}}>{ date }</h4>
						</Box>
						<Button 
							variant = 'contained' 
							color = 'secondary' 
							sx = {{
								borderRadius: '10px',
								width: '20px', 
								height: '40px', 
								marginRight: '25px',
								color: '#FFFFFF',
								backgroundColor: theme.palette.primary.main,
								fontSize: 40,
								fontWeight: 350,
								maxWidth: '40px', maxHeight: '40px', minWidth: '40px', minHeight: '40px',
								'& svg': {fill: '#FFFFFF', maxWidth: '25px', maxHeight: '25px', minWidth: '25px', minHeight: '25px'}
							}}
						>
							<PlusIcon />
						</Button>
					</Box>

					{/* Current Learning */}
					<h2 style = {{margin: '40px 20px 10px 35px', fontWeight: 500, fontSize: 18, color: '#A4A4A4'}}>Current Learning</h2>
					<CareerSpecsSection 
						getCareerSpecsQuery={getCareerSpecsQuery}
						selectedCareerSpecId={selectedCareerSpecId}
						setSelectedCareerSpecId={setSelectedCareerSpecId}
						setRightPanelContent={setRightPanelContent}
					/>
				</motion.div>
			</Box>

			<motion.div 
				style={{ 
					flex: 3, 
					position: "relative",
					display: "flex",
					flexDirection: "column",
					justifyContent: "stretch",
					maxHeight: "100%", 
				}}
				exit={{ opacity: 0 }}
				transition= {{ duration: preExpandTime }}
			>
				{/* MY LESSONS */}
				<Box sx = {{
					display: 'flex',
					justifyContent: 'space-between', 
					alignItems: 'flex-end', 
					width: '100%',
					minHeight: "110px",
					padding: "0 28px",
					marginBottom: "52px"
				}}>
					<Button 
						variant="light-outline" 
						onClick={()=>setRightPanelContent((prev)=>prev==="MODULES" ? "PROFILE" : "MODULES")}
					>
						<Box sx={{ display: "flex", alignItems: "center", transition: "all 200ms ease-in-out", ...{transform: rightPanelContent==="MODULES" ? 
							"rotate(180deg)":"rotate(0)"
						}}}>
							<LeftArrow />
						</Box>
					</Button>
					<Profile/>
				</Box>
				<Box sx={{ position: "relative", height: "100%", maxHeight: "calc(100% - 150px)", overflow: "hidden"}}>
					<Box sx={{
							position: "absolute",
							maxHeight: "100%",
							width: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							transition: "all 250ms ease-in-out",
							padding: "0 28px",
							transform: `translateX(${rightPanelContent==="MODULES" ? "0px" : "-25%"})`,
							opacity: rightPanelContent==="MODULES" ? 1 : 0
						}}>
						<Box>
							<h2 style = {{margin: '0 20px 10px 0', fontWeight: 500, fontSize: 18, color: '#A4A4A4'}}>My Lessons</h2>
						</Box>
						{/* Individual Lessons Cards */}
						<Box sx = {{width: '100%', padding: '10px 20px 0 50px', overflowY: 'scroll', overflowX: "hidden"}}>
							{
								getModulesByCareerSpec.isSuccess ? (
									getModulesByCareerSpec.data.data.map((module, index) => (
										<LessonCard			
											id = {module._id}
											index={index}
											name = {module.name}
											icon = {module.icon}
											desc = {module.descBrief}
											progress = {100 * getModuleProgress(getUserQuery.data.data, module._id)/(module.content.length===0 ? 1 : module.content.length + module.quizzes.length)}
										/>
									))
								) : (
									<Box sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
									}}>
										<CircularProgress
											variant="indeterminate"
											size="60px"
										/>
									</Box>
								)
							}
						</Box>
					</Box>
					<Box sx={{
							position: "absolute",
							height: "100%",				
							maxHeight: "100%",
							width: "100%",
							display: "flex",
							padding: "0 28px",
							flexDirection: "column",
							justifyContent: "space-between",
							transition: "all 250ms ease-in-out",
							transform: `translateX(${rightPanelContent==="MODULES" ? "25%" : "0"})`,
							opacity: rightPanelContent==="MODULES" ? 0 : 1,
						}}>
						<ProfileSection />
					</Box>
				</Box>
						<Box sx={{ 
							height: "300px", 
							display: "flex", 
							flexDirection: "column",
							padding: "0 28px",
						}}>
							<h2 style = {{ margin: '30px 0 10px 0', fontWeight: 500, fontSize: 18, color: '#A4A4A4' }}>Weekly Goal</h2>
							<Box sx = {{display: 'flex', width: '100%',paddingLeft: '25px', justifyContent: 'space-between', margin: '25px 0 50px ',}}>
								{
									days.map((name, index) => <WeeklyGoals name={name} index={index} key={index}/>)
								}
							</Box>
						</Box>
			</motion.div>
		</motion.div>
	)
}
