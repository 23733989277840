import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosClient } from './axiosClient';

export const useAddComment = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ data }) => {
			return await axiosClient.post('comments', data, {
				headers: {
					'Content-type': 'multipart/form-data'
				}
			})
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries(['comments'])
			setTimeout(() => { 
				axiosClient.post("user/activity", {
					type: "COMMENT",
					activityId: data.data._id
				});	
			}, 500);
		}
	})
}


export const useAddReply = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({description, questionId, commentId, userId, replies}) => {
			return await axiosClient.post("replies", {
				description, questionId, commentId, userId, replies,
			})
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries(['replies'])
			setTimeout(() => { 
				axiosClient.post("user/activity", {
					type: "COMMENT",
					activityId: data.data._id
				});	
			}, 500);
		}
	})
}

export const useGetComments = (id) => useQuery({
	queryKey: ['comments'],
	queryFn: async () => await axiosClient.get(`comments/${id}`)
})

export const useGetReplies = (id) => useQuery({
	queryKey: ['replies'],
	queryFn: async () => await axiosClient.get(`replies/${id}`)
})
