import {useEffect} from 'react';
import { Box } from '@mui/material'

export default ({questions}) => {
    console.log(questions);
    return (
        <Box>
            {questions.value.map((question) => {
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {question.name}
                        <img
                            width='400px'
                            height='auto'
                            alt='question'
                            style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
                            src={`http://localhost:5000/assets/${question.picturePath}`}
                        ></img>
                    </Box>
                )
            })}
        </Box>
    )
}
