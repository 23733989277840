import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Box, LinearProgress, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default ({name, slides, index, desc, progress, handleClick, test }) => {

	const theme = useTheme();
	const [ delay, setDelay ] = useState(0.25+index*0.15);
	useEffect(() => {
		setTimeout(()=>{ setDelay(0)}, 400);
	}, []);

	return (
		<motion.div
			key={index}
			style={{
				backgroundColor: "white",
				color: "#3b3b3b",
				boxShadow: "rgba(149, 157, 165, 0.125) 0px 1px 8px",
				borderRadius: "28px",
				cursor: "pointer",
				display: "flex"
			}}
			initial={{ translateY: -50, opacity: 0 }}
			animate={{ translateY: 0, opacity: 1 }}
			whileTap={{ scale: 0.985 }}
			onClick={handleClick}
			transition={{ 
				type: 'spring', 
				bounce: 0.4, 
				duration: 0.5,
				delay: delay
			}}
			whileHover={ delay===0 ? { translateY: -5, transition: { duration: 0.2 }} : {} }
		>
			<Box sx={{ 
				display: "flex", 
				flex: 3, 
				backgroundColor: theme.palette.primary.main, 
				padding: "26px", 
				paddingRight: "32px",
				borderRadius: "18px 0 0 18px",
				flexDirection: "column", 
				justifyContent: "space-between"
			}}>
				<Box>
					<Box sx={{ opacity: 0.6, color: "white", fontSize: "13px" }}>Module</Box>
					<Box sx={{ color: "white", fontSize: "18px" }}>{ name }</Box>
				</Box>
				<Box>
					<Box sx={{ opacity: 0.6, color: "white", fontSize: "13px", marginTop: "34px" }}>Total slides: {slides}</Box>
				</Box>
			</Box>
			<Box sx={{ 
				display: "flex", 
				flex: 4, 
				backgroundColor: "white", 
				padding: "26px",
				borderRadius: "0 18px 18px 0",
				flexDirection: "column", 
				justifyContent: "space-between"
			}}>
				<Box>
					<Box sx={{ color: theme.palette.primary.main, fontSize: "13px", minWidth: "50px" }}>Slide {progress + 1} of {slides}</Box>
					<Box sx={{ color: theme.palette.primary.main, fontSize: "14px", fontWeight: 450, marginTop: "3px" }}>{ desc }</Box>
				</Box>
				<LinearProgress 
					variant="determinate" 
					value={100*(progress===0 ? 0 : progress+1)/slides} 
					sx={{ width: "80%", height: "5px", borderRadius: "2.5px", margin: "12px 0 8px 0" }}
				/>
			</Box>
		</motion.div>
	)
}
