import { AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';
import { Tooltip, Button, InputBase, Typography, MenuItem, Select, Box, CircularProgress } from '@mui/material';
import { useGetCareerPaths } from '../../../api/careerPathApi';
import { useGetCareerSpecsByPath, usePostCareerSpec } from '../../../api/careerSpecialisationApi';
import { usePostModule, useGetModulesByCareerSpec } from '../../../api/modulesApi';
import ModulePreview from './ModulePreview';
import ModuleTable from './ModuleTable';

export default ({setOpenBar, setBarContents}) => {

	const [ showModulePreview, setShowModulePreview ] = useState(false);
	const [ selectedCareerPathId, setSelectedCareerPathId ] = useState('');
	const [ selectedCareerSpecId, setSelectedCareerSpecId ] = useState('');
	const [ selectedModuleId, setSelectedModuleId ] = useState('');
	const careerPathsQuery = useGetCareerPaths();
	const careerSpecsQuery = useGetCareerSpecsByPath(selectedCareerPathId);
	const modulesQuery = useGetModulesByCareerSpec(selectedCareerSpecId);
	const postModuleMutation = usePostModule({setOpenBar, setBarContents});

	const [ form, setForm ] = useState({
		name: "",
		icon: "",
		descLong: "",
		descBrief: "",
		content: [],
		quizzes: []
	});

	useEffect(()=>console.log("changing"), [form]);

	return (
		<>
			<Button onClick={()=>console.log(form)}>TEST</Button>
			<Box sx={{ display: "flex", flexDirection: "column", marginBottom: "26px", alignItems: "flex-start" }}>
				<Typography sx={{ marginBottom: "10px" }}>Select a Career Path</Typography>
				<Select 
					value={selectedCareerPathId}
					onChange={(e) => setSelectedCareerPathId(e.target.value)}
					sx={{
						height: "36px"
					}}
				>
					{careerPathsQuery.isSuccess && careerPathsQuery.data.data.map((careerPath) => (
						<MenuItem value={careerPath._id}>{careerPath.name}</MenuItem>
					))}
				</Select>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", marginBottom: "26px", alignItems: "flex-start" }}>
				<Typography sx={{ marginBottom: "10px" }}>Select a Career Specialisation</Typography>
				<Select 
					value={selectedCareerSpecId}
					onChange={(e) => setSelectedCareerSpecId(e.target.value)}
					sx={{
						height: "36px"
					}}
				>
					{careerSpecsQuery.isSuccess && careerSpecsQuery.data.data.map((careerSpec) => (
						<MenuItem value={careerSpec._id}>{careerSpec.name}</MenuItem>
					))}
				</Select>
			</Box>
			<Box sx={{
				marginBottom: "36px"
			}}>
			</Box>
			<Box sx={{ 
				display: "flex", 
				flexDirection: "column",
				gap: "12px"
			}}>
				<Typography sx={{ marginBottom: "10px" }}>Add Module</Typography>
				<Box sx={{ display: "flex" }}>
					<Box sx={{ maxWidth: "160px", width: "160px"}}>Name</Box>
					<InputBase 
						onChange={(e)=>setForm({...form, name: e.target.value})}
					/>
				</Box>
				<Tooltip title="Download a .svg file, open it with any text editor and then copy & paste the code into this field">
					<Box sx={{ display: "flex" }}>
						<Box sx={{ maxWidth: "160px", width: "160px"}}>Icon SVG</Box>
						<Box sx = {{minWidth: "50px", width: '50px', height: '50px', backgroundColor: '#F3F7FF', borderRadius: '12px', padding: "12px", "& svg": { fill: "#3B3B3B" } }}>
							<div dangerouslySetInnerHTML={{ __html: form.icon}} />
						</Box>
						<InputBase 
							onChange={(e)=>setForm({...form, icon: e.target.value})}
						/>
					</Box>
				</Tooltip>
				<Box sx={{ display: "flex" }}>
					<Box sx={{ maxWidth: "160px", width: "160px"}}>Long Description (Long description here, summarise this in the "short description" below</Box>
					<InputBase 
						onChange={(e)=>setForm({...form, descLong: e.target.value})}
					/>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Box sx={{ maxWidth: "160px", width: "160px"}}>Short Description</Box>
					<InputBase 
						onChange={(e)=>setForm({...form, descBrief: e.target.value})}
					/>
				</Box>
					<ModuleTable 
						form={form}
						setForm={setForm}
					/>
				<Box sx={{ display: "flex", gap: "10px" }}>
					<Tooltip title="There is no confirmation, pressing this will immediately post the new module into the database">
						<Button 
							variant="contained" 
							onClick={async ()=>{
								if(selectedCareerPathId && selectedCareerSpecId) {
									postModuleMutation.mutate({...form, careerSpecId: selectedCareerSpecId });
								}
								else {
									setOpenBar(true);
									setBarContents({ severity: "error", msg: "You must select a career path and specialisation that this module falls under" });
								}
							}}
						>
							Post to database
						</Button>
					</Tooltip>
					<Button
						variant="outlined"
						onClick={()=>setShowModulePreview(true)}
					>
						Preview Module+Lesson 
					</Button>
				</Box>
			</Box>
		</>
	)
}
