import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as HeartIcon } from '../../../assets/misc_icons/heart-solid.svg';
import { useGetFeaturedActivity } from '../../../api/featuredActivityApi';
import ActivityButton from './ActivityButton';

export default () => {

	const theme = useTheme();
	const getFeaturedActivityQuery = useGetFeaturedActivity();

	return ( 
		<Box sx={{
			padding: "18px",
			borderRadius: "16px",
			width: "100%",
			height: "100%",
			backgroundColor: "white",
			boxShadow: "rgb(149 157 165 / 5%) 0px 0px 6px",
			display: "flex",
			flexDirection: "column"
		}}>
			<Box sx={{ 
				color: theme.palette.primary.main,
				fill: theme.palette.primary.main,
				fontWeight: 550,
				display: "flex",
				gap: "8px",
				padding: "0 10px",
				marginBottom: "12px"
			}}>
				<HeartIcon width="18px" />
				Featured
			</Box>
			<Box sx={{ overflowY: "scroll", overflowX: "hidden" }}>
			{ getFeaturedActivityQuery.isSuccess ? getFeaturedActivityQuery.data.data.map((activity) => (
				<ActivityButton
					activity={activity}
				/>
			)) : (
				<Box sx={{
					display: "flex",
					width: "100%",
					height: "100%",
					justifyContent: "center",
					alignItems: "center"
				}}>
					<CircularProgress
						variant="indeterminate"
						width="60px"
					/>
				</Box>
			)}
			</Box>
		</Box>
	)
}
