import React, { useState } from 'react'
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { Box, Menu, MenuItem, CircularProgress, Button } from '@mui/material'
import { AnimatePresence } from 'framer-motion';
import { useGetUser } from '../api/userApi.js';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from '../providers/MainProvider';
import Popup from './Animated/Popup';
import MyAccount from './auth/MyAccount';

const Profile = ({ firstName, lastName, role, picturePath, setShowMyAccount }) => {

	const { baseURL } = useMainContext();
	const [ anchorEl, setAnchorEl ] = useState(null);
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return (
		<>
    <Box 
			sx = {{
				display: 'flex', 
				justifyContent: 'center', 
				gap: "20px",
				alignItems: 'center',
				cursor: "pointer"
			}}
			onClick={(event) => setAnchorEl(event.currentTarget)}
		>
        <Box>
            <Box sx = {{lineHeight: '1.3', display: 'flex', flexDirection: 'column'}}>
                <h1 style = {{fontSize: 17, fontWeight: 550}}>{ firstName } { lastName }</h1>
                <h1 style = {{fontSize: 15, fontWeight: 550, color: '#1B56BA'}}>{ role.charAt(0).toUpperCase() + role.slice(1).toLowerCase() }</h1>
            </Box>
        </Box>
        <Box sx = {{
            width: '50px', 
            height: '50px',
            backgroundColor: '#d9d9d9',
            borderRadius: '50%'
        }}>
            <img 
				src={`${baseURL}${picturePath}`}
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					borderRadius: '50%'
				}}
			/>
        </Box>
    </Box>
		<Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
				onClose={()=>setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
				<MenuItem onClick={() => { 
					setAnchorEl(null);
					setShowMyAccount(true);
				}}>
					My account
				</MenuItem>
        <MenuItem onClick={() => {
					setAnchorEl(null);
					localStorage.removeItem("jwt");
					queryClient.invalidateQueries({ queryKey: ["currUser"] });
					console.log(localStorage.getItem("jwt"));
				}}>
					Logout
				</MenuItem>
				{role==="STAFF" && ( 
					<MenuItem onClick={()=>{
						setAnchorEl(null);
						navigate("/app/admin");
					}}>
						Staff Panel
					</MenuItem>
				)}
      </Menu>
		</>
	)
}

export default () => {
	
	const userQuery = useGetUser();
	const [ showMyAccount, setShowMyAccount ] = useState(false);

	return (
		<>
			{ userQuery.isLoading && 
					<CircularProgress
						variant = "indeterminate"
						size = '50px'
						thickness={4}
					/>
			}
			{ userQuery.isSuccess && 
				<Profile
					firstName={userQuery.data.data.name.split(" ")[0]}
					lastName={userQuery.data.data.name.split(" ")[1]}
					role={userQuery.data.data.role}	
					picturePath={userQuery.data.data.picturePath}
					setShowMyAccount={setShowMyAccount}
				/>
			}
			<MyAccount open={ showMyAccount } setOpen={setShowMyAccount}/>
		</>
	)
}
