import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from './axiosClient';

export const useGetUser = (options) => useQuery({
	queryKey: ['currUser'],
	queryFn: async () => {
		const res = await axiosClient.get("user");
		if(res && options) {
			if(options.callback) {
				options.callback(res);
			}
		}
		return res;
	},
	retry: (failCount, error) => { 
		if(failCount > 1) {
			return false
		}
		else if(error.response) {
			return error.response.status!==401
		}
		return true
	}
}) 

export const useGetUserById = (id) => useQuery({
	queryKey: [`userId-${id}`],
	queryFn: async () => await axiosClient.get(`user/${id}`),
})

export const usePatchUserPicture = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ data }) => {
            return await axiosClient.patch("user/picture", data, { 
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            })
        },
		onSuccess: () => queryClient.invalidateQueries(['currUser'])
	})
}

export const usePatchUser = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (variables) => await axiosClient.patch("user", variables),
		onSuccess: () => queryClient.invalidateQueries(["currUser"])
	})
};
