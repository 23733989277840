import { useState, useContext, createContext } from 'react';
import { Snackbar, Alert, Box, Button, Typography, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AnimWrapperFromHome from '../../global_components/Animated/AnimWrapperFromHome';
import CareerPaths from './components/CareerPaths';
import CareerSpecs from './components/CareerSpecs';
import AddModules from './components/AddModules';
import Modules from './components/Modules';
import Forums from './components/Forums';
import MyAdmin from './components/MyAdmin';

export const AdminContext = createContext();

export default () => {

	const [ value, setValue ] = useState("MY_ADMIN");
	const [ openBar, setOpenBar ] = useState(false);
	const [ barContents, setBarContents] = useState({
		msg: "",
		severity: "success"
	});
	const theme = useTheme();

	return (
		<AdminContext.Provider value={{
			openBar,
			setOpenBar,
			barContents,
			setBarContents
		}}>
		<Snackbar open={openBar} autoHideDuration={3000} onClose={()=>setOpenBar(false)}>
			<Alert onClose={()=>setOpenBar(false)} severity={barContents.severity} sx={{ width: '100%' }}>
				{barContents.msg}
			</Alert>
		</Snackbar>
		<AnimWrapperFromHome height="calc(100% - 30px)">
			<Box sx={{ 
				display: "flex", 
				width: "100%",
				height: "100%",
				justifyContent: "center",
				alignItems: "center",
			}}>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
				}}>
					<Typography>Admin page</Typography>
					<Box sx={{ 
						marginTop: "10px", 
					}}>
					<Tabs
						sx={{ 
							maxWidth: "100%", 
							borderRadius: "16px",
							backgroundColor: "white",
							padding: "16px",
							boxShadow: "rgba(100, 100, 111, 0.05) 0px 7px 29px 0px",
							".MuiTabs-scrollButtons": {
								width: "24px",
								height: "32px",
								margin: "auto 0",
								borderRadius: "6px"
							},
							".MuiTab-root": {
								padding: "0 6px"
							}
						}}
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab disableRipple label={
							<Button
								variant="light-blue-fill"
								onClick={()=>setValue("MY_ADMIN")}
								sx={{ 
									fontWeight: 550,
									color: value=="MY_ADMIN" && theme.palette.primary.main,
									fill: value=="MY_ADMIN" && theme.palette.primary.main
								}}
							>
								My Admin
							</Button>
						}/>
						<Tab disableRipple label={
							<Button
								variant="light-blue-fill"
								onClick={()=>setValue("CAREER_PATHS")}
								sx={{ 
									fontWeight: 550,
									color: value=="CAREER_PATHS" && theme.palette.primary.main,
									fill: value=="CAREER_PATHS" && theme.palette.primary.main
								}}
							>
								Career Paths
							</Button>
						}/>
						<Tab disableRipple label={
							<Button
								variant="light-blue-fill"
								onClick={()=>setValue("CAREER_SPECS")}
								sx={{ 
									fontWeight: 550,
									color: value=="CAREER_SPECS" && theme.palette.primary.main,
									fill: value=="CAREER_SPECS" && theme.palette.primary.main
								}}
							>
								Career Specialisations
							</Button>
						}/>
						<Tab disableRipple label={
							<Button
								variant="light-blue-fill"
								onClick={()=>setValue("MODULES")}
								sx={{ 
									fontWeight: 550,
									color: value=="MODULES" && theme.palette.primary.main,
									fill: value=="MODULES" && theme.palette.primary.main
								}}
							>
								Modules
							</Button>
						}/>
						<Tab disableRipple label={
							<Button
								variant="light-blue-fill"
								onClick={()=>setValue("ADD_MODULES")}
								sx={{ 
									fontWeight: 550,
									color: value=="ADD_MODULES" && theme.palette.primary.main,
									fill: value=="ADD_MODULES" && theme.palette.primary.main
								}}
							>
								Add Modules
							</Button>
						}/>
						<Tab disableRipple label={
							<Button
								variant="light-blue-fill"
								onClick={()=>setValue("FORUMS")}
								sx={{ 
									fontWeight: 550,
									color: value=="FORUMS" && theme.palette.primary.main,
									fill: value=="FORUMS" && theme.palette.primary.main
								}}
							>
								Forums
							</Button>
						}/>
					</Tabs>
					<Box sx={{ 
						marginTop: "30px",
						width: "1000px", 
						maxHeight: "600px",
						overflowY: "scroll",
						overflowX: "hidden",
						backgroundColor: "white",
						padding: "32px",
						borderRadius: "16px",
						boxShadow: "rgba(100, 100, 111, 0.05) 0px 7px 29px 0px",
					}}>
					{ value==="MY_ADMIN" && (
						<MyAdmin />
					)}
					{ value==="CAREER_PATHS" && (
						<CareerPaths/>
					)}
					{ value==="CAREER_SPECS" && (
						<CareerSpecs
							setOpenBar={setOpenBar}
							setBarContents={setBarContents}
						/>
					)}
          { value==="MODULES" && (
            <Modules
							setOpenBar={setOpenBar}
							setBarContents={setBarContents}
            />
          )}
					{ value==="ADD_MODULES" && (
						<AddModules
							setOpenBar={setOpenBar}
							setBarContents={setBarContents}
						/>
					)}
					{ value==="FORUMS" && (
						<Forums 
							setOpenBar={setOpenBar}
							setBarContents={setBarContents}
						/>
					)}
					{ value==="FORUMS" && (
						<Forums 
							setOpenBar={setOpenBar}
							setBarContents={setBarContents}
						/>
					)}
					</Box>
					</Box>
				</Box>
			</Box>
		</AnimWrapperFromHome>
		</AdminContext.Provider>
	)
}
