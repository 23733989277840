import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useMainContext } from "../../../providers/MainProvider";
import { motion } from 'framer-motion';
import { ReactComponent as RightArrowIcon } from '../../../assets/misc_icons/right-arrow.svg';
import { ReactComponent as PlusIcon } from '../../../assets/sidebar_icons/plus-solid.svg';

export default () => {

	const theme = useTheme();
	const navigate = useNavigate();
	const { setPrevPathname } = useMainContext();
	const [ hovered, setHovered ] = useState(false);
	const [ delay, setDelay ] = useState(0);
	useEffect(() => {
		setTimeout(()=>{ setDelay(0)}, 250);
	}, []);

	return (  
	<motion.div 
		style = {{ 
			cursor: 'pointer', 
			color: 'white', 
			borderRadius: '20px', 
			margin: '5px auto 25px auto', 
			width: '100%', 
			maxWidth: '600px',
			padding: "18px",
			backgroundColor: theme.palette.primary.main,
			boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 15px -3px'
		}}
		onHoverStart={()=>setHovered(true)}
		onHoverEnd={()=>setHovered(false)}
		whileTap = {{ scale: 0.97 }}
		initial={{ translateY: -15, opacity: 0 }}
		animate={{ translateY: 0, opacity: 1 }}
		transition={{ delay, duration: 0.6, type: 'spring', bounce: 0.5 }}
		whileHover={ delay===0 ? { translateY: -5, transition: { duration: 0.2 }} : {} }
		onClick={() => { 
			setPrevPathname("/app");
			navigate("careerpath");
		}}
	>
		<Box sx = {{display: "flex", justifyContent: 'space-evenly', gap: "4px" }}>
				<Box sx = {{width: '220px', height: '70px'}}>
					<img 
						src="https://i.imgur.com/PNXmqqN.png" 
						style={{
							width: '100%', 
							objectFit: 'contain', 
							borderRadius: '15px', 
							height: '100%' 
						}}
					/>
				</Box>
			<Box sx = {{width: '100%', marginRight: "10px"}}>
					<h4 style = {{fontSize: 14, fontWeight: 420, lineHeight: 2}}>
						No learning progress so far!
					</h4>
					<p style = {{fontSize: 10, fontWeight: 300}}>Click here to start learning about your future and the career paths available</p>
				</Box>
				<Box sx={{
					marginRight: '7px', 
					display: 'flex', 
					justifyContent: 'center', 
					alignItems: 'center', 
					gap: "6px",
					flexDirection: 'column',
					transition: "all 150ms ease-in", 
					opacity: hovered ? 1 : 0, 
					transform: hovered ? "translateY(0)" : "translateY(2px)" 
				}}>
					<Box sx = {{ 
						display: 'flex', 
						justifyContent: 'center', 
						alignItems: 'center', 
						color: '#2B2B2B', 
						backgroundColor: 'white', 
						borderRadius: '12px', 
						boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
						padding: "6px 8px",
						fontWeight: 550,
						fontSize: '14px'
					}}>
						Explore
					</Box>
				</Box>
			</Box>
	</motion.div>
	)
};





