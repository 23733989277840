import { Box, Button } from '@mui/material';
import TrophyGif from '../../../assets/img/trophy_cropped.gif';

export default ({ handleClick }) => {
	return (
		<Box sx={{ 
			width: "100%", 
			height: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			color: "#3B3B3B",
		}}>
			<Box sx={{ width: "500px", transform: "translateY(65px)" }}>
				<img src={TrophyGif} style={{width: "100%", height: "100%"}}/>
			</Box>
			<Box sx={{ fontWeight: 550, fontSize: "26px", display: "flex", flexDirection: "column", alignItems: "center" }}>
				<Box>Quiz finished!</Box><Box>Your progress has been saved.</Box>
			</Box>
			<Button 
				variant="contained" 
				sx={{ width: "240px", margin: "28px", height: "56px"}}
				onClick={()=>handleClick()}
			>
				Next
			</Button>
		</Box>
	)
}
