import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { useMainContext } from '../../../providers/MainProvider';
import { useNavigate } from 'react-router-dom';

export default ({ name, lessons, duration, icon, careerPathId, careerSpecId }) => {

	const { setSelectedCareerSpecId } = useMainContext();
	const navigate = useNavigate();

	return (
		<motion.div 
			style={{
				borderRadius: '10px', 
				backgroundColor: '#F3F7FF',
				display: 'flex',
				justifyContent: 'space-evenly',
				alignItems: 'center',
				minHeight: "60px",
				minWidth: "190px",
				cursor: "pointer"
			}}
			initial={{ scale: 0.95 }}
			whileHover={{ scale: 1 }}
			whileTap={{ scale: 0.95 }}
			transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}
			onClick={()=>{ 
				setSelectedCareerSpecId(careerSpecId);
				navigate("/app/learning");
			}}
		>
			<Box 
				dangerouslySetInnerHTML={{ __html: icon }} 
				style={{ 
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flex: 1,
					borderRadius: '10px',
					maxHeight: '34px',
					maxWidth: '34px',
					marginLeft: '16px',
					marginRight: '16px',
					fill: "#3B3B3B",
				}}
			/>
			<Box sx = {{flex: 3}}>
				<Box sx={{ fontWeight: 500, lineHeight: "18px"}}>{name}</Box>
				<Box sx = {{fontWeight: 500, fontSize: 9, color: '#797878'}}>{lessons} Lessons | {duration} mins</Box>
			</Box>
		</motion.div>
	)
}
