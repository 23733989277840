import { useLocation } from "react-router";
import { useMainContext } from '../../providers/MainProvider';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { useReducer, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Slider, Button, Switch, Select, MenuItem, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputField from './components/InputField';
import InputFieldSearch from './components/InputFieldSearch';
import AutoCompleteSchools from './components/AutoCompleteSchools';
import { useResetPwd } from '../../api/authApi.js';

export default ({ setOpen, setShowError, errorMsg, setErrorMsg }) => {

	const { pathname } = useResolvedPath();
	const location = useLocation();
	const navigate = useNavigate();
	const theme = useTheme();
	const { authType, setAuthType } = useMainContext();

	const initialFormState = {
		password: { value: "", valid: true },
		confirmPassword: { value: "", valid: true },
	}

	const regex = {
		password: /^.{6,}$/, 
		confirmPassword: /^.{6,}$/, 
	}

	const formReducer = (state, action) => {
		switch(action.type) {
			case "INPUT":
				return {...state, [action.field]: { ...state[action.field], value: action.payload }}
			case "VALIDATE":
				return { 
					...state, 
					[action.field]: { 
						...state[action.field], 
						valid: regex[action.field].test(action.payload) 
					}
				}
		}
	}

	const queryClient = useQueryClient();
	const [ form, dispatchForm ] = useReducer(formReducer, initialFormState);
	const resetPwdMutation = useResetPwd();

	if(resetPwdMutation.isSuccess) {		
		return (
			<Box sx={{ 
				display: "flex", 
				flexDirection: "column", 
				fontWeight: 500, 
				fontSize: "18px", 
				width: "400px", 
				textAlign: "center",
				alignItems: "center"
			}}>
				<Box sx={{ fontSize: "22px" }}>Success!</Box> 
				You may now login
				<Button 
					variant="contained"
					sx={{ width: "160px", marginTop: "12px" }}
					onClick={()=>setAuthType("LOGIN")}
				>
					Login
				</Button>
			</Box>
		)
	}
	else if(resetPwdMutation.isError) {		
		return (
			<Box sx={{ 
				display: "flex", 
				flexDirection: "column", 
				alignItems: "center", 
				gap: "14px", 
				width: "400px" 
			}}>
				<Box sx={{ fontWeight: 500, fontSize: "18px", textAlign: "center" }}>Error occured</Box>
				<Button 
					variant="contained"
					sx={{ width: "160px" }}
					onClick={()=>window.location.reload()}
				>
					Try Again
				</Button>
			</Box>
		)
	}
	else if(resetPwdMutation.isLoading) {
		return (
			<Box sx={{ 
				width: "100%", 
				height: "100%", 
				display: "flex", 
				alignItems: "center", 
				justifyContent: "center"
			}}>
				<CircularProgress
					variant="indeterminate"
					size="60px"
				/>
			</Box>
		)
	}
	else {
		return (
			<Box sx={{ 
				width: "500px",
				display: "flex",
				flexDirection: "column",
				borderRadius: "32px",
				color: "#3b3b3b",
				backgroundColor: "white"
			}}>
				<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: "16px" }}>
					<Box sx={{ fontSize: "28px", fontWeight: 650 }}>Reset your password</Box>
					<Box sx={{ fontSize: "16px", fontWeight: 400, marginTop: "6px" }}>Your new password must be different from previously used passwords</Box>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column"}}>
					<Box sx={{ marginTop: "8px"}}>
						<InputField 
							label="Password" 
							placeholder="******" 
							type="password" 
							tip="Must be at least 6 characters"
							field="password"
							value={form.password.value}
							error={!form.password.valid}
							dispatch={dispatchForm}
						/>
					</Box>
					<Box sx={{ marginTop: "8px"}}>
						<InputField 
							label="Confirm your password" 
							placeholder="******" 
							type="password" 
							tip="Enter your password again"
							field="confirmPassword"
							value={form.confirmPassword.value}
							error={!form.confirmPassword.valid}
							dispatch={dispatchForm}
						/>
					</Box>
				</Box>
				<Box sx={{ display: "flex", marginTop: "24px" }}>
					<Button 
						variant="contained" 
						sx={{ width: "100%" }}
						onClick={async ()=>{

							let validInput = true;
							Object.keys(form).forEach((key)=>{
								if(typeof form[key].value === "string" ) {
									dispatchForm({ type: "VALIDATE", field: key, payload: form[key].value });
								}
								validInput = validInput && form[key].valid && form[key].value!=="";
							});						
							validInput = validInput && form["password"].value === form["confirmPassword"].value

							if(validInput) {
								await resetPwdMutation.mutate({
									token: pathname.split("/").pop(),
									password: form.password.value,
								});
							}
							else {
								setErrorMsg("Passwords do not match");
								setShowError(true);	
							}
						}}
					>
						Reset password
					</Button>
				</Box>
				<Box sx={{ display: "flex", fontSize: "12px", fontWeight: 500, marginTop: "10px" }}>
					Don't need to reset your password?
					<Box 
						sx={{ 
							marginLeft: "5px", 
							fontWeight: 600, 
							color: theme.palette.primary.main,
							cursor: "pointer"
						}}
						onClick={()=>{
							setAuthType("LOGIN");
						}}
					>
						Sign in
					</Box>
				</Box>
			</Box>
		)
	}
}
