import { useState, useContext } from 'react';
import { Button, Box, InputBase, Typography, Select, MenuItem } from '@mui/material';
import { AdminContext } from '../../Admin';

export default ({ quiz, setQuiz }) => {

	const [ question, setQuestion ] = useState({ options: [] });
	const { openBar, setOpenBar, barContents, setBarContents } = useContext(AdminContext);

	return (
		<Box>
			<Typography>Multiple Choice (Single) question</Typography>
			Question text
			<InputBase onChange={(e)=>setQuestion((prev) => (
				{ ...prev, content: e.target.value }
			))}/>
			<Box>Answer</Box>
			{ Array.from(Array(4).keys()).map((index) => (
				<Box sx={{ display: "flex" }}>
					<Box sx={{ whiteSpace: "no-wrap", color: (question.answer===question.options[index] && Boolean(question.answer)) && "#1B56BA", fontWeight: (question.answer===question.options[index] && Boolean(question.answer)) && 550 }}>Choice { index+1 }</Box>
					<InputBase
						onChange={(e)=>{
							setQuestion((prev) => {
								const newOptionsArr = [...prev.options];
								newOptionsArr[index] = e.target.value;
								return {
									...prev, options: newOptionsArr
								}
							});
						}}
					/>
					<Button
						onClick={()=>setQuestion((prev) => ({
							...prev, answer: prev.options[index]
						}))}
					>
						Set as answer
					</Button>
				</Box>
			))}
			<Button 
				variant="outlined"
				onClick={()=> { 
					if(!question.content || !question.options || !question.answer || !question.options.includes(question.answer)) { 
						setBarContents({ msg: "Something is missing, please try again", severity: "error" });
						setOpenBar(true);
						return;
					};
					setQuiz((prev)=>({
						...prev, questions: [...prev.questions, {
							questionType: "MULTI_SINGLE",
							content: question.content,
							options: question.options,
							answer: question.answer
						}]
					}))
				}}
			>
				Add to quiz
			</Button>
		</Box>
	)	
}
