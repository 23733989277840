import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Box, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default ({name, icon, lessons, duration, index, desc, creatorName, progress, handleClick, pageState }) => {

	const theme = useTheme();
	const [ delay, setDelay ] = useState(0.25+index*0.15);
	useEffect(() => {
		setTimeout(()=>{ setDelay(0)}, 400);
	}, []);

	return (
		<motion.div
			key={index}
			style={{
				backgroundColor: "white",
				color: "#3b3b3b",
				boxShadow: "rgba(149, 157, 165, 0.125) 0px 1px 8px",
				borderRadius: "28px",
				cursor: "pointer",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between"
			}}
			initial={{ translateY: -50, opacity: 0 }}
			animate={{ translateY: 0, opacity: 1 }}
			whileTap={{ scale: 0.985 }}
			onClick={handleClick}
			transition={{ 
				type: 'spring', 
				bounce: 0.4, 
				duration: 0.5,
				delay: delay
			}}
			whileHover={ delay===0 ? { translateY: -5, transition: { duration: 0.2 }} : {} }
		>
			<Box sx={{ display: "flex", flexDirection: "column", padding: "44px 44px 0 44px" }}>
				<Box sx={{ display: "flex" }}>
					<Box
						sx={{ 
							display: "flex", 
							alignItems: "center", 
							justifyContent: "center",
							backgroundColor: theme.palette.primary.main,
							borderRadius: "16px",
							width: "60px",
							marginRight: "16px",
							height: "60px",
							padding: "12px",
							"& svg": {
								fill: "white",
								width: "100%",
							}
						}}
						dangerouslySetInnerHTML={{ __html: icon }}
					/>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Box sx={{ fontWeight: 500, fontSize: "24px", color: theme.palette.primary.main, lineHeight: "30px", marginBottom: "2px" }}>
							{name}
						</Box>
						<Box sx={{ fontWeight: 500, fontSize: "14px", color: theme.palette.secondary.main }}>
							{ lessons } lessons | { duration } minutes
						</Box>
					</Box>
				</Box>
				<Box sx={{ width: "100%", height: "140px", minHeight: "140px", marginTop: "30px", fontWeight: 450, overflowY: "scroll" }}>
					{ desc }
				</Box>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column"}}>
				<LinearProgress variant="determinate" value={progress * 100} sx={{ height: "8px", borderRadius: "4px", margin: "30px 44px" }}/>
				<Box sx={{ width: "100%", height: "3px", backgroundColor: "#F3F7FF" }} />
				<Box sx={{ padding: "22px 40px", display: "flex", justifyContent: "space-between" }}>
					<Box sx={{ 
						display: "flex", 
						alignItems: "center", 
						justifyContent: "center", 
						backgroundColor: progress===1 ? "#EEFAF6" : "#F3F7FF", 
						color: progress === 1 ? "#0ac98aa" : theme.palette.primary.main,
						padding: "10px 22px",
						fontWeight: 500,
						fontSize: "13px",
						borderRadius: "18px"
					}}>
						{ progress===1 ? "Completed" : "Ongoing" }
					</Box>
				</Box>
			</Box>
		</motion.div>
	)
}
