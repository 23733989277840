import React, { useState } from 'react';
import { useMainContext } from '../../../providers/MainProvider';
import { Box, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';

export default ({ userQuery, setButtonShow, preview, setPreview, image, setImage }) => {

	const [ hovered, setHovered ] = useState(false);
	const { baseURL } = useMainContext();

	const handleDrop = (acceptedFiles) => {
		
		setImage(acceptedFiles[0]);
		setButtonShow(true);

		const file = acceptedFiles[0];
		const reader = new FileReader();

		reader.onload = () => {
			console.log("WORKING WORKING")
			setPreview(reader.result)
		}

		reader.readAsDataURL(file);
	}

	// console.log(image)

	return (
		<Box
			sx={{ 
				width: 150, 
				height: 150,
				backgroundColor: "#D9D9D9",
				borderRadius: "50%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "relative",
				cursor: "pointer"
			}}
			onMouseEnter={()=>setHovered(true)}
			onMouseLeave={()=>setHovered(false)}
			onClick={()=>{/* UPLOAD HERE */}}
		>
			<img
				src={preview ? preview : `${baseURL}${userQuery.data.data.picturePath}`}
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					borderRadius: '50%'
				}}
			/>
			<Dropzone
				acceptedFiles=".jpg,.jpeg,.png,.svg"
				multiple={true}   
				onDrop={handleDrop}
			>
				{({ getRootProps, getInputProps }) => (
					<Box
						{...getRootProps()}
						p="1rem"
						width="100%"
						height="100%"
						sx={{ 
							"&:hover": { cursor: "pointer" }, 
							borderRadius: '50%', 
							position: 'absolute',
							zIndex: 10
						}}
						>
							<input {...getInputProps()} />
					</Box>
				)}
			</Dropzone>
			<Box sx={{ 
				position: "absolute",
				width: "100%", 
				height: "100%",
				backgroundColor: "black",
				transition: "all 120ms ease-out",
				borderRadius: "inherit",
				opacity: hovered ? 0.5 : 0,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontSize: "14px",
				textAlign: "center",
				color: "white",
				fontWeight: 450
			}}>
				{image ? 'Image Selected' : 'Change Picture'}
			</Box>
		</Box>
	)
}
