import * as React from 'react';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { useGetAllSchoolNames } from '../../../api/schoolsApi';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
		fontSize: "12px",
		fontWeight: 500
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default ({ label, value, placeholder, dispatch, field, disabled }) => {
	
	const getSchoolNamesQuery = useGetAllSchoolNames();
	const schools = getSchoolNamesQuery.isSuccess ? ( 
		getSchoolNamesQuery.data.data.map((school) => school.SchoolName)
	) : (
		[]
	)

  return (
		<Box sx={{ display: "flex", flexDirection: "column", width: "100%", fontSize:"12px", fontWeight: 600 }}>
			<Box sx={{ margin: "0 0 5px 3px"}}>{label}</Box>
			<Autocomplete
				sx={{ width: "100%"}}
				disableListWrap
				value={value}
				PopperComponent={StyledPopper}
				ListboxComponent={ListboxComponent}
				options={schools}
				groupBy={(option) => option[0].toUpperCase()}
				renderInput={(params) => (
					<div ref={params.InputProps.ref}>
						<InputBase {...params.inputProps} placeholder={placeholder} sx={{ height: "36px" }} />
					</div>
				)}
				disabled={disabled}
				onChange={(event, newValue) => {
					if(dispatch) {
						dispatch({ 
							type: "INPUT", 
							field, 
							payload: newValue 
						});
						dispatch({
							type: "VALIDATE",
							field,
							payload: newValue 
						});
					}
				}}
				renderOption={(props, option, state) => [props, option, ""]}
				renderGroup={(params) => params}
			/>
		</Box>
  );
}
