import { Box, Button } from '@mui/material';

export default ({ number, svg, text, handleClick}) => {
	return (
		<Button
			sx={{
				width: "100%", 
				display: "flex", 
				justifyContent: "flex-start", 
				marginBottom: "8px", 
				padding: "15px 20px", 
				gap: "12px", 
				color: "#3b3b3b" 
			}}
			onClick={handleClick}
		>
			{ number && (
				<Box sx={{ 
					fontWeight: 650, 
					borderRadius: "50%", 
					backgroundColor: "#3B3B3B", 
					color: "white", 
					display: "flex", 
					justifyContent: "center", 
					alignItems: "center", 
					minWidth: "22px", 
					height: "22px"
				}}>
					{number}
				</Box>
			)}
			{ svg && (
				<Box>Unfinished Styling</Box>
			)}
			<Box sx={{ fontWeight: 550 }}>{text}</Box>
		</Button>
	)
}
