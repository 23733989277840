import { Box, Button, InputBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ToggleButton from './ToggleButton';

export default ({ question, answers, updateAnswers, validation, correctAnswer, index }) => {

	const theme = useTheme();
	const errorCondition = !validation[index] && answers[index] !== correctAnswer;

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Box sx={{ backgroundColor: "#F4F4F4", borderRadius: "20px", padding: "20px", fontWeight: 450, display: "flex", justifyContent: "center" }}>
				{ question }
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px", height: "50px" }}>
				<Box sx={{ width: "300px" }}>
					<InputBase
						sx={{
							boxShadow: `${errorCondition ? theme.palette.error.light + "33" : "rgba(149, 157, 165, 0.35)"} 0px 2px 16px`,
							borderWidth: "2px",
							borderRadius: "12px",
							height: "44px",
							"&.Mui-focused": { borderColor: errorCondition && theme.palette.error.light}, 
							"&:hover": { borderColor: errorCondition ? theme.palette.error.light : theme.palette.secondary.main },
						}}
						onChange={(event)=>updateAnswers(index, event.target.value)}
					/>		
				</Box>
			</Box>
		</Box>
	)
}
