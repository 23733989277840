import AnimWrapperDelayed from './AnimWrapperDelayed';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useMainContext } from '../../providers/MainProvider';

export default ({duration, delay, height, children}) => {

	const transitionToRightBlock = {
		width: "40px", 
		minWidth: "40px", 
		flex: 0, 
	}
	const transitionFromRightBlock = {
		width: "100%", 
		minWidth: "none", 
		flex: 3, 
	}
	const transitionFromLeftBlock = {
		height: "calc(100% - 20px)",
	}
	const transitionToLeftBlock = {
		height: height ? height : "calc(100% - 200px)",
	}

	const { prevPathname } = useMainContext(); 
	const [ stylesRightBlock, setStylesRightBlock ] = useState(prevPathname==="/app" ? transitionFromRightBlock : transitionToRightBlock);
	const [ stylesLeftBlock, setStylesLeftBlock ] = useState(prevPathname==="/app" ? transitionFromLeftBlock : transitionToLeftBlock);

	const [ show, setShow ] = useState(false);

	useEffect(()=>{
		setStylesRightBlock(transitionToRightBlock);
		setStylesLeftBlock(transitionToLeftBlock);
		setTimeout(() => {
			setShow(true);
		}, delay ? delay : 200);
	}, [])

	return (
		<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "100vh" }}>
			<motion.div
				style={{ 
					backgroundColor: "#F3F7FF", 
					borderRadius: "30px", 
					width: "100%",
					flex: 2,
					overflow: "hidden",
					position: "relative",
					...stylesLeftBlock
				}}
				exit={{ opacity: 0 }}
				transition={{ duration }}
				layout
			>
				{show && children}
			</motion.div>
			<motion.div
				style={{
					height: "100%", 
					backgroundColor: "white",
					...stylesRightBlock
				}}
				layout
			/>
		</Box>	
	)
}
