import { Box } from '@mui/material';
import { useState } from 'react';
import './animations.css';

export default ({sx, onClick, selected, children}) => {

	const [ hovered, setHovered ] = useState(false);

	return (
		<Box 
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				cursor: "pointer",
				fontWeight: 500,
				fontSize: "15px",
				...sx
			}}
			onClick={onClick}
			onMouseEnter={()=>setHovered(true)}
			onMouseLeave={()=>setHovered(false)}
		>
			{children}	
			<Box 
				sx={{
					borderRadius: "50%",
					width: "8px",
					height: "8px",
					backgroundImage: 'linear-gradient(to right, #7474BF 0%, #348AC7 51%, #7474BF 100%)',
					opacity: (hovered || selected) ? 1 : 0,
					transition: "all ease-in 200ms"
				}}
				className={hovered && "ball"} 
			/>
		</Box>
	)
}
