import { Box, Typography } from '@mui/material'
import Popup from '../../../global_components/Animated/Popup';
import CareerSpecCard from '../../home/components/CareerSpecCard';

export default ({form, setOpen}) => {

	return (
		<Popup setOpen={setOpen}>
			<Box sx={{ 
				marginTop: "30px",
				width: "1000px", 
				overflowY: "scroll",
				overflowX: "hidden",
				backgroundColor: "white",
				padding: "32px",
				borderRadius: "16px",
				boxShadow: "rgba(100, 100, 111, 0.05) 0px 7px 29px 0px",
			}}>
				<Box sx={{
					display: "flex"
				}}>
					<Typography>Career Specialisation card preview</Typography>
					<CareerSpecCard 
						name={form.name}
						icon={form.media.icon}
						color={form.color}
						lessons={8}
						duration={form.duration}
						desc={form.descBrief}
						image={form.media.cardMedia}
						progress={0.4}
						handleClick={()=>console.log("clicked")}
						index={1}
					/>
				</Box>
			</Box>
		</Popup>
	)
}
