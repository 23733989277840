import { useQuery } from '@tanstack/react-query';
import { axiosClient } from './axiosClient';

export const useGetUserActivity = () => useQuery({
	queryKey: ['userActivity'],
	queryFn: async () => await axiosClient.get("featuredactivity/user?format=LIST")
});

export const useGetFeaturedActivity = () => useQuery({
	queryKey: ['featuredActivity'],
	queryFn: async () => await axiosClient.get("featuredActivity")
});
