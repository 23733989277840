import { Box, InputBase } from '@mui/material';
export default ({label, placeholder, type, tip, field, multiline, value, error, disabled, dispatch, sx}) => (
	<Box sx={{ display: "flex", flexDirection: "column", width: "100%", fontSize:"12px", fontWeight: 600 }}>
		<Box sx={{ margin: "0 0 5px 3px"}}>{label}</Box>
		<InputBase 
			fullWidth 
			placeholder={placeholder} 
			type={type} 
			sx={{ height: "36px", ...sx}} 
			value={value}
			error={error}
			multiline={multiline}
			disabled={disabled}
			onChange={(event) => { 
				if(dispatch) {
					dispatch({ 
						type: "INPUT", 
						field, 
						payload: event.target.value 
					});
					dispatch({
						type: "VALIDATE",
						field,
						payload: event.target.value
					});
				}
			}}
		/>
		{ tip && <Box sx={{ margin: "5px 0 0 5px", fontSize: "8px", opacity: "65%", fontWeight: "500" }}>{tip}</Box>}
	</Box>
)

