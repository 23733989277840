import { Box } from '@mui/material';
import { useGetUserById } from '../../../api/userApi';
import { useMainContext } from '../../../providers/MainProvider';

export default ({ userId, postDate }) => {

	const getUserByIdQuery = useGetUserById(userId);
	const { baseURL } = useMainContext();

	return (
		(getUserByIdQuery.isSuccess && getUserByIdQuery.data.data) && 
			<Box sx={{ 
				width: "200px", 
				display: "flex",
				alignItems: "center",
				gap: "10px"
			}}>
				<Box sx={{ width: "56px", height: "56px", borderRadius: "50%", backgroundColor: "#D9D9D9"}}>
					<img
						src={`${baseURL}${getUserByIdQuery.data.data.picturePath}`}
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'cover',
							borderRadius: '50%'
						}}
					/>
				</Box>
				<Box sx={{ transform: "translateY(-3px)" }}>
					<Box sx={{ 
						fontWeight: 600, 
						fontSize: "17px",
						color: "#3B3B3B"
					}}>
						{getUserByIdQuery.data.data.name}
					</Box>
					<Box sx={{ 
						fontWeight: 450, 
						fontSize: "10px",
						color: "#A4A4A4"
					}}>
						{postDate > 0 ? (postDate > 1 ? `${postDate} days ago` : `${postDate} day ago`) : 'Today'}
					</Box>
				</Box>
			</Box>
	)
}

