import { useState } from 'react';
import { Box, InputBase, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useResetPwdEmail } from '../../api/authApi';

export default () => {

	const theme = useTheme();
	const resetPwdEmail = useResetPwdEmail();
	const [ emailInput, setEmailInput ] = useState("");
	const [ sent, setSent ] = useState(false);

	return sent ? (
		<Box sx={{
			padding: "30px",
			display: "flex",
			width: "315px",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center"
		}}>
			<Box sx={{ 
				fontSize: "12px", 
				fontWeight: 500, 
				color: "#3B3B3B", 
				lineHeight: "115%",
				marginBottom: "8px",
				textAlign: "center"
			}}>
				Email sent to <Box sx={{ color: theme.palette.primary.main}}>{emailInput}</Box>
				<br/>
				Remember to check your spam/junk folders!
			</Box>
		</Box>
	) : (
		<Box sx={{
			padding: "30px",
			display: "flex",
			width: "315px",
			flexDirection: "column",
			alignItems: "center"
		}}>
			<Box sx={{ 
				fontSize: "12px", 
				fontWeight: 500, 
				color: "#3B3B3B", 
				lineHeight: "115%",
				marginBottom: "8px",
				textAlign: "center"
			}}>
				We'll email you instructions <br/> on how to reset your password
			</Box>
			<Box sx={{
				display: "flex",
				gap: "4px",
				height: "24px"
			}}>
				<InputBase
					placeholder="Enter your email"
					sx={{ 
						height: "100%", 
						flex: "3"
					}}
					onChange={(event)=>setEmailInput(event.target.value)}
				/>	
				<Button 
					variant="contained"
					sx={{ 
						height: "100%", 
						borderRadius: "8px",
						fontSize: "12px",
						flex: "1"
					}}
					onClick={()=>{ resetPwdEmail.mutate({ email: emailInput }); setSent(true)}}
				>
					Reset
				</Button>
			</Box>
		</Box>
	)
}
