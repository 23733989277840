import { useState, useContext } from 'react';
import { Popover, InputBase, Box, Typography, Button, Select, MenuItem, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import Popup from '../../../global_components/Animated/Popup';
import { ReactComponent as XIcon } from '../../../assets/misc_icons/x-solid.svg';
import { AdminContext } from '../Admin';
import QuizPreview from './QuizPreview';
import CreateTrueFalse from './quiz_components/CreateTrueFalse';
import CreateTextInput from './quiz_components/CreateTextInput';
import CreateFillInBlank from './quiz_components/CreateFillInBlank';
import CreateMultiChoiceSingle from './quiz_components/CreateMultiChoiceSingle';
import CreateMultiChoiceMultiple from './quiz_components/CreateMultiChoiceMultiple';

export default ({ form, setForm, quiz, setQuiz, setOpen }) => {

	const { openBar, setOpenBar, barContents, setBarContents } = useContext(AdminContext);
	const [ questionType, setQuestionType ] = useState(null);
	const [ selectedQuestion, setSelectedQuestion ] = useState(null);
	const [ anchorEl, setAnchorEl ] = useState(null);
	const theme = useTheme();

	return (
		<Popup setOpen={setOpen}>
			<Box sx={{
				display: "flex",
				width: "100%",
				gap: "20px"
			}}>
				<Box sx={{ 
					backgroundColor: "white",
					maxHeight: "600px",
					overflowY: "scroll",
					overflowX: "hidden",
					padding: "32px",
					borderRadius: "16px",
					boxShadow: "rgba(100, 100, 111, 0.05) 0px 7px 29px 0px",
					display: "flex",
					flexDirection: "column",
					gap: "12px",
					width: "100%"
				}}>
					<Typography>Quiz Information</Typography>
					Slide number to appear after
					<Tooltip title="E.g entering in '2' means this quiz will appear after the 2nd content slide">
						<InputBase
							onChange={(e)=>setQuiz((prev) => ({ ...prev, afterSlideNum: Number(e.target.value)-1 }))}
						/>
					</Tooltip>
					Title
					<Tooltip title="This is the title of the quiz">
						<InputBase
							onChange={(e)=>setQuiz((prev) => ({ ...prev, title: e.target.value }))}
						/>
					</Tooltip>
					<Typography>Questions</Typography>
					All Questions
					<Box sx={{
						padding: "14px",
						border: "1px solid #DEDEDE",
						borderRadius: "4px",
						display: "flex", 
						flexDirection: "column",
						gap: "12px"
					}}>
						{ quiz.questions.map((question, index) => (
							<Button sx={{ display: "flex", alignItems: "center" }} onClick={(e)=>{ setSelectedQuestion(index); setAnchorEl(e.currentTarget) }}>
								{question.content.length > 16 ? question.content.substring(0, 16) + "..." : question.content }
								<Box sx={{ width: "24px" }}/>
								Delete
								<Box sx={{ width: "6px" }}/>
								<XIcon width="10px" fill={theme.palette.primary.main}/>	
							</Button>
						))}
						{ quiz.questions.length === 0 && (
							<>No questions added yet</>
						)}
						<Popover
							open={selectedQuestion!==null}
							onClose={()=>setSelectedQuestion(null)}
							anchorEl={anchorEl}
						>
							{ selectedQuestion!==null && (
								<Box sx={{ display: "flex", flexDirection: "column", padding: "30px", gap: "10px" }}>
									<Typography>Delete "{quiz.questions[selectedQuestion].content}"?</Typography>
									<Box sx={{ display: "flex", gap: "10px" }}>
										<Button 
											variant="outlined"
											onClick={()=>setSelectedQuestion(null)} 
										>
											No
										</Button>
										<Button 
											variant="contained"
											onClick={()=>{ setQuiz((prev)=>{
												const newQuestions = quiz.questions.slice();
												newQuestions.splice(selectedQuestion, 1)
												setSelectedQuestion(null);
												return { ...prev, questions: newQuestions }
											})}}
										>
											Yes
										</Button>
									</Box>
								</Box>
							)}
						</Popover>
					</Box>
					Select question type to add
					<Select 
						sx={{ height: "36px" }}
						onChange={(e)=>setQuestionType(e.target.value)}
					>
						<MenuItem value="TRUE_FALSE">True or False</MenuItem>
						<MenuItem value="FILL_IN_BLANK_SELECT">Fill in blank (Select)</MenuItem>
						<MenuItem value="TEXT_INPUT">Fill in blank (Input)</MenuItem>
						<MenuItem value="MULTI_SINGLE">Multiple Choice (One Answer)</MenuItem>
						<MenuItem value="MULTI_LIST">Multiple Choice (Multi Answer)</MenuItem>
					</Select>
					<Box sx={{
						padding: "14px",
						border: "1px solid #DEDEDE",
						borderRadius: "4px"
					}}>
						{ questionType==="TRUE_FALSE" && (
							<CreateTrueFalse quiz={quiz} setQuiz={setQuiz} />
						)}
						{ questionType==="FILL_IN_BLANK_SELECT" && (
							<CreateFillInBlank quiz={quiz} setQuiz={setQuiz} />
						)}
						{ questionType==="TEXT_INPUT" && (
							<CreateTextInput quiz={quiz} setQuiz={setQuiz} />
						)}
						{ questionType==="MULTI_SINGLE" && (
							<CreateMultiChoiceSingle quiz={quiz} setQuiz={setQuiz} />
						)}
						{ questionType==="MULTI_LIST" && (
							<CreateMultiChoiceMultiple quiz={quiz} setQuiz={setQuiz} />
						)}
					</Box>
					<Button 
						variant="outlined" 
						sx={{ width: "160px" }}
						onClick={() => { 
							setForm((prev) => ({ ...prev, quizzes: [...prev.quizzes, quiz]}));
							setOpen(false);
						}}
					>
						Add to module
					</Button>
				</Box>
				<Box sx={{ 
					minWidth: "900px",
					height: "600px",
					backgroundColor: "white",
					borderRadius: "16px",
					boxShadow: "rgba(100, 100, 111, 0.05) 0px 7px 29px 0px",
				}}>
					<QuizPreview quizSlide={quiz}/>
				</Box>
			</Box>
		</Popup>
	)
}
