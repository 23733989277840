import { useState, createContext, useMemo, useContext} from 'react';

const FlowContext = createContext();

export default ({children}) => {
    const [nodeLabel, setNodeLabel] = useState("")
	const [nodeShortDesc, setNodeShortDesc] = useState("")
	const [nodeLongDesc, setNodeLongDesc] = useState("")

    const value = {
        nodeLabel,
        setNodeLabel,
        nodeShortDesc,
        setNodeShortDesc,
        nodeLongDesc,
        setNodeLongDesc
    }

    const valueMemo = useMemo(() => value, [{...value}])

    return (
        <FlowContext.Provider value={valueMemo}>
            {children}
        </FlowContext.Provider>
    )
}

export const useFlowContext = () => useContext(FlowContext);