import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as FacebookIcon } from '../assets/socialmedia_img/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/socialmedia_img/instagram.svg';
import { ReactComponent as LinkedInIcon } from '../assets/socialmedia_img/linkedin.svg';

export default () => {

	const theme = useTheme();

	return (
		<Box sx={{ 
			width: "100vw", 
			maxWidth: "100%",
			height: "120px",
			borderTop: "1px solid #E8E8E8",
			backgroundColor: "white",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: "0 64px"
		}}>
			<Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
				<Box>© {new Date().getFullYear()} Advance Australia Careers Limited</Box>
				<Box sx={{ fontSize: "10px", transform: "translateY(1.5px)" }}>Site developed by Ethan Han & Adrian Si</Box>
			</Box>
			<Box sx={{ 
				display: "flex", 
				alignItems: "center", 
				gap: "20px", 
				fill: theme.palette.primary.main, 
				"& svg": {
					width: "18px"
				} 
				}}>
				<FacebookIcon />
				<InstagramIcon />
				<LinkedInIcon />
			</Box>
		</Box>
	)
}

