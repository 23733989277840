import { Box } from '@mui/material';
import { ReactComponent as CheckIcon } from '../../assets/misc_icons/check-solid.svg';

export default ({children}) => (
	<Box sx={{
		display: "flex",
		gap: "10px",
		fontSize: "16px",
		alignItems: "center"
	}}>
		<Box sx={{
			backgroundImage: 'linear-gradient(to right, #7474BF 0%, #348AC7 100%)',
			width: "20px",
			height: "20px",
			borderRadius: "6px",
			padding: "5px",
			display: "flex", 
			alignItems: "center",
			justifyContent: "center",
		}}>
			<CheckIcon fill="white" />
		</Box>
		{children}
	</Box>
)
