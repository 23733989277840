import React, { useState, useEffect } from 'react';
import { Box, InputBase, Button, Typography, IconButton, Collapse, Snackbar } from '@mui/material';
import Dropzone from 'react-dropzone';
import { AnimatePresence } from 'framer-motion';
import { ImageOutlined, DeleteOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import AnimPopIn from '../../../global_components/Animated/AnimPopIn';
import { ReactComponent as PlaneLogo } from '../../../assets/misc_icons/paper-plane-solid.svg';
import { useCreateQuestion } from '../../../api/questionApi';
import { useAddComment } from '../../../api/commentApi';
import { useGetUser } from '../../../api/userApi';

export default ({ showAsk, showAskReal, toggleShowComment, selectedQuestion }) => {

	const theme = useTheme();
	
	// States
	const [ answerInput, setAnswerInput ] = useState("");
	const [ image, setImage ] = useState([]);
	const [ isImage, setIsImage ] = useState(false);
	const [ openSnackbar, setOpenSnackbar ] = useState(false);

	const addCommentMutation = useAddComment();
	const getUserQuery = useGetUser();

	const submitComment = async () => {

		
		if (getUserQuery.isSuccess) {

			const formData = new FormData();
			formData.append("description", answerInput);
			formData.append("questionId", selectedQuestion);
			formData.append("userId", getUserQuery.data.data._id);
			if (image) {
				for (const img of image) {
					formData.append("picture", img);
					formData.append("picturePath", img.name);
				}
			}
			// formData.append("replies", [])
			
			addCommentMutation.mutate({
				data: formData
			})

			// Resetting States
			setAnswerInput("");
			setImage([]);
			toggleShowComment();
		}

	}

	useEffect(() => {
		console.log("rendered already");
	}, []);

	return (
		<AnimPopIn>
			<Collapse in={showAsk}>
				<Box sx={{ paddingTop: "20px", height: showAskReal ? "auto" : "317.2px"  }}> 
					<AnimatePresence exitBeforeEnter>
						{ showAskReal && (
							<>
							<Snackbar 
								anchorOrigin={{vertical: "top", horizontal: "center" }} 
								open={openSnackbar} 
								onClose={()=>setOpenSnackbar(false)}
								message='You must select a category first e.g "Engineering"'
							/>
							<AnimPopIn
								sx={{
									width: "100%",
									borderRadius: "16px",
									padding: "20px 32px",
									backgroundColor: "white",
									display: "flex",
									flexDirection: "column",
									gap: "8px",
									boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px",
								}}
							>
								<Box sx={{
									backgroundColor: "#F3F7FF",
									width: "100%",
									borderRadius: "16px",
									padding: "14px"
								}}>
									<InputBase 
										value={answerInput}
										onChange={(e)=>setAnswerInput(e.target.value)}
										variant="no-style" 
										multiline
										placeholder="Add answer here..."
										
										sx={{ 
											color: "#3B3B3B", 
											height: "150px",
											fontSize: "14px", 
											alignItems: "flex-start",
											overflowX: "hidden",
											overflowY: "scroll",
											padding: 0,
										}}
									/>
								</Box>

								<Collapse
									in={isImage}
								>
									<Dropzone
									acceptedFiles=".jpg,.jpeg,.png,.svg"
									multiple={true}   
									onDrop={(acceptedFiles) => {
										if (image.length < 3) {
											setImage(prev => [...prev, acceptedFiles[0]])}
										}
									}
								>
									{({ getRootProps, getInputProps }) => (
										<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
											<Box
											{...getRootProps()}
											border={`2px dashed #A4A4A4`}
											p="1rem"
											width="100%"
											sx={{ "&:hover": { cursor: "pointer" }, borderRadius: '10px', display: 'flex', justifyContent: 'space-between' }}
											>
												<input {...getInputProps()} />
												<p style={{color: '#3B3B3B'}}>{image.length < 3 ? 'Add Image Here' : 'Maximum Image Selected'}</p>
												<Box>
													<Typography>{image.length} files</Typography>
												</Box>
											</Box>
											{image && (
												<IconButton
													onClick={() => setImage([])}
													sx={{ width: "15%" }}
												>
													<DeleteOutlined 
														sx={{ "&:hover": { cursor: "pointer", backgroundColor: 'none' } }}
													/>
												</IconButton>
											)}
										</Box>
										)}
									</Dropzone>
								</Collapse>

								<Box sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									height: "60px"
								}}>
									<Button
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											cursor: 'pointer',
											width: "auto",
											paddingLeft: "12px",
											paddingRight: "14px",
											color: "#A4A4A4",
											"&:hover": {
												color: "#3B3B3B"
											}
										}}
										onClick={() => setIsImage(!isImage)}
									>
										<ImageOutlined />
										<Typography 
											sx={{ fontWeight: '400', fontSize: "14px", paddingLeft: '5px'}}
										>
											Image
										</Typography>
									</Button>
									<Box sx={{ display: "flex", gap: "6px" }}>
										<Button 
											variant="outlined"
											sx={{ 
												width: "120px", 
											}}
											onClick={()=>toggleShowComment()}
										>
											Cancel
										</Button>
										<Button 
											variant="contained"
											sx={{ 
												width: "120px", 
												display: "flex",
												gap: "6px",
											}}
											onClick={submitComment}
										>
											<PlaneLogo />
											Submit
										</Button>
									</Box>
								</Box>
							</AnimPopIn>
							</>
						)}
					</AnimatePresence>
				</Box>
			</Collapse>
		</AnimPopIn>
	)
}
