import "./style.css";
import { ReactComponent as LeftArrow} from '../../../assets/misc_icons/left-arrow.svg'
import { ReactComponent as RightArrow} from '../../../assets/misc_icons/right-arrow.svg'
import { Button } from '@mui/material';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const MAX_VISIBILITY = 2;

export default ({ children, sx }) => {
  const [active, setActive] = useState(2);
  const count = children.length;

  return (
    <div 
      className="carousel"
      style={sx}
    >
      {active > 0 && (
        <motion.div 
          className="nav left" 
          onClick={() => setActive((i) => i - 1)}
          intial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
        >
          <Button 
            variant="light-circular"
            sx={{ 
              position: "absolute",
              pointerEvents: "auto",
              transition: "all 200ms cubic-bezier(0.12, 0.83, 0.58, 1)",
            }}
          >
            <LeftArrow />
          </Button>
        </motion.div>
      )}
      {children.map((child, i) => (
        <div
          className="card-container"
          style={{
            "--active": i === active ? 1 : 0,
            "--offset": (active - i) / 3,
            "--direction": Math.sign(active - i),
            "--abs-offset": Math.abs(active - i) / 3,
            "pointer-events": active === i ? "auto" : "none",
            opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
            display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
          }}
        >
          {child}
        </div>
      ))}
      <AnimatePresence>
        {active < count - 1 && (
          <motion.div 
            className="nav right" 
            onClick={() => setActive((i) => i + 1)}
            intial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
          >
            <Button 
              variant="light-circular"
              sx={{ 
                position: "absolute",
                pointerEvents: "auto",
                transition: "all 200ms cubic-bezier(0.12, 0.83, 0.58, 1)",
              }}
            >
              <RightArrow />
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
