import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import Popup from '../../../../global_components/Animated/Popup';

const tabData = [
	{
		value: "work",
		label: "Work With Us",
	},
	{
		value: "workshop",
		label: "Have Us Host a Workshop"
	},
	{
		value: "sponsor",
		label: "Sponsor Us"
	},
	{
		value: "donate",
		label: "Donate"
	}
]

export default ({open, setOpen}) => {

	const [ tabValue, setTabValue ] = useState(tabData[0].value);	
	const theme = useTheme();

	return (
		<AnimatePresence exitBeforeEnter> {
			open && ( 
				<Popup setOpen={setOpen}>
						<Box sx = {{
							width: "clamp(40vw, 1000px, 90vw)", 
							height: "clamp(40vh, 600px, 92vh)",
							borderRadius: '20px', 
							display: 'flex',
							backgroundColor: '#FFFFFF',
							marginTop: '50px',
							padding: "24px",
							alignItems: "center",
							gap: "24px"
						}}>
							<Tabs 
								orientation="vertical"
								value={tabValue}
								onChange={(e, value)=>setTabValue(value)}
								color="secondary"
								sx={{
									"& .Mui-selected": { 
										color: `${theme.palette.primary.main} !important`,
										backgroundColor: "#F3F7FF"
									},
									"& .MuiTabs-indicator": { },
									minWidth: "165px", 
									transform: "translateY(10px)"
								}}
							>
								{ tabData.map((tab) => (
									<Tab 
										value={tab.value}
										label={tab.label}
										disableRipple
										sx={{
											color: `#3B3B3B !important`,
											transition: "all 200ms ease",
											borderRadius: "4px 0 0 4px",
											textTransform: "none",
											fontWeight: 600,
											"&:hover": { 
												color: `${theme.palette.primary.main} !important`,
												backgroundColor: "#F3F7FF"
											}
										}}
									/>
								))}
							</Tabs>
							<Box sx={{
								backgroundColor: "#F3F7FF",
								borderRadius: "32px",
								width: "100%",
								height: "100%"
							}}>
							</Box>
						</Box>				
				</Popup>
			)
		}
		</AnimatePresence>
	)
}
