import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import ToggleButton from './ToggleButton';

export default ({ question, answers, updateAnswers, validation, correctAnswer, index }) => {

	const errorCondition = !validation[index] && answers[index] !== correctAnswer;

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Box sx={{ backgroundColor: "#F4F4F4", borderRadius: "20px", padding: "20px", fontWeight: 450, display: "flex", justifyContent: "center" }}>
				{ question }
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px", height: "50px" }}>
				<ToggleButton 
					answer={answers[index]}
					setAnswer={(value)=>updateAnswers(index, value)}
					value={true}
					isError={errorCondition && answers[index] === true}
				>
					True
				</ToggleButton>
				<ToggleButton 
					answer={answers[index]}
					setAnswer={(value)=>updateAnswers(index, value)}
					value={false}
					isError={errorCondition && answers[index] === false}
				>
					False
				</ToggleButton>
			</Box>
		</Box>
	)
}
