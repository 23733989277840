import { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { LinearProgress } from '@mui/material'
import { motion } from 'framer-motion';
import { ReactComponent as RightArrowIcon } from '../../../assets/misc_icons/right-arrow.svg';

export default ({ index, id, name, lessons, desc, image, color, progress, selectedCareerSpecId, setSelectedCareerSpecId, setRightPanelContent }) => {

	const [ hovered, setHovered ] = useState(false);
	const [ delay, setDelay ] = useState(0.15+index*0.15);
	useEffect(() => {
		setTimeout(()=>{ setDelay(0)}, 250);
	}, []);

	return (  
	<motion.div 
		onClick = {() => {
			setRightPanelContent("MODULES");
			setSelectedCareerSpecId(id)
		}} 
		style = {{ 
			cursor: 'pointer', 
			color: 'white', 
			borderRadius: '20px', 
			margin: '5px auto 25px auto', 
			width: '100%', 
			maxWidth: '600px',
			padding: '18px 18px 13px 18px', 
			backgroundColor: color,
			boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 15px -3px'
		}}
		onHoverStart={()=>setHovered(true)}
		onHoverEnd={()=>setHovered(false)}
		whileTap = {{ scale: 0.97 }}
		initial={{ translateY: -15, opacity: 0 }}
		animate={{ translateY: 0, opacity: 1 }}
		transition={{ delay, duration: 0.6, type: 'spring', bounce: 0.5 }}
		whileHover={ delay===0 ? { translateY: -5, transition: { duration: 0.2 }} : {} }
	>
		<Box sx = {{display: 'flex', flexDirection: 'column'}}>
			<Box sx = {{display: "flex", justifyContent: 'space-evenly', gap: "12px" }}>
				<Box sx = {{width: '100px', height: '70px'}}>
					<img src={ image } style={{width: '100%', objectFit: 'contain', borderRadius: '15px', height: '100%' }}/>
				</Box>
				<Box sx = {{width: '100%'}}>
					<h4 style = {{fontSize: 12, fontWeight: 420, lineHeight: 2}}>{ name }</h4>
					<p style = {{fontSize: 9.5, fontWeight: 300}}>{ desc }</p>
				</Box>
				<Box sx = {{marginRight: '7px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
					<Box sx = {{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#2B2B2B', width: '50px',height: '50px', backgroundColor: 'white', borderRadius: '15px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'}}>
						<h2>{ lessons }</h2>
					</Box>
					<Box>
						<p style = {{fontWeight: 400, fontSize: 11}}>Lessons</p>
					</Box>
				</Box>
			</Box>
						<Box sx = {{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: "30px"}}>
								<Box sx = {{width: '100%', paddingTop: '10px', marginLeft: "70px"}}>
					<LinearProgress variant = 'determinate' value = {progress * 100} color = 'secondary' sx = {{borderRadius: '20px', backgroundColor:'#c4c7ce'}}/>
				</Box>
							<Box sx={{display: "flex", alignItems: "center", gap: "2px", marginTop: '5px', marginRight: "4px", transition: "all 150ms ease-in", opacity: hovered ? 1 : 0, transform: hovered ? "translateY(0)" : "translateY(2px)" }}>
					<p style = {{fontSize: 9}}>Continue</p>
								<RightArrowIcon fill="white" style={{ width: "12px" }}/>
				</Box>
			</Box>
		</Box>
	</motion.div>
	)
};





