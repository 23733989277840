import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from './axiosClient';

export const useGetUsers = (ids) => useQuery({
	queryKey: ['users', ids],
	queryFn: async () => await axiosClient.get(`frontpage/users?ids=${ids.join(",")}`)
});

export const usePostContactEmail = () => {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async({name, email, number, message}) => {
			return await axiosClient.post("email/sendcontactemail", {
				name, email, number, message
			});
		}
	});
}
