import { useState, useEffect } from 'react';
import { Tooltip, Button, InputBase, Typography, MenuItem, Select, Box, CircularProgress } from '@mui/material';
import { useGetUser, usePatchUser } from '../../../api/userApi';

export default () => {

	const getUserQuery = useGetUser();		
	const patchUserMutation = usePatchUser();
	const [ msText, setMsText ] = useState("");
	useEffect(()=>{
		if(getUserQuery.isSuccess) {
			setMsText(getUserQuery.data.data.missionStatement);
		}
	}, [getUserQuery.data]);

	return (
		<Box>
			This has been temporarily setup for you to enter in your mission statement please ignore the shit formatting
			<InputBase 
				value={msText}
				onChange={(e)=>setMsText(e.target.value)}
			/>
			<Button 
				variant="contained"
				onClick={() => { 
					console.log(msText.length);
					if(msText.length > 118) {
						alert("message is too long, keep it to 118 characters or less");				
					}
					else {
						patchUserMutation.mutate({ missionStatement: msText })
					}
				}}
			>
				Save
			</Button>
		</Box>
	)
}
