import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Content from './Content';
import Homepage from './Homepage';
import Bar from './Bar';
import { useLearningContext, PageStates } from './providers/LearningProvider';
import { Box, Snackbar, CircularProgress } from '@mui/material';
import { useMainContext } from '../../providers/MainProvider';
import { useGetModule, useGetModulesByCareerSpec, getModuleProgress, updateModuleProgress } from '../../api/modulesApi';
import { useGetUser, usePatchUser } from '../../api/userApi';
import { useGetCareerSpecsByPath } from '../../api/careerSpecialisationApi';

export default () => {

	const { selectedCareerPathId, setSelectedCareerPathId, selectedCareerSpecId, setSelectedCareerSpecId, selectedModuleId, setSelectedModuleId } = useMainContext();
	const { pageState, error, setError } = useLearningContext();
	const navigate = useNavigate();
	const getCareerSpecsQuery = useGetCareerSpecsByPath(selectedCareerPathId);
	const getModulesQuery = useGetModulesByCareerSpec(selectedCareerSpecId);
	const getUserQuery = useGetUser();

	useEffect(() => {
		if(!selectedCareerPathId) {
			navigate("/app/careerpath");	
		}
	}, []);

	return (
		<Box sx={{ 
			width: "100vw", 
			maxWidth: "100vw", 
			height: "100vh", 
			maxHeight: "100vh",
			backgroundColor: "#F3F7FF",
			display: "flex",
			flexDirection: "column"
		}}>
			<Box sx={{ width: "100%", height: "60px", transition: "300ms ease-out"}}>
				<Bar />
			</Box>
			<Box sx={{ width: "100%", height: "calc(100% - 60px)", display: "flex" }}>
				<Box sx={{ width: "350px" }}>
					{ (getModulesQuery.isSuccess && getCareerSpecsQuery.isSuccess && getUserQuery.isSuccess ) ? (
						<Sidebar />
						) : (
						<Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
							<CircularProgress
								variant="indeterminate"
								size="60px"
							/>
						</Box>
						)
						}
				</Box>
				<Box sx={{ width: "calc(100% - 350px)", maxWidth: "calc(100% - 350px)", height: "100%", padding: "20px 30px 30px 5px" }}>
					{ pageState < PageStates.CONTENT ? (
						<Homepage />
					) : (
						<Content />
					)}
				</Box>
			</Box>
			<Snackbar
				open={error.show}
				autoHideDuration={3000}
				onClose={(event, reason) => { 
					if(reason!=="clickaway") { 
						setError({ show: false, msg: "" })
					}
				}}
				message={error.msg}
				anchorOrigin={{ vertical: "top", horizontal: "center"}}
			/>
		</Box>
	)
}
