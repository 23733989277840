import { useReducer, useContext } from 'react';
import { Box, Button } from '@mui/material';
import InputField from './InputField';
import { FormContext } from './FormContextProvider';

export default () => {

	const { state, dispatch } = useContext(FormContext);

	return (
		<Box sx={{ 
			display: "flex", 
			flexDirection: "column",
			justifyContent: "space-between",
			color: "#3B3B3B",
			width: "100%"
		}}>
			<Box sx={{
				flexDirection: "column",
				gap: "10px"
			}}>
				<Box sx={{ fontWeight: 550, fontSize: "30px", marginBottom: "24px" }}>My Details</Box>
				<Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
					<Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
						<InputField
							label="First name"
							placeholder="Enter your first name"
							field="firstName"
							value={state.firstName.value}
							error={!state.firstName.valid}
							dispatch={dispatch}
						/>
						<InputField
							label="Last name"
							placeholder="Enter your last name"
							field="lastName"
							value={state.lastName.value}
							error={!state.lastName.valid}
							dispatch={dispatch}
						/>
					</Box>
					<InputField
						label="Email"
						placeholder="Enter your email"
						value={state.email.value}
						error={!state.email.valid}
						dispatch={dispatch}
						disabled={true}
					/>
					<InputField
						label="Password"
						placeholder="Enter your password"
						value={state.password.value}
						error={!state.password.valid}
						dispatch={dispatch}
						type="password"
						disabled={true}
					/>
				</Box>
			</Box>
		</Box>
	)
}
