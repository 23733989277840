import { Box, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../assets/sidebar_icons/plus-solid.svg';
import { useState } from 'react';
import { useGetCareerPath } from '../../api/careerPathApi';
import { useGetCareerSpecsByPath } from '../../api/careerSpecialisationApi';
import CareerSpecCard from './components/CareerSpecCard';
import Popup from '../../global_components/Animated/Popup';
import { AnimatePresence } from 'framer-motion';
import { Link } from "react-router-dom";
import  magnifyingGlassImg  from '../../assets/misc_icons/search.png';
import bookImg from '../../assets/misc_icons/book-icon.png';
import treeImg from '../../assets/misc_icons/treediagram.png';

export default ({ open, setOpen, selectedCareerPathId }) => {

	const getCareerSpecsByPathQuery = useGetCareerSpecsByPath(selectedCareerPathId);
	const getCareerPathQuery = useGetCareerPath(selectedCareerPathId);
	const theme = useTheme()

	return (
		<AnimatePresence exitBeforeEnter> {
			open && ( 
				<Popup setOpen={setOpen}>
						<Box sx = {{
							width: "clamp(40vw, 1000px, 90vw)", 
							height: "clamp(40vh, 600px, 92vh)",
							borderRadius: '20px', 
							display: 'flex',
							backgroundColor: '#FFFFFF',
							marginTop: '50px',
							padding: "0 30px 0 10px"
							}}
						>
							{ (getCareerSpecsByPathQuery.isSuccess && getCareerPathQuery.isSuccess) ? (
								<>
								<Box sx = {{
									flex: 2, 
									display: 'flex', 
									justifyContent: 'center', 
									flexDirection: 'column',
									alignItems: 'left',
									padding: '55px'
									}}
								>
									{/* Title / Description */}
									<Box sx = {{
										display: 'flex', 
										justifyContent: 'left', 
										flexDirection: 'column',
										marginBottom: "24px"
										}}
									>
										<h1 style = {{fontWeight: 600, fontSize: 32, marginBottom: '18px'}}>{getCareerPathQuery.data.data.name}</h1>
										<h2 style = {{fontWeight: 400, fontSize: 18}}>{getCareerPathQuery.data.data.descLong}</h2>
									</Box>
									<Box sx = {{
										display: 'flex', 
										justifyContent: 'left', 
										alignItems: 'center',
										}}
									>
										<Accordion sx = {{
											backgroundColor: '#F3F7FF'
										}}
										>
											<AccordionSummary>
												<Box sx={{ 
														display: "flex", 
														padding: "5px", 
														gap: "14px", 
														alignItems: "center", 
														fontWeight: 550, 
														fontSize: 18, 
														color: theme.palette.primary.main, 
														}}>
													<img 
														src={magnifyingGlassImg} 
														style={{ 
															width: "30px", 
															height: '30px',
															}}
														/>
													Explore
												</Box>
											</AccordionSummary>
											<AccordionDetails>
												<Link to="/app/learning" style = {{textDecoration: 'none'}}>
												<Button sx = {{
													width: "100%", 
													display: "flex", 
													justifyContent: "flex-start", 
													marginBottom: "8px", 
													padding: "15px 20px", 
													gap: "12px", 
												}}>
													<Box sx = {{display: 'flex', justifyContent: 'space-evenly'}}>
														<img 
															src = {bookImg}  
															style = {{
																display: "flex", 
																justifyContent: "center", 
																alignItems: "center", 
																width: "22px", 
																height: "22px",
																marginRight: '10px'
															}}
														/>
														<h1 style = {{fontWeight: 500, fontSize: 16, color: '#2B2B2B'}}>Lessons</h1>
													</Box>
												</Button>
												</Link>
												<Link to="/app/careertree" style = {{textDecoration: 'none'}}>
												<Button sx = {{
													width: "100%", 
													display: "flex", 
													justifyContent: "flex-start", 
													marginBottom: "8px", 
													padding: "15px 20px", 
													gap: "12px", 
												}}>
													<Box sx = {{display: 'flex', justifyContent: 'space-evenly'}}>
														<img 
															src = {treeImg}  
															style = {{
																display: "flex", 
																justifyContent: "center", 
																alignItems: "center", 
																width: "22px", 
																height: "22px",
																marginRight: '10px'
															}}
														/>
														<h1 style = {{fontWeight: 500, fontSize: 16, color: '#2B2B2B'}}>Career Tree</h1>
													</Box>
												</Button>
												</Link>
												
											</AccordionDetails>
										</Accordion>
									</Box>
								</Box>
								<Box sx = {{
									flex: 3, 
									borderLeft: '1px solid #E4E1E1',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: '20px',
									padding: "0 40px"
									}}
								>
									<Box sx={{width: "100%"}}>
										{ getCareerPathQuery.data.data.media.popupMedia.includes("youtube")
										|| getCareerPathQuery.data.data.media.popupMedia.includes("youtu.be") ? (
											<iframe 
												src={getCareerPathQuery.data.data.media.popupMedia} 
												style={{
													width: "100%",
													aspectRatio: "16/9",
													borderRadius: '15px',
													border: 'none',
													boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px'
												}}
											>
											</iframe>
										) : (
											<Box
												style={{
													width: "100%",
													backgroundImage: `url(${getCareerPathQuery.data.data.media.cardMedia})`,
													backgroundSizing: "cover",
													backgroundPosition: "center",
													backgroundRepeat: "no-repeat",
													aspectRatio: "16/9",
													borderRadius: '15px',
													border: 'none',
													boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px'
												}}
											/>	
										)}
									</Box>
									<Box sx = {{
										textAlign: 'left', 
										display: 'flex', 
										justifyContent: 'left', 
										width: '100%',
										marginTop: '10px',
										marginBottom: '10px'
										}}
									>
										<h1 style={{fontWeight: 500, fontSize: 22}}>Course <span style={{color: '#797878'}}>Specialisations</span></h1>
									</Box>
									<Box sx = {{
										display: 'grid', 
										gridTemplateColumns: 'calc(50% - 10px) calc(50% - 10px)', 
										columnGap: '20px',
										rowGap: '15px',
										gridTemplateRows: '70px '.repeat(getCareerSpecsByPathQuery.data.data.length),
										justifyContent: 'center',
										overflowY: 'scroll',
										overflowX: 'hidden',
										width: "100%",
										height: '150px',
									}}>
										{ getCareerSpecsByPathQuery.data.data.map((spec) => (
											<CareerSpecCard 
												name={spec.name}
												lessons={spec.lessons}
												duration={spec.duration}
												icon={spec.media.icon}
												careerPathId={selectedCareerPathId}
												careerSpecId={spec._id}
											/>
										))}
									</Box>
									</Box>
									</>
							) : (
								<Box sx={{
									width: "100%",
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}>
									<CircularProgress size="60px" />
								</Box>
							)}
						</Box>				
				</Popup>
			)
		}
		</AnimatePresence>
	)
}
