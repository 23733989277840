import { useState, useContext } from 'react';
import { Button, Box, Checkbox, InputBase, Typography, Select, MenuItem } from '@mui/material';
import { AdminContext } from '../../Admin';

export default ({ quiz, setQuiz }) => {

	const [ question, setQuestion ] = useState({ answer: [], options: [] });
	const { openBar, setOpenBar, barContents, setBarContents } = useContext(AdminContext);

	return (
		<Box>
			<Typography>Multiple Choice (Single) question</Typography>
			Question text
			<InputBase onChange={(e)=>setQuestion((prev) => (
				{ ...prev, content: e.target.value }
			))}/>
			<Box>Answer</Box>
			{ Array.from(Array(4).keys()).map((index) => (
				<Box sx={{ display: "flex" }}>
					<Box sx={{ whiteSpace: "no-wrap" }}>Choice { index+1 }</Box>
					<InputBase
						onChange={(e)=>{
							setQuestion((prev) => {
								const newOptionsArr = [...prev.options];
								newOptionsArr[index] = e.target.value;
								return {
									...prev, options: newOptionsArr
								}
							});
						}}
					/>
					Is an answer?
					<Checkbox
						value={question.answer.includes(question.options[index])}
						onChange={(e)=>setQuestion((prev) => { 
							const newAnswer = [...prev.answer];
							if(e.target.checked) {
								newAnswer.push(prev.options[index]);
							}
							else {
								newAnswer.filter((answer)=>answer!==prev.options[index]);
							}
							return {
								...prev, answer: newAnswer
							}
						})}
					/>
				</Box>
			))}
			<Button 
				variant="outlined"
				onClick={()=>{
					if(!question.content || !question.options || !question.answer) {
						setBarContents({ msg: "Something is missing, please try again", severity: "error" });
						setOpenBar(true);
						return;
					};
					setQuiz((prev)=>({
						...prev, questions: [...prev.questions, {
							questionType: "MULTI_LIST",
							content: question.content,
							options: question.options,
							answer: question.answer
						}]
					}))
				}}>
				Add to quiz
			</Button>
		</Box>
	)	
}

