import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosClient } from './axiosClient';

export const getCareerSpecProgress = (user, careerSpec) => {
	//Total number of slides in all the modules of the career specialisation
	let userProgress = 0;
	const userModules = user.modules.filter((module)=>careerSpec.moduleIds.includes(module.moduleId.toString()));
	userModules.forEach((module) => {
		userProgress += module.slideNum;
	});
	const progressPercent = userProgress/careerSpec.totalSlides;
	return progressPercent ? progressPercent : 0;
}

export const useGetCareerSpec = (careerSpecId) => useQuery({ 
		queryKey: ['careerSpec'],
		queryFn: async () => await axiosClient.get("careerspecs/" + careerSpecId)
	});

export const useGetCareerSpecsAll = (careerPathId) => useQuery({
	queryKey: ['careerSpecs'],
	queryFn: async () => await axiosClient.get(`careerspecs?userSpecific=true&includeTotalSlides=true`)
});

export const useGetCareerSpecsByPath = (careerPathId) => useQuery({
	queryKey: ['careerSpecs', careerPathId],
	queryFn: async () => {
		return careerPathId ? await axiosClient.get(`careerspecs?careerPathId=${careerPathId}&includeTotalSlides=true`) : { data: [] }
	}});

export const usePostCareerSpec = ({ setOpenBar, setBarContents }) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ careerPathId, name, media, descBrief, descLong, color, duration }) => await axiosClient.post('careerspecs', {
			careerPathId, 
			modules: [],
			name,
			media,
			descBrief,
			descLong,
			color,
			duration
		}),
		onSuccess: () => {
			setOpenBar(true);
			setBarContents({ serverity: "success", msg: "Successfully pushed to database"});
			queryClient.invalidateQueries(["careerSpecs"]);
			queryClient.invalidateQueries(["careerPaths"]);
		},
		onError: () => {
			setOpenBar(true);
			setBarContents({ serverity: "error", msg: "Error encountered"});
		}
	})
};
