import { motion } from 'framer-motion';
import { Box, ClickAwayListener } from '@mui/material';

export default ({ allowDrag, setOpen, children }) => {

	return (
		<motion.div
			style={{ 
				position: "fixed", 
				backgroundColor: "#000000AA",
				zIndex: 1000,
				width: "100vw", 
				height: "100vh", 
				display: "flex", 
				justifyContent: "center", 
				alignItems: "center",
				top: 0,
				left: 0
			}}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
			onClick={()=>setOpen(false)}
		>
			<motion.div
				initial={{ scale: 0.95, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				exit={{ scale: 0.95, opacity: 0 }}
				transition={{ type: "spring", bounce: 0.5, duration: 0.75 }}
				drag={allowDrag}
				dragConstraints={{
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				}}
				dragTransition={{ bounceStiffness: 100, bounceDamping: 30 }}
				style={{
					boxShadow: "rgba(149, 157, 165, 0.1) 0px 12px 24px", 
					borderRadius: "32px"
				}}
				onClick={(e)=>e.stopPropagation()}
			>
				{children}
			</motion.div>
		</motion.div>
	)
}
