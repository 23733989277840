import { useState, useMemo, useContext, createContext } from 'react';

const MainContext = createContext();

export default ({children}) => {
 
	const [ prevPathname, setPrevPathname ] = useState("");
	const [ selectedCareerPathId, setSelectedCareerPathId ] = useState("639c125d442b990d86505a0e");
	const [ selectedCareerSpecId, setSelectedCareerSpecId ] = useState();
	const [ selectedNodeId, setSelectedNodeId ] = useState();
	const [ selectedModuleId, setSelectedModuleId ] = useState();
	const [ openPathPopup, setOpenPathPopup ] = useState(false);
	const [ selectedQuestion, setSelectedQuestion ] = useState(null);
	const [ authType, setAuthType ] = useState("REGISTER");

	const calculateDate = (date) => {
		const compareDate = new Date(date);
		const currentDate = new Date();

		const difference = Math.round((currentDate - compareDate)/86400000);
		if(difference < 1) {
			return "Today"
		}
		else if(difference > 30) {
			return `${Math.round(difference/30)} Months`
		}
		else if(difference > 365) {
			return `${Math.round(difference/365)} Years`
		}
		else {
			return `${difference} Days`
		}

	}
	//const baseURL = "http://localhost:5000/assets/";
	const baseURL = "https://advanceau-api.onrender.com/assets/";

	const value = {
		prevPathname, setPrevPathname, selectedCareerPathId, setSelectedCareerPathId, selectedCareerSpecId, setSelectedCareerSpecId, selectedNodeId, setSelectedNodeId, selectedModuleId, setSelectedModuleId, openPathPopup, setOpenPathPopup, selectedQuestion, setSelectedQuestion, authType, setAuthType, calculateDate, baseURL
	}
	const valueMemo = useMemo(()=>value, [{...value}])
	
	return (
		<MainContext.Provider value={valueMemo}>
				{children}
		</MainContext.Provider>
	)
}

export const useMainContext = () => useContext(MainContext);
