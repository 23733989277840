import { useQuery } from '@tanstack/react-query';
import { axiosClient } from './axiosClient';

export const useGetCareerPath = (careerPathId) => useQuery({ 
	queryKey: ['careerPath', careerPathId],
	queryFn: async () => careerPathId ? await axiosClient.get("careerpaths/" + careerPathId) : { data: "" }
});

export const useGetCareerPaths = () => useQuery({
	queryKey: ['careerPaths'],
	queryFn: async () => await axiosClient.get("careerpaths")
});
