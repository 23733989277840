import React, { createContext, useReducer } from 'react';
import { useGetUser, usePatchUser } from '../../../api/userApi.js';

export const FormContext = createContext();

export default ({children}) => {

	const userQuery = useGetUser();

	const initialState = {
		firstName: { value: userQuery.data.data.name.split(" ")[0], valid: true },
		lastName: { value: userQuery.data.data.name.split(" ")[1], valid: true },
		email: { value: userQuery.data.data.email, valid: true },
		school: { value: userQuery.data.data.school, valid: true },
		role: { value: userQuery.data.data.role.toUpperCase(), valid: true },
		year: { value: userQuery.data.data.year, valid: true },
		password: { value: "*********", valid: true }
	}

	const regex = {
		firstName: /^[a-zA-Z]+$/,
		lastName: /^[a-zA-Z]+$/,
		school: /^[a-zA-Z\s]+$/,
		role: /.+/
	}

	const formReducer = (state, action) => {
		switch(action.type) {
			case "INPUT":
				return {...state, [action.field]: { ...state[action.field], value: action.payload }}
			case "VALIDATE":
				return { 
					...state, 
					[action.field]: { 
						...state[action.field], 
						valid: regex[action.field].test(action.payload) 
					}
				}
			case "RESET": {
				return initialState
			}
		}
	}

	const [ state, dispatch ] = useReducer(formReducer, initialState);
	
	return (
		<FormContext.Provider value={{ state, dispatch }}>
			{children}
		</FormContext.Provider>
	)

}
