import { useReducer, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import InputField from '../../../global_components/auth/components/InputField';
import GradientButton from '../../components/GradientButton';
import { usePostContactEmail } from '../../../api/frontpageApi';

export default ({setPopupMsg}) => {

	const initialFormState = {
		name: { value: "", valid: true },
		email: { value: "", valid: true },
		number: { value: "", valid: true },
		message: { value: "", valid: true },
	}

	const regex = {
		email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
		password: /^.{6,}$/, 
	}

	const formReducer = (state, action) => {
		switch(action.type) {
			case "INPUT":
				return {...state, [action.field]: { ...state[action.field], value: action.payload }}
			case "VALIDATE":
				return {...state, [action.field]: { ...state[action.field], valid: true }}
		}
	}

	const [ form, dispatchForm ] = useReducer(formReducer, initialFormState);
	const postContactEmailMutation = usePostContactEmail();
	const [ sending, setSending ] = useState(false);
	const theme = useTheme();

	return (
		<motion.div 
			style={{
				display: "flex",
				position: "relative",
				flexDirection: "column",
				backgroundColor: "white",
				padding: "36px",
				borderRadius: "32px",
				boxShadow: "rgba(149, 157, 165, 0.15) 0px 5px 20px",
				gap: "16px"
			}}
			initial={{ scale: 0.975, opacity: 0 }}
			animate={{ scale: 1, opacity: 1 }}
			transition={{ delay: 0.1, type: "spring", stiffness: 110, damping: 12 }}
		>
			{ sending && (
				<motion.div 
					style={{
						backgroundColor: "white",
						borderRadius: "inherit",
						width: "100%",
						height: "100%",
						position: "absolute",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 10,
						top: 0,
						left: 0
					}}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
				>
					{ !(postContactEmailMutation.isSuccess || postContactEmailMutation.isError) && (
						<CircularProgress 
							size="60px"
						/>								
					)}
					{ postContactEmailMutation.isSuccess && (
					<Box sx={{ fontWeight: 500, fontSize: "16px", textAlign: "center" }}>
						<Box sx={{ 
							color: theme.palette.primary.main,
							fontWeight: 600,
							marginBottom: "8px"
						}}> 
							Successfully sent!
						</Box>
						We'll get back to you as soon as possible
					</Box>
					)}
					{ postContactEmailMutation.isError && (
					<Box sx={{ fontWeight: 500, fontSize: "16px" }}>
						Error occured
					</Box>
					)}
				</motion.div>
			)}
			<Box sx={{
				display: "flex",
				gap: "16px"
			}}>
				<InputField 
					label="Name" 
					placeholder="Enter your name"
					field="name"
					value={form.name.value}
					error={!form.name.valid}
					dispatch={dispatchForm}
				/>
				<InputField 
					label="Phone Number (Optional)" 
					placeholder="Enter your phone number"
					field="number"
					value={form.number.value}
					error={!form.number.valid}
					dispatch={dispatchForm}
				/>
			</Box>
			<InputField 
				label="Email" 
				placeholder="Enter your email"
				field="email"
				value={form.email.value}
				error={!form.email.valid}
				dispatch={dispatchForm}
			/>
			<InputField 
				label="Message" 
				placeholder="Type your message here"
				field="message"
				multiline
				value={form.message.value}
				error={!form.message.valid}
				dispatch={dispatchForm}
				sx={{ height: "150px", alignItems: "flex-start", padding: "8px" }}
			/>
			<GradientButton 
				sx={{ fontSize: "16px" }}
				clickHandler={() => {
					setSending(true);
					let condensedForm = {};
					Object.keys(form).forEach((key) => {
						condensedForm[key] = form[key].value;
					});
					postContactEmailMutation.mutate(condensedForm);
				}}
			>
				Send
			</GradientButton>
	</motion.div>
	)
}
