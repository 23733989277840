import { Box, useMediaQuery, InputBase, Button, IconButton, Typography } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import UserWidget from './widgets/UserWidget';
import { useGetUser } from '../../api/userApi';
import { useGetQuestions, useGetUserQuestions, useCreateQuestion } from '../../api/questionApi';
import { useEffect, useReducer, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import QuestionWidget from './widgets/QuestionWidget';

const ForumsPage = () => {

    // Make a useReducer
    const initialState = {
        user: {value: null, valid: true},
        token: {value: null, valid: true},
        questions: {value: [], valid: true},
    }
    
    const stateReducer = (state, action) => {
        return {...state, [action.field]: { ...state[action.field], value: action.payload }}
    }
    
    const [ state, dispatchState ] = useReducer(stateReducer, initialState);

    // Queries
    const getUserQuery = useGetUser();


    // Fetching Queries
    const getQuestionsQuery = useGetQuestions();

    useEffect(() => {
        if (getQuestionsQuery.isSuccess) {
            dispatchState({field: "questions", payload: getQuestionsQuery.data.data})
        }
    }, [getQuestionsQuery.isSuccess])

    console.log(state.questions)

    const createQuestionQuery = useCreateQuestion();

    // Intialising States
    const [desc, setDesc] = useState("");
    const [image, setImage] = useState(null);
    const [category, setCategory] = useState("");

    // Handling Post Function
    const handleQuestion = async () => {
        // await createQuestionQuery.mutate({
        //     userId: getUserQuery.data.data._id,
        //     description: desc,
        //     picture: image ? image : null,
        //     picturePath: image ? image.name : null
        // }).then(response => {
        //     const testingData = response.data;
        //     console.log(testingData);
        // });


        const formData = new FormData();
        formData.append("userId", getUserQuery.data.data._id);
        formData.append("description", desc);
        formData.append("category", "Engineering");
    
        if (image) {
            formData.append("picture", image);
            formData.append("picturePath", image.name);
        }

        const response = await fetch(`http://localhost:5000/api/questions`, {
            method: "POST",
            body: formData
        })

        const testing = await response.json();
        dispatchState({field: 'questions', payload: testing});

        // Resetting useStates
        setDesc("");
        setImage(null);
        setCategory("");
    }

    return (
        <Box
        sx = {{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
        }}
        >
            <Box
                sx={{
                    width: '97%',
                    height: '90%',
                    marginLeft: '20px',
                    borderRadius: '20px',
                    backgroundColor: '#F3F7FF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {/* Left Box */}
                <Box
                    sx={{
                        height: '100%',
                        border: '1px solid blue',
                        flex: '4'
                    }}
                >
                    <InputBase
                        placeholder="Description of question"
                        onChange={(e) => setDesc(e.target.value)}
                        value={desc}
                    />
                    <Dropzone
                        acceptedFiles=".jpg,.jpeg,.png,.svg"
                        multiple={false}   
                        onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Box
                                {...getRootProps()}
                                border={`2px dashed red`}
                                p="1rem"
                                width="100%"
                                sx={{ "&:hover": { cursor: "pointer" } }}
                                >
                                <input {...getInputProps()} />
                                {!image ? (
                                    <p>Add Image Here</p>
                                ) : (
                                    <Box>
                                    <Typography>{image.name}</Typography>
                                    </Box>
                                )}
                                </Box>
                                {image && (
                                    <IconButton
                                        onClick={() => setImage(null)}
                                        sx={{ width: "15%" }}
                                    >
                                        <DeleteOutlined />
                                    </IconButton>
                                )}
                            </Box>
                            )}
                    </Dropzone>
                    <Button
                        onClick={handleQuestion}
                        sx={{
                            height: '50px',
                        }}
                    >
                        Submit
                    </Button>
                    <QuestionWidget
                        questions={state.questions}
                    >
                    </QuestionWidget>

                </Box>
                {/* Right Box */}
                <Box
                    sx={{
                        height: '100%',
                        flex: '1',
                        border: '1px solid red'
                    }}
                >
                    {/* Profile Section */}
                    {
                        getUserQuery.isSuccess ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'left',
                                    paddingLeft: '20px',
                                    height: '100px',
                                    width: '200px',
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '20px'
                                }}
                            >
                                <Box>
                                    {getUserQuery.data.data.name}
                                </Box>
                                <Box>
                                    {getUserQuery.data.data.role}
                                </Box>
                            </Box>
                        ) : (
                            <Box>
                                loading...
                            </Box>
                        )
                    }
                    {/* My questions Section */}

                </Box>
            </Box>
        </Box>
    )
}

export default ForumsPage;  