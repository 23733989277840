import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import QuestionSummarySidebar from '../qa/components/QuestionSummarySidebar';
import AnimPopIn from '../../global_components/Animated/AnimPopIn';
import FeaturedActivity from './components/FeaturedActivity';
import MyActivity from './components/MyActivity';
import { ReactComponent as HeartIcon } from '../../assets/misc_icons/heart-solid.svg';
import { ReactComponent as PenIcon } from '../../assets/misc_icons/pen-solid.svg';

export default () => {

	const [ likedOpen, setLikedOpen ] = useState(false);
	const [ myPostsOpen, setMyPostsOpen ] = useState(false);
	const theme = useTheme();
	
	return (
		<Box sx={{ display: "flex", position: "relative", flexDirection: "column", gap: "20px", height: "100%" }}>
			<h2 style = {{fontWeight: 500, fontSize: 18, color: '#A4A4A4'}}>My Portal</h2>
			<Box sx={{
				backgroundColor: "#F3F7FF", 
				borderRadius: "30px", 
				width: "100%",
				height: "100%",
				maxHeight: "calc(100% - 50px)",
				padding: "20px",
				display: "flex",
				gap: "20px",
				position: "relative"
			}}>
				<FeaturedActivity />
				<MyActivity />
			</Box>
		</Box>
	)
}
