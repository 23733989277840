import { motion } from 'framer-motion';

export default ({children, key}) => {
	return (
		<motion.div
			key={key}
			initial={{ y: 10, opacity: 0, size: 0.975 }}
			animate={{ y: 0, opacity: 1, size: 1 }}
			exit={{ y: -10, opacity: 0, size: 0.975 }}
			transition={{ 
				duration: 0.3, 
				type: 'spring', 
				bounce: 0.5,
				size: { type: 'tween', duration: 0.2 }				
			}}
			style={{ 
				width: "100%",
				height: "100%"
			}}
		>
			{children}
		</motion.div>
	)
}
