import React, { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Box, CircularProgress } from '@mui/material';
import Navbar from './components/Navbar';
import AskSection from './components/AskSection';
import QuestionsSection from './components/QuestionsSection';
import { useGetQuestions } from '../../api/questionApi';

export default ({ selectedQuestion, setSelectedQuestion }) => {

	const [ categoryId, setCategoryId ] = useState(null);
	const [ showAsk, setShowAsk ] = useState(false);
	const [ showAskReal, setShowAskReal ] = useState(false);
	const [ snackbar, setSnackbar ] = useState({ show: false });
	const [ show, setShow ] = useState(5);
	const [ page, setPage ] = useState(1);
	const [ sort, setSort ] = useState("-updatedAt");
	const getQuestionsQuery = useGetQuestions({ category: categoryId, results: show, page, sort });
	const queryClient = useQueryClient();

	const toggleShowAsk = () => {
		if(showAsk===false) { 
			setShowAsk(true) 
		}
		else { 
			setTimeout(()=>{ 
				setShowAsk(false) 
			}, 400);
			setShowAskReal(false);
		}
	}
	useEffect(() => {
		if(showAsk) {
			setTimeout(() => { setShowAskReal(true) }, 350); 
		}
		else {
			setShowAskReal(false);
		}
	}, [showAsk]);

	return getQuestionsQuery.isSuccess ? (
		<>
			<Navbar
				key={1} 
				getQuestionsQuery={getQuestionsQuery}
				categoryId={categoryId}
				setCategoryId={setCategoryId}
			/>
			<AskSection 
				showAsk={showAsk}
				showAskReal={showAskReal}
				categoryId={categoryId}
				toggleShowAsk={toggleShowAsk}
				snackbar={snackbar}
				setSnackbar={setSnackbar}
			/>
			<QuestionsSection 
				key={2}
				toggleShowAsk={toggleShowAsk}
				show={show}
				setShow={setShow}
				page={page}
				setPage={setPage}
				sort={sort}
				setSort={setSort}
				getQuestionsQuery={getQuestionsQuery}
				selectedQuestion={selectedQuestion}
				setSelectedQuestion={setSelectedQuestion}
				categoryId={categoryId}
				setCategoryId={setCategoryId}
				snackbar={snackbar}
				setSnackbar={setSnackbar}
			/>
			{ 
			}
		</>
	) : (
		<Box sx={{
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		}}>
			<CircularProgress
				variant="indeterminate"
				size="60px"
			/>
		</Box>
	)
}
