import { Box } from '@mui/material';
import { MotionConfig } from 'framer-motion';
import FoundersAddress from './components/FoundersAddress';
//import MeetTheTeam from './components/MeetTheTeam';
import Faq from './components/Faq';
import Footer from '../components/Footer';

export default () => {
	return (
		<MotionConfig viewport={{ once: true }}>
			<Box sx={{
				overflowX: "hidden",
			}}>
				<Box sx={{ height: "48px" }}/>
				<FoundersAddress />
				{/* <Box sx={{ height: "64px" }}/> */}
				{/* <MeetTheTeam/> */}
				<Box sx={{ height: "64px" }}/>
				<Faq />
				<Box sx={{ height: "400px" }}/>
				<Footer />
			</Box>
		</MotionConfig>
	)
}
