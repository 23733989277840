import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Box, InputBase, CircularProgress, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as SearchIcon } from '../../assets/misc_icons/magnifying-glass-solid.svg';
import { ReactComponent as XIcon } from '../../assets/misc_icons/x-solid.svg';
import { ReactComponent as LeftChevIcon } from '../../assets/misc_icons/chevron-left-solid.svg';
import CareerSpecCard from './components/CareerSpecCard';
import ModuleCard from './components/ModuleCard';
import { useGetUser } from '../../api/userApi';
import { useGetCareerSpecsByPath, getCareerSpecProgress } from '../../api/careerSpecialisationApi';
import { getModuleProgress, useGetModulesByCareerSpec } from '../../api/modulesApi';
import { useLearningContext, PageStates } from './providers/LearningProvider';
import { useMainContext } from '../../providers/MainProvider';

export default () => {

	const { handleCareerSpecClick, handleModuleClick, handleBackClick, pageState } = useLearningContext();
	const { selectedCareerPathId, selectedCareerSpecId, selectedModuleId } = useMainContext();
	const theme = useTheme();
	const getUserQuery = useGetUser();
	const getCareerSpecsQuery = useGetCareerSpecsByPath(selectedCareerPathId);
	const getModulesQuery = useGetModulesByCareerSpec(selectedCareerSpecId);
	const [ searchInput, setSearchInput ] = useState("");

  return (
		<Box sx={{
			width: "100%",
			height: "100%",
			display: "flex",
			flexDirection: "column",
			overflow: "hidden"
		}}>
			<Box sx={{ 
				width: "100%", 
				height: "50px",
				backgroundColor: "white",
				boxShadow: "rgba(149, 157, 165, 0.125) 0px 1px 8px",
				borderRadius: "12px",
				display: "flex", 
				alignItems: "center",
				padding: "0 18px",
				color: "#3B3B3B"
			}}>
				<Box sx={{ width: "18px", display: "flex", alignItems: "center" }}>
					<SearchIcon fill={theme.palette.secondary.light}/>
				</Box>
				<InputBase 
					value={searchInput}
					onChange={(e)=>setSearchInput(e.target.value)}
					variant="no-style" 
					placeholder="Search here"
					sx={{ fontSize: "14px" }}
				/>
				<Box
					sx={{
						backgroundColor: theme.palette.secondary.light,
						width: "20px",
						height: "20px",
						padding: "5px",
						display: "flex",
						alignItems: "center",
						borderRadius: "50%",
						cursor: "pointer"
					}}
					onClick={()=>setSearchInput("")}	
				>
					<XIcon fill="white"/>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px", padding: "0 14px" }}>
				<Box sx={{ display: "flex", gap: "10px", fontWeight: 500, fontStyle: "italic" }}>
					<Box sx={{ fontStyle: "normal" }}>Currently showing: </Box>	
					<Box sx={{ color: theme.palette.secondary.main }}>
						Career Paths
					</Box>
					<Box sx={{ transform: "rotate(180deg)", width: "10px", display: "flex", alignItems: "center" }}>
						<LeftChevIcon fill={theme.palette.secondary.main}/>
					</Box>
					<Box sx={{ color: pageState===PageStates.HOME_SPECS ? theme.palette.primary.main : theme.palette.secondary.main}}>
						Career Specialisations
					</Box>
					{ pageState >= PageStates.HOME_MODULES && (
						<>
							<Box sx={{ transform: "rotate(180deg)", width: "10px", display: "flex", alignItems: "center" }}>
								<LeftChevIcon fill={theme.palette.secondary.main}/>
							</Box>
							<Box sx={{ color: pageState===PageStates.HOME_MODULES ? theme.palette.primary.main : theme.palette.secondary.main }}>
								Modules
							</Box>
						</>
					)}
				</Box>
				<Box sx={{ width: "100px", height: "40px" }}>
					<Button variant="contained" onClick={()=>handleBackClick()}>Back</Button>
				</Box>
			</Box>
			<Box sx={{ maxHeight: "100%", overflow: "scroll", paddingTop: "36px" }}>
			{ (getUserQuery.isSuccess && getCareerSpecsQuery.isSuccess && getModulesQuery.isSuccess) ? ( 
					pageState === PageStates.HOME_SPECS ? (
						<Box sx={{
							display: 'grid',
							gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 32%))',
							gridGap: '16px'
						}}>
							{ getCareerSpecsQuery.data.data.map((item, index) => (
								<CareerSpecCard 
									name={item.name}
									icon={item.media.icon}
									lessons={item.moduleIds.length}
									duration={item.duration}
									desc={item.descLong}
									progress={getCareerSpecProgress(getUserQuery.data.data, item)}
									handleClick={()=>handleCareerSpecClick(item._id)}
									index={index}
								/>
							))}
						</Box>
					) : (
						<Box sx={{
							display: 'grid',
							gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 48%))',
							gridGap: '16px'
						}}>
							{ getModulesQuery.data.data.map((item, index) => (
								<ModuleCard
									name={item.name}
									desc={item.descLong}
									slides={item.content.length + item.quizzes.length + 1}
									index={index}
									handleClick={()=>handleModuleClick(item._id)}
									progress={getModuleProgress(getUserQuery.data.data, item._id)}
								/>
							))}
						</Box>
					)
				) : (
					<CircularProgress
						variant="indeterminate"
						size="60px"
					/>
				)}
			</Box>
		</Box>
  );
};

