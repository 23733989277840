import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, InputBase, Collapse, Tabs, Tab } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { ReactComponent as SearchIcon } from '../../../assets/misc_icons/magnifying-glass-solid.svg';
import { ReactComponent as XIcon } from '../../../assets/misc_icons/x-solid.svg';
import AnimPopIn from '../../../global_components/Animated/AnimPopIn';
import AskSection from './AskSection';

export default ({ categoryId, setCategoryId, getQuestionsQuery }) => {

	const [ searchInput, setSearchInput ] = useState("");
	const theme = useTheme();

	return (
		<AnimPopIn>
			<Box
				sx={{
					position: "relative",
					width: "100%",
					height: "64px",
					display: "flex",
					padding: "0 32px 0 8px",
					borderRadius: "16px",
					justifyContent: "space-between",
					gap: "6px",
					alignItems: "center",
					backgroundColor: "white",
					boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px",
				}}
			>
				<Box sx={{
					width: "50%",
					display: "flex",
					position: "relative"
				}}>
					<Tabs
						sx={{ 
							maxWidth: "100%", 
							".MuiTabs-scrollButtons": {
								width: "24px",
								height: "32px",
								margin: "auto 0",
								borderRadius: "6px"
							},
							".MuiTab-root": {
								padding: "0 6px"
							}
						}}
						variant="scrollable"
						scrollButtons="auto"
					>
						<Tab disableRipple label={
							<Button
								variant="light-blue-fill"
								onClick={()=>setCategoryId(null)}
								sx={{ 
									fontWeight: 550,
									width: "64px",
									color: categoryId===null && theme.palette.primary.main,
									fill: categoryId===null && theme.palette.primary.main
								}}
							>
								All
							</Button>
						}/>
						{ getQuestionsQuery.data.data.categories.map((category) => (
							<Tab disableRipple label={
							<Button 
								key={category.name} 
								variant="light-blue-fill"
								sx={{ 
									display: "flex", 
									alignItems: "center",
									gap: "6px",
									color: categoryId===category._id && theme.palette.primary.main,
									fill: categoryId===category._id && theme.palette.primary.main
								}}
								onClick={()=>setCategoryId(category._id)}
							>
								<Box 
									sx={{ minWidth: "20px", display: "flex", alignItems: "center" }}
									dangerouslySetInnerHTML={{ __html: category.icon }}
								/>
								{category.name}
							</Button>
							}/>
						))}
					</Tabs>
				</Box>
				<Box sx={{
					width: "50%",
					display: "flex",
					gap: "8px",
				}}>
					<Box sx={{
						width: "100%",
						height: "36px",
						display: "flex",
						borderRadius: "12px",
						alignItems: "center",
						color: "#3B3B3B",
						backgroundColor: "#00000005",
						padding: "0 16px"
					}}>
						<Box sx={{ width: "18px", display: "flex", alignItems: "center" }}>
							<SearchIcon fill={theme.palette.secondary.light}/>
						</Box>
						<InputBase 
							value={searchInput}
							onChange={(e)=>setSearchInput(e.target.value)}
							variant="no-style" 
							placeholder="Search here"
							sx={{ color: "#3B3B3B", fontSize: "14px" }}
						/>
						<Box
							sx={{
								backgroundColor: theme.palette.secondary.light,
								width: "20px",
								height: "20px",
								aspectRatio: "1",
								padding: "6px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								borderRadius: "50%",
								cursor: "pointer"
							}}
							onClick={()=>setSearchInput("")}	
						>
							<XIcon fill="white"/>
						</Box>
					</Box>
					<Button 
						sx={{ width: "100px" }}
						variant="outlined"
					>
						Search
					</Button>
				</Box>
			</Box>
		</AnimPopIn>
	)
}
