import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import previewImg from '../../../assets/img/front_page_preview.png';
import laptopImg from '../../../assets/img/front_page_laptop.png';
import blobImg from '../../../assets/img/front_page_blob.png';
import GradientButton from '../../components/GradientButton';
import RotationWrapper from '../../components/RotationWrapper';
import RotationBox from '../../components/RotationBox';

export default () => {

	const navigate = useNavigate();

	return (
		<RotationWrapper constrain={500} delay={800}>
			<Box sx={{
				width: "100vw",
				minHeight: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "relative",
				overflowX: "hidden",
			}}>
				<Box sx={{
					display: "flex",
					width: "1125px",
					height: "100%",
					transform: "translateX(80px)"
				}}>
					<Box sx={{
						width: "50%",
						display: "flex",
						flexShrink: "none",
						flexDirection: "column",
						justifyContent: "center",
						paddingRight: "60px",
						gap: "24px"
					}}>
						<motion.div 
							style={{ 
								fontWeight: 600, 
								fontSize: "42px", 
								lineHeight: "125%" 
							}}
							initial={{ y: 36, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							transition={{ type: "spring", stiffness: 140, damping: 12 }}
						>
							Advancing the careers of young Australians
						</motion.div>
						<motion.div 
							style={{ fontWeight: 400, fontSize: "20px" }}
							initial={{ y: 36, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							transition={{ delay: 0.1, type: "spring", stiffness: 140, damping: 12 }}
						>
							Empowering Education, Both Onsite and Online. Click to Connect!
						</motion.div>
						<motion.div 
							initial={{ y: 36, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							transition={{ delay: 0.2, type: "spring", stiffness: 130, damping: 12 }}
						>
							<GradientButton 
								boxShadow 
								sx={{ width: "200px" }}
								clickHandler={()=>navigate("/")}
							>
								Learn more
							</GradientButton>
						</motion.div>
					</Box>
					<Box sx={{
						width: "50%",
						display: "flex",
						flexDirection: "column",
						position: "relative",
					}}>
						<motion.div 
							style={{
								position: "absolute",
								top: -45,
								left: -35 
							}}
							initial={{ scale: 0.5, opacity: 0 }}
							animate={{ scale: 1, opacity: 1 }}
							transition={{ 
								opacity: { delay: 0.4, type: "spring", stiffness: 200, damping: 28 },
								default: { delay: 0.4, type: "spring", stiffness: 500, damping: 16 }
							}}
						>
							<img src={blobImg} width="300px"/>
						</motion.div>
						<motion.div 
							style={{
								position: "absolute",
								bottom: -80,
								right: -40 
							}}
							initial={{ y: 20, scale: 0.9, opacity: 0 }}
							animate={{ y: 0, scale: 1, opacity: 1 }}
							transition={{ 
								opacity: { delay: 0.6, type: "spring", stiffness: 200, damping: 28 },
								default: { delay: 0.6, type: "spring", stiffness: 400, damping: 18 } 
							}}
						>
							<img src={blobImg} width="450px"/>
						</motion.div>
						<motion.div 
							style={{ width: "100%", height: "100%", position: "relative" }}
							initial={{ y: 46, scale: 0.9, opacity: 0 }}
							animate={{ y: 0, scale: 1, opacity: 1 }}
							transition={{ 
								opacity: { delay: 0.1, type: "spring", stiffness: 200, damping: 28 },
								default: { delay: 0.1, type: "spring", stiffness: 120, damping: 8 } 
							}}
						>
							<RotationBox>
								<Box sx={{
									position: "relative"
								}}>
									<img 
										src={previewImg}
										width="400px"
										style={{ 
											position: "absolute", 
											top: "50px",
											boxShadow: "rgba(149, 157, 165, 0.25) 0px 8px 12px",
											borderRadius: "12px"
										}}
									/>
									<img 
										src={laptopImg} 
										width="500px"
									/>
								</Box>
							</RotationBox>
						</motion.div>
					</Box>
				</Box>
			</Box>
		</RotationWrapper>
	)
}
