import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { useGetAdminQuestions, usePatchAdminQuestion, useDeleteAdminQuestion } from '../../../api/questionApi';
import { ReactComponent as TickIcon } from '../../../assets/misc_icons/check-solid.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/misc_icons/trash-solid.svg';

export default ({ setOpenBar, setBarContents }) => {

	const getQuestionsQuery = useGetAdminQuestions();	
	const patchQuestionMutation = usePatchAdminQuestion();
	const deleteQuestionMutation = useDeleteAdminQuestion();

	return (
		<Box>
			<Typography sx={{ marginBottom: "14px" }}>Unapproved questions</Typography>	
			<Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
				{ getQuestionsQuery.isSuccess ? (
						getQuestionsQuery.data.data.map((question) => (
						<Box sx={{
							display: "flex",
							borderRadius: "16px",
							backgroundColor: "#FAFAFA",
							padding: "12px 16px",
							justifyContent: "space-between"
						}}>
							<Box sx={{
								display: "flex",
								flexDirection: "column",
								gap: "3px"
							}}>
								<Box sx={{ fontWeight: 500, fontSize: "15px" }}>{question.title}</Box>
								<Box sx={{ fontSize: "13px" }}>{question.description}</Box>
							</Box>
							<Box sx={{
								display: "flex",
								gap: "12px",
								fill: "#3C3C3C"
							}}>
								<Button onClick={()=>{
									patchQuestionMutation.mutate({ id: question._id, data: { approved: true } })
									setBarContents('Successfully approved question, see "Q & A" section for the newly approved question');
									setOpenBar(true)
								}}>
									<TickIcon width="26px"/>
								</Button>
								<Button onClick={()=>{
									deleteQuestionMutation.mutate({ id: question._id })
									setBarContents('Successfully deleted question');
									setOpenBar(true)
								}}>
									<RemoveIcon width="18px"/>
								</Button>
							</Box>
						</Box>
					))
				) : (
					<Box sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}>
						<CircularProgress size="60px"/>
					</Box>
				)}
			</Box>
		</Box>
	)
}
