import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import WorkshopsImg from '../../assets/unsw_shoot/Presenting1.jpg';
import ImageContainer from '../../components/ImageContainer';
import TickBox from '../../components/TickBox';

export default () => (
	<Box sx={{
		display: "flex",
		justifyContent: "center"
	}}>
		<Box sx={{
			display: "flex",
			gap: "80px",
			width: "1000px",
			margin: "auto"
		}}>
			<Box sx={{
				display: "flex",
				justifyContent: "flex-end"
			}}>
				<ImageContainer 
					sx={{ width: "500px"}}
					img={WorkshopsImg}
				/>
			</Box>
			<Box sx={{
				display: "flex",
				flexDirection: "column",
				gap: "20px"
			}}>
				<motion.div 
					style={{
						fontWeight: 600,
						fontSize: "36px",
						lineHeight: "125%"
					}}
					initial={{ y: 30, opacity: 0 }}
					whileInView={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
				>
					What we do
				</motion.div>
				<motion.div 
					style={{
						fontSize: "16px",
					}}
					initial={{ y: 30, opacity: 0 }}
					whileInView={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
				>
				Advance Careers is a young not-for-profit organisation with the mission of providing young Australians with the tools and guidance necessary to forge a path for themselves as they leave high school and enter an everchanging world, both in their careers and in their lives.
				</motion.div>
				<motion.div 
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "6px",
						marginTop: "8px"
					}}
					initial={{ y: 30, opacity: 0 }}
					whileInView={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
				>
					<TickBox>Empowerment</TickBox>
					<TickBox>Engagement</TickBox>
					<TickBox>Impact</TickBox>
				</motion.div>
			</Box>
		</Box>
	</Box>
)

