import { useMemo } from "react";
import Dagre from "@dagrejs/dagre";


// Function to collapse children
function filterCollapsedChildren(dagre, node) {
  const children = dagre.successors(node.id) || [];

  node.data.expandable = !!children.length;

  if (!node.data.expanded) {
    while (children.length) {
      const child = children.pop();

      // dagre.removeEdge(node.id, child)
      children.push(...(dagre.successors(child) || []));
      dagre.removeNode(child);
    }
  }
}

function useExpandCollapse(nodes, edges, { layoutNodes = true, treeWidth = 220, treeHeight = 100 } = {}) {
  return useMemo(() => {
    if (!layoutNodes) return { nodes, edges };

    const dagre = new Dagre.graphlib.Graph()
      .setDefaultEdgeLabel(() => ({}))
      .setGraph({ rankdir: "TB" });

    function reverseOrder(arr) {
      const reversedNodes = arr.slice().reverse();
      return reversedNodes;
    }

    // Set initial Nodes and Edges
    // nodes = reverseOrder(nodes);
    nodes.forEach(node => {
      dagre.setNode(node.id, {
        width: treeWidth,
        height: treeHeight,
        data: node.data,
      });
    });
    edges.forEach(edge => {
      dagre.setEdge(edge.source, edge.target);
    });

    /* Logic: edges are responsible for the ranking (top-down order) and ordering (left-right order).
      * So go level by level and sort the edges (childrens) based of their node childIndex */

    // Reorder edges based on node child index
    // nodes = reverseOrder(nodes); // sort based on when added, so first node is index 0
    nodes.forEach(node => {
      const children = dagre.successors(node.id);
      if (children.length > 0) {
        // Sort children based on childIndex
        children.sort((a,b) => {
          const nodeA = dagre.node(a);
          const nodeB = dagre.node(b);
          return nodeA.data.childIndex - nodeB.data.childIndex;
        });

        // Reassign & Update childIndex (odd numbers)
        children.forEach((child, index) => {
          const childNode = dagre.node(child);
          childNode.data.childIndex = 2 * index + 1;

          const childSuccessors = dagre.successors(child) || [];
          dagre.removeNode(child, childNode);

          dagre.setNode(child, childNode);

          childSuccessors.forEach(successor => {
            dagre.setEdge(child, successor);
          });
        });

        // Set edges based on this order
        children.forEach(child => {
          dagre.removeEdge(node.id, child);
          dagre.setEdge(node.id, child);
        });
      }
    });
    
    nodes.forEach(node => {
      filterCollapsedChildren(dagre, node);
    });

    // console.log("DAGRE EDGES AFTER: ", dagre.edges());
    // console.log("DAGRE NODES AFTER: ", dagre.nodes());

    Dagre.layout(dagre);

    return {
      nodes: nodes.flatMap(node => {
        if (!dagre.hasNode(node.id)) return [];

        const { x, y } = dagre.node(node.id);
        const type = "custom";
        const position = { x, y };
        const data = { ...node.data };

        return [{ ...node, position, type, data }];
      }),
      edges,
    };
  }, [nodes, edges, layoutNodes, treeWidth, treeHeight]);
}

export default useExpandCollapse;
