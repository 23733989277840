import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosHeaders } from 'axios';
import { axiosClient } from './axiosClient';

export const getModuleProgress = (user, moduleId) => {
    const module = user.modules.find((module)=>module.moduleId.toString()===moduleId);
    if(module) {
        return module.slideNum
    }
    else {
        return 0
    }
}

export const updateModuleProgress = ({ user, careerSpecId, moduleId, slideNum }) => {
	const newModules = user.modules.slice();	
	let moduleExists = false;
	for(let i = 0; i < newModules.length; i++) {
		if(newModules[i].moduleId.toString()===moduleId) {
			if(newModules[i].slideNum < slideNum) {
				newModules[i].slideNum = slideNum;
			}
			moduleExists = true;
		}
	}
	if(!moduleExists) {
		newModules.push({ moduleId, slideNum });
	}

	const newCareerSpecs = user.careerSpecialisations.slice();
	let careerSpecExists = false;
	for(let i = 0; i < newCareerSpecs.length; i++) {
		if(newCareerSpecs[i].toString()===careerSpecId) {
			careerSpecExists = true;
		}
	}
	if(!careerSpecExists) {
		newCareerSpecs.push(careerSpecId);
	}

	return { modules: newModules, careerSpecialisations: newCareerSpecs };
}

export const useGetModules = () => useQuery({
    queryKey: ['modules'],
    queryFn: async () => await axiosClient.get('modules')
});

export const useGetModule = ({ moduleId }) => useQuery({
	queryKey: ['module', moduleId],
	queryFn: async () => {
		if(!moduleId) {
			return {}
		}
		const module = (await axiosClient.get(`modules/${moduleId}`)).data;
		module.slides = module.content.map((content)=>({...content, type: "SLIDE"}));
		module.quizzes.forEach((quiz) => {
			module.slides.splice(quiz.afterSlideNum+1, 0, {...quiz, type: "QUIZ" });
		});
		module.slides.push({ title: "Module Finished!", type: "FINISHED" });
		return module;
	}
})

export const useGetModulesByCareerSpec = (careerSpecId) => useQuery({
    queryKey: ['modules', careerSpecId],
    queryFn: async () => careerSpecId ? await axiosClient.get(`modules/?careerSpecId=${careerSpecId}`) : { data: [] }
})

export const useGetModulesByCareerSpecs = (careerSpecIds) => useQuery({
    queryKey: ['modules_multi', careerSpecIds],
    queryFn: async () => careerSpecIds ? await axiosClient.get(`modules/?careerSpecIds=${careerSpecIds.join()}`) : { data: [] }
})

export const usePostModule = ({ setOpenBar, setBarContents }) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async ({ name, icon, descLong, descBrief, content, quizzes, careerSpecId }) => await axiosClient.post('modules', {
			name,
			icon,
			descLong,
			descBrief,
			content,
			quizzes,
			careerSpecId
		}),
		onSuccess: () => {
			setOpenBar(true);
			setBarContents({ severity: "success", msg: "Successfully pushed to database" });
				queryClient.invalidateQueries(["careerSpecs"]);
				queryClient.invalidateQueries(["careerPaths"]);
		},
		onError: (data) => {
			console.log(data);
			setOpenBar(true);
			setBarContents({ severity: "error", msg: "Error encountered"});
		}
	});
}

export const usePostModuleSimple = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => await axiosClient.post('modules', data)
  })
}

export const usePatchModule = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => await axiosClient.patch('modules', data)
  })
}
