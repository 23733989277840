import { useState, useContext } from 'react';
import { Button, Box, InputBase, Typography, Select, MenuItem } from '@mui/material';
import { AdminContext } from '../../Admin';

export default ({ quiz, setQuiz }) => {

	const [ question, setQuestion ] = useState({});
	const { openBar, setOpenBar, barContents, setBarContents } = useContext(AdminContext);

	return (
		<Box>
			<Typography>True or false question</Typography>
			Question text
			<InputBase onChange={(e)=>setQuestion((prev) => (
				{ ...prev, content: e.target.value }
			))}/>
			<Box>Answer</Box>
			<Select onChange={(e)=>setQuestion((prev) => (
				{ ...prev, answer: e.target.value }
			))}>
				<MenuItem value={true}>True</MenuItem>
				<MenuItem value={false}>False</MenuItem>
			</Select>
			<Button 
				variant="outlined"
				onClick={()=>{ 
					if(!question.content || !question.answer) { 
						setBarContents({ msg: "Something is missing, please try again", severity: "error" });
						setOpenBar(true);
						return;
					};
					setQuiz((prev)=>({
						...prev, questions: [...prev.questions, {
							questionType: "TRUE_FALSE",
							content: question.content,
							options: [],
							answer: question.answer
						}]
					}));
				}}
			>
				Add to quiz
			</Button>
		</Box>
	)	
}
