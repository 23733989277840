import React from 'react';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import Popup from '../../../global_components/Animated/Popup';
import { useGetModule } from '../../../api/modulesApi';
import { AnimatePresence } from 'framer-motion';
import { useFlowTreeContext } from '../FlowTree';


const innerHTMLTest = `
<div style="position: relative; width: 100%; height: 0; padding-top: 56.2500%; padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden; border-radius: 8px; will-change: transform;">  <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"    src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGHiXEGJ1Q&#x2F;M5wG9MFDF7OMwYn72sOBVA&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">  </iframe></div><a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGHiXEGJ1Q&#x2F;M5wG9MFDF7OMwYn72sOBVA&#x2F;view?utm_content=DAGHiXEGJ1Q&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener">[Syd Tech] Advance Careers Workshop</a> by Matt Ng
`
export default ({ open, setOpen  }) => {

  const { activeNode } = useFlowTreeContext(); 
  const getModuleQuery = useGetModule({ moduleId: activeNode?.data?.moduleId });

  // useEffect(() => {
  //   if (getModuleQuery.isSuccess) {
  //     console.log("data: ", getModuleQuery.data);
  //   }
  // }, [getModuleQuery.isSucccess, getModuleQuery.data])

  return (
    <AnimatePresence exitBeforeEnter> {
      open && getModuleQuery.isSuccess && getModuleQuery.data?.canva && (
        <Popup setOpen={setOpen}>
          <Box 
            sx={{
              width: "clamp(40vw, 1000px, 90vw)", 
              height: "clamp(40vh, 600px, 92vh)",
              borderRadius: '20px', 
              display: 'flex',
              backgroundColor: '#FFFFFF',
              padding: "0"
            }}
            dangerouslySetInnerHTML={{ __html: `
              <iframe loading="lazy" style="width: 100%; height: 100%; border-radius: inherit; border: none;"
                src="${getModuleQuery.data.canva}" allowfullscreen="allowfullscreen" allow="fullscreen">
              </iframe>
            ` }}
          />
        </Popup>
      )
    } 
    </AnimatePresence>
  )
}
