import { Box, Button } from '@mui/material';

export default ({ heading }) => {

	return (
		<Button sx={{
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			padding: "10px 12px"
		}}>
			<Box sx={{
				color: "#3B3B3B",
				fontWeight: 500,
				lineHeight: "20px",
				textAlign: "left"
			}}>
				{ `${heading.slice(0, 40)}...` }
			</Box>	
			<Box sx={{
				color: "#A4A4A4",
				fontWeight: 400,
				fontSize: "12px"
			}}>
				4 days ago
			</Box>
		</Button>
	)
}
