import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Box } from '@mui/material';
import { ReactComponent as ClockImg } from '../../assets/misc_img/clock-solid.svg';
import { ReactComponent as StudentImg } from '../../assets/misc_img/graduation-cap-duotone-solid.svg';
import { ReactComponent as StairsImg } from '../../assets/misc_img/stairs-solid.svg';
import { ReactComponent as PinImg } from '../../assets/misc_img/map-pin-solid.svg';

const Feature = ({Icon, text}) => {
  return (
    <Box sx={{ display: "flex", gap: "6px", alignItems: "center", width: "50%" }}>
      <Box sx={{ 
        display: "flex", 
        backgroundColor: "#3b3b3bEE", 
        borderRadius: "5px",
        height: "20px",
        width: "20px",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <Icon height="13px" fill="white"/>
      </Box>
      <Box sx={{
        fontSize: "11px",
        fontWeight: 500,
        color: "#3b3b3bee"
      }}>
        {text}
      </Box>
    </Box>
  )
}

export default ({imgUrl, title, desc, time, students, grade, location}) => {

  return (
    <motion.div
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: "16px",
        backgroundColor: "white",
        boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px",
        width: "100%",
        height: "220px",
        cursor: "pointer"
      }}
      transition={{ duration: 0.6, type: 'spring', bounce: 0.5 }}
      whileHover={{ translateY: -5, scale: 1.005, transition: { duration: 0.2 }}}
    >
      <Box sx={{
        flex: 5,
        padding: "18px",
        height: "100%"
      }}>
        <Box sx={{
          backgroundImage: `url(${imgUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          borderRadius: "16px",
          width: "100%",
          height: "100%",
        }}/>
      </Box>
      <Box sx={{
        height: "calc(100% - 24px)",
        width: "1px",
        backgroundColor: "#E4E1E199"
      }}/>
      <Box sx={{
        flex: 11,
        display: "flex",
        flexDirection: "column",
        padding: "16px",
      }}>
        <Box sx={{
          fontWeight: 600,
          color: "#3b3b3b",
          fontSize: "18px"
        }}>
          {title}
        </Box>
        <Box sx={{
          color: "#3b3b3b",
          fontSize: "11px",
          marginTop: "6px"
        }}>
          {desc}
        </Box>
        <Box sx={{
          display: "flex",
          marginTop: "16px",
          width: "80%"
        }}>
          <Feature
            Icon={ClockImg}
            text={time}
          />
          <Feature
            Icon={StudentImg}
            text={students}
          />
        </Box>
        <Box sx={{
          display: "flex",
          marginTop: "12px",
          width: "80%"
        }}>
          <Feature
            Icon={StairsImg}
            text={grade}
          />
          <Feature
            Icon={PinImg}
            text={location}
          />
        </Box>
      </Box>
    </motion.div>
  )
}
