import { LinearProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#1B56BA',
			light: '#757ce8'
		},
		secondary: {
			main: '#3B3B3B',
			light: '#AAAAAA'
		}
	},
	components: {
		MuiButton: {
			defaultProps: {
			},
			styleOverrides: {
				root: {
					textTransform: "none",
					width: "100%",
					fontFamily: "Poppins",
					boxShadow: "none",
					borderRadius: "12px",
					"&:hover": {
						boxShadow: "none"
					}
				},
			},
			variants: [
				{
          props: { variant: 'light-outline' },
          style: {
						width: '1px !important', 
						height: '40px', 
						borderRadius: '10px', 
						border: '2px solid #e3e2e7', 
						color: '#a7a8bd', fontSize: 40, fontWeight: 350,
						boxShadow: "2px 2px 8px 6px rgba(0,0,0,0.025)",
						maxWidth: '40px', maxHeight: '40px', minWidth: '40px', minHeight: '40px',
						'& svg': {maxWidth: '12px', maxHeight: '12px', minWidth: '12px', minHeight: '12px', fill: '#A4A4A4'},
						'&:hover': {						
								border: '2px solid #A4A4A4', 
						}
          }
        },
				{
          props: { variant: 'light-circular' },
          style: {
						width: '30px', 
						height: '30px', 
						borderRadius: '50%', 
						backgroundColor: 'white',
						color: '#B3B3B3', fontSize: 40, fontWeight: 350,
						boxShadow: "1px 1px 4px 4px rgba(0,0,0,0.05)",
						maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px',
						'& svg': {margin: "auto", maxWidth: '12px', maxHeight: '12px', minWidth: '12px', minHeight: '12px', fill: '#A4A4A4'},
						'&:hover': { backgroundColor: "#f3f2f7" }
          },
        },
				{
          props: { variant: 'filled-bold' },
          style: {
						borderRadius: '30px', 
						backgroundColor: 'white',
						color: '#3B3B3B', fontSize: 20, fontWeight: 600,
						boxShadow: "2px 2px 8px 6px rgba(0,0,0,0.01)",
						padding: "10px 0",
						'& svg': {margin: "auto", maxWidth: '12px', maxHeight: '12px', minWidth: '12px', minHeight: '12px', fill: '#A4A4A4'},
						'&:hover': { backgroundColor: "#f3f2f7" }
          },
				},
				{
          props: { variant: 'heavy-outline' },
          style: {
						borderRadius: '10px', 
						border: '2px solid', 
						boxShadow: "2px 2px 8px 6px rgba(0,0,0,0.025)",
						'&:hover': {						
								border: '2px solid', 
						}
          }
        },
				{
          props: { variant: 'light-blue-fill' },
          style: {
						borderRadius: '10px', 
						backgroundColor: "#F3F7FF",
						color: "#A4A4A4",
						fill: "#A4A4A4",
						transition: "all 300ms ease",
						'&:hover': {						
							backgroundColor: "#F3F7FF",
							color: "#1B56BA",
							fill: "#1B56BA"
						}
          }
        },
				{
          props: { variant: 'light-white-fill' },
          style: {
						borderRadius: '10px', 
						backgroundColor: "white",
						color: "#A4A4A4",
						fill: "#A4A4A4",
						transition: "all 300ms ease",
						'&:hover': {						
							backgroundColor: "white",
							color: "#1B56BA",
							fill: "#1B56BA"
						}
          }
        },
			]
		},
		MuiInputBase: {
			defaultProps: {
				fullWidth: true,
				fontFamily: "Poppins"
			},
			styleOverrides: {
				root: {
					border: "1px #E5E5E5 solid",
					borderRadius: "5px",
					fontSize: "13px",
					padding: "0 10px",
					transition: "all 100ms ease-out",
					textarea: { fontFamily: "Poppins" },
					input: { fontFamily: "Poppins" },
					"&:hover": {
						borderColor: "#1B56BA",
						boxShadow: "#1B56BA44 0px 0px 5px 1px",
					},
					"&.Mui-focused": {
						borderColor: "#1B56BA"
					},
					"&.Mui-error": {
						borderColor: "#ff3333",
						"&:hover": {
							borderColor: "#ff3333",
							boxShadow: "#ff333344 0px 0px 5px 1px",
						},
					}
				},
			},
			variants: [
				{
          props: { variant: 'no-style' },
          style: {
						border: "none",
						boxShadow: "none",
						"&.Mui-focused": {
							borderColor: "none"
						},
						"&:hover": {
							borderColor: "none",
							boxShadow: "none"
						}
          }
        }
			]
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					height: 8,
					'& .MuiSlider-track': {
						border: 'none',
					},
					'& .MuiSlider-thumb': {
						height: 24,
						width: 24,
						backgroundColor: '#fff',
						border: '2px solid currentColor',
						'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
							boxShadow: 'inherit',
						},
						'&:before': {
							display: 'none',
						},
					},
					'& .MuiSlider-valueLabel': {
						lineHeight: 1.2,
						fontSize: 12,
						background: 'unset',
						padding: 0,
						width: 32,
						height: 32,
						borderRadius: '50% 50% 50% 0',
						transformOrigin: 'bottom left',
						transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
						'&:before': { display: 'none' },
						'&.MuiSlider-valueLabelOpen': {
							transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
						},
						'& > *': {
							transform: 'rotate(45deg)',
						},
					},
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: "Poppins",
					fontWeight: 500
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					border: "1px #E5E5E5 solid",
					fontFamily: "Poppins",
					"& .MuiOutlinedInput-notchedOutline": {
						border: "none",
					},
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontFamily: "Poppins",
					fontWeight: 450,
					fontSize: "12px"
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
				}
			}
		},
		MuiAccordion: {
			defaultProps: {
				square: true 
			},
			styleOverrides: {
				root: {
					boxShadow: "rgba(149, 157, 165, 0.1) 0px 1px 6px",
					borderRadius: "12px",
					'&:before': {
						display: 'none',
						},
				}
			}
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
				}
			}
		},
		MuiAccordionSummary: {
			defaultProps: {
				expandIcon: <ExpandMoreIcon />
			}
		},
	}
});


