import { useState, useContext } from 'react';
import { Box, Avatar, CircularProgress, Button } from '@mui/material';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useGetUser } from '../../api/userApi.js';
import { AnimatePresence } from 'framer-motion';
import MyDetails from './components/MyDetails';
import ProfilePicture from './components/ProfilePicture';
import EducationDetails from './components/EducationDetails';
import CancelUpdateButtons from './components/CancelUpdateButtons';
import Popup from '../Animated/Popup';
import FormContextProvider, { FormContext } from './components/FormContextProvider';
import DeleteUploadButtons from './components/DeleteUploadButtons.js';

export default ({ open, setOpen }) => {
	
	const userQuery = useGetUser();
	const [ anchorEl, setAnchorEl ] = useState(null);

	const [ buttonShow, setButtonShow ] = useState(false);
	const [ preview, setPreview ] = useState(null);
	const [ image, setImage ] = useState(null);

	return (
		<AnimatePresence>
			{ open && (
			<Popup setOpen={setOpen}>
				<Box sx = {{
					width: "clamp(40vw, 1200px, 90vw)", 
					height: "clamp(40vh, 500px, 92vh)",
					borderRadius: '20px', 
					backgroundColor: '#FFFFFF',
					marginTop: '50px',
					padding: "10px"
				}}>
					{ userQuery.isSuccess ? (
						<FormContextProvider>
							<Box sx={{ 
								width: "100%", 
								height: "100%",
								display: "flex"
							}}>
								<Box sx={{ 
									width: "340px", 
									display: "flex", 
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									gap: "14px",
									fontWeight: 550,
									fontSize: "26px",
									color: "#3B3B3B"
								}}>
									<ProfilePicture 
										userQuery={userQuery} 
										setButtonShow={setButtonShow} 
										preview={preview}
										setPreview={setPreview}
										image={image}
										setImage={setImage}
									/>
									{ userQuery.data.data.name }
									<Box>
										{buttonShow && 
											<DeleteUploadButtons 
												setButtonShow={setButtonShow} 
												setPreview={setPreview}
												setImage={setImage}
												image={image}
											/>}
									</Box>
								</Box>
								<Box sx={{
									width: "1.5px",
									backgroundColor: "#E8E8E8",
									height: "100%"
								}}/>
								<Box sx={{ 
									width: "100%",
									display: "flex",
									justifyContent: "center",
									padding: "70px"
								}}>
									<Box sx={{
										display: "flex",
										flexDirection: "column",
										gap: "10px"
									}}>
										<Box sx={{
											display: 'flex', 
											gap: "54px",
										}}>
											<MyDetails/>
											<EducationDetails/>
										</Box>
										<CancelUpdateButtons setOpen={setOpen}/>
									</Box>
								</Box>
							</Box>
						</FormContextProvider>
					) : (
						<Box sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							height: "100%"
						}}>
							<CircularProgress
								variant="indeterminate"
								size="60px"
							/>
						</Box>
					)}
				</Box>
			</Popup>
			)}
		</AnimatePresence>
	)
}
