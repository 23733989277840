import { Box } from '@mui/material';
import { MotionConfig } from 'framer-motion';
import Hero from './components/Hero';
import PictureSection from './components/PictureSection';
import MissionStatement from './components/MissionStatement';
import DarkSection from './components/DarkSection';
import Impact from './components/Impact';
import Footer from '../components/Footer';
import GradientProgressBar from '../components/GradientProgressBar';

export default () => {

	return (
		
		<MotionConfig viewport={{ once: true }}>
			<Box sx={{
				color: "#3B3B3B"
			}}>
				<Hero />
        <Impact />
				<Box sx={{ height: "120px" }}/>
				<PictureSection />
				<Box sx={{ height: "180px" }}/>
				<MissionStatement />
				<Box sx={{ height: "180px" }}/>
				<DarkSection />
				{/* <Box sx={{ height: "180px" }}/> */}
				{/* <Box sx={{ */}
				{/* 	height: "360px", */}
				{/* 	display: "flex", */}
				{/* 	flexDirection: "column", */}
				{/* 	justifyContent: "center", */}
				{/* 	alignItems: "center", */}
				{/* 	backgroundColor: "#F3F7FF", */}
				{/* }}> */}
				{/* 	<Box sx={{ */}
				{/* 		fontSize: "24px", */}
				{/* 		fontWeight: 500 */}
				{/* 	}}> */}
				{/* 		Exciting Section coming soon! */}
				{/* 	</Box> */}
				{/* 	<Box sx={{ */}
				{/* 		fontSize: "18px", */}
				{/* 		fontWeight: 400,  */}
				{/* 		marginTop: "16px" */}
				{/* 	}}> */}
				{/* 		In the meantime, explore our feature-rich App for a seamless experience. */}
				{/* 	</Box> */}
				{/* </Box> */}
    {/*     <Box sx={{ */}
    {/*       width: "100%", */}
    {/*       display: "flex", */}
    {/*       justifyContent: "center", */}
    {/*       alignItems: "center", */}
    {/*     }}> */}
    {/*       <GradientProgressBar  */}
    {/*         progress="50%" */}
    {/*         sx={{ height: "10px", width: "200px" }} */}
    {/*       /> */}
    {/*     </Box> */}
				{/*
				*/}
				<Footer />
			</Box>
		</MotionConfig>
	)
}
