import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import ecllipseImg from '../../../assets/bg_img/ecllipse_small.png';
import FloatingDesc from '../../components/FloatingDesc';
import presentationImg from '../../assets/unsw_shoot/Presenting2.jpg';

export default () => {

	return (
		<motion.div
			initial={{ scale: 0.97, opacity: 0 }}
			animate={{ scale: 1, opacity: 1 }}
			transition={{ delay: 0.1, type: "spring", stiffness: 110, damping: 12 }}
		>
			<Box sx={{
				minWidth: "560px",
				height: "315px",
				transform: "translateY(24px)",
				position: "relative"
			}}>
				<Box sx={{
					position: "absolute",
					top: -38,
					left: -38,
					transform: "scale(50%)",
					animation: "float 3s ease-in-out infinite"
				}}>
					<img src={ecllipseImg}/>
				</Box>
				<FloatingDesc sx={{ 
					position: "absolute", 
					right: -32, 
					top: 32,
					zIndex: 10,
					animation: "float 3s ease-in-out infinite"
				}}>
					Join us in<br/>our mission!
				</FloatingDesc>
				<Box sx={{
					position: "absolute",
					zIndex: 5,
					width: "100%",
					height: "100%",
					borderRadius: "20px",
					overflow: "hidden",
					backgroundImage: `url(${presentationImg})`,
					backgroundSize: "cover"
				}}/>
			</Box>
		</motion.div>
	)
}
