import { Typography, Box, CircularProgress } from '@mui/material';
import { useGetCareerPaths } from '../../../api/careerPathApi';

export default () => {

	const careerPathsQuery = useGetCareerPaths();

	return (
		<Box>
			<Typography>Add Career Path</Typography>
			<Box>
			<Typography>View Career Paths</Typography>
			</Box>
		</Box>
	)
}
