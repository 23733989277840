import axios from 'axios';

const axiosClient = axios.create({
	// baseURL: "http://localhost:5000/api"
	baseURL: 'https://advanceau-api.onrender.com/api/'
})

axiosClient.interceptors.request.use((config) => {
	const token = localStorage.getItem("jwt");
	config.headers.authorization = token ? `Bearer ${token}` : "-1";
	return config
}, (error) => {
	return Promise.reject(error);
});

export { axiosClient }
