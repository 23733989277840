import { useQueryClient, useMutation } from '@tanstack/react-query';
import { axiosClient } from './axiosClient';

export const useLogin = ({ navigate, setShowError, setErrorMsg, setLoggingin }) => { 

	let emailInfo;
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({email, password}) => {
			emailInfo = email
			return await axiosClient.post("auth/login", {email, password}) 
		},
		onSuccess: (data) => {
			localStorage.setItem('jwt', data.data.token);
			navigate("/app/careerpath");
			queryClient.invalidateQueries(["currUser"]);
		},
		onError: (error) => {
			setErrorMsg(error.response.data.emailVerificationError ? `Please verify your email first (Account verification sent to '${emailInfo}')` : "Invalid login credentials, please try again");
			setShowError(true);
			setLoggingin(false);
		}
	})
};

export const useRegister = ({ setShowError, setErrorMsg }) => {

	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({name, email, password, year, school, subscribed, role}) => {
			return await axiosClient.post("auth/register", {
				name, email, password, year, school, subscribed, role
			})
		},
		onError: (error) => {
			setErrorMsg(error.response.data.existingEmailError ? "You used an email that already exists, please choose another one" : "An error has occured");	
			setShowError(true);
		}
	});
}

export const useVerify = () => useMutation({
	mutationFn: async ({ userId }) => await axiosClient.post("auth/verify", { userId }),
});

export const useResetPwdEmail = () => useMutation({
	mutationFn: async ({ email }) => await axiosClient.post("auth/resetPwdEmail", { email })
});

export const useResetPwd = () => useMutation({
	mutationFn: async ({ token, password }) => await axiosClient.post("auth/resetPwd", { token, password })
});
