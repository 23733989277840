import React, { useState } from 'react';
import { motion } from 'framer-motion';

export default ({children, delay, show, sx}) => {

	const [ style, setStyle ] = useState(sx);

	return (
		<motion.div
			initial={{ y: -10, opacity: 0 }}
			animate={{ y: 0, opacity: 1 }}
			exit={{ y: -10, opacity: 0 }}
			transition={{ type: "spring", bounce: 0.6, duration: 0.6, delay }}
			/*
			onMouseEnter={()=>setStyle({ ...sx,
        "::-webkit-scrollbar-thumb": {
          background: "#888"
        }
      })}
			onMouseLeave={()=>setStyle(sx)}
			 */
			style={style}
		>
			{children}
		</motion.div>
	)
}
