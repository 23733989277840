import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, CircularProgress, Button } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import ModuleFinished from './components/ModuleFinished';
import { useGetModule } from '../../api/modulesApi';
import { useMainContext } from '../../providers/MainProvider';
import { useLearningContext } from './providers/LearningProvider';
import { ReactComponent as LeftArrow} from '../../assets/misc_icons/left-arrow.svg'
import { ReactComponent as RightArrow} from '../../assets/misc_icons/right-arrow.svg'
import Quiz from './Quiz';

export default () => {
	
	const { 
		selectedCareerPathId, 
		setSelectedCareerPathId, 
		selectedCareerSpecId, 
		setSelectedCareerSpecId, 
		selectedModuleId, 
		setSelectedModuleId, 
	} = useMainContext();

	const { swiperSlide, handlePrevSlide, handleNextSlide, completeModule  } = useLearningContext();
	const getModuleQuery = useGetModule({ moduleId: selectedModuleId });
	const slide = getModuleQuery.isSuccess ? getModuleQuery.data.slides[swiperSlide] : null;
	const [ active, setActive ] = useState(false);

	return (
		<Box 
			sx={{ 
				borderRadius: "20px", 
				backgroundColor: "white", 
				overflow: "hidden",
				width: "100%",
				height: "100%",
				boxShadow: "rgba(149, 157, 165, 0.125) 0px 1px 8px",  
				position: "relative"
			}}
			onMouseEnter={()=>setActive(true)}
			onMouseLeave={()=>setActive(false)}
		>
				{ getModuleQuery.isSuccess ? (
					<>
						{ slide.type === "SLIDE" && 
							<Box 
								sx={{ width: "100%", height: "100%" }}
								dangerouslySetInnerHTML={{__html: slide.htmlContent}}
							/>
						}
						{ slide.type === "QUIZ" &&
							<Quiz quizSlide={slide}/>
						}
						{ slide.type === "FINISHED" &&
							<ModuleFinished handleClick={completeModule}/>
						}
					</>
				) : (
					<Box sx={{ 
						width: "100%", 
						height: "100%", 
						display: "flex", 
						alignItems: "center", 
						justifyContent: "center" 
					}}>
						<CircularProgress
							variant="indeterminate"
							size="60px"
						/>
					</Box>
				)}
			<Box sx={{ 
				position: "absolute", 
				top: 0,
				zIndex: 100,
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "32px",
				pointerEvents: "none"
			}}>
				<Button 
					variant="light-circular"
					sx={{ 
						pointerEvents: "auto",
						opacity: active ? 1 : 0,
						transform: active ? "translateX(0px)" : "translateX(20px)",
						transition: "all 200ms cubic-bezier(0.12, 0.83, 0.58, 1)"
					}}
					onClick={()=>handlePrevSlide()}
				>
					<LeftArrow />
				</Button>
				<Button 
					variant="light-circular"
					sx={{ 
						pointerEvents: "auto",
						opacity: active ? 1 : 0,
						transform: active ? "translateX(0px)" : "translateX(-20px)",
						transition: "all 200ms cubic-bezier(0.12, 0.83, 0.58, 1)"
					}}
					onClick={()=>handleNextSlide()}
				>
					<RightArrow />
				</Button>
			</Box>
		</Box>
	)
}
