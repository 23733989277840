import React, { useState } from 'react';
import { Box, CircularProgress, Button, Select, MenuItem, Pagination, Snackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import QuestionSummary from './QuestionSummary';
import AnimPopIn from '../../../global_components/Animated/AnimPopIn';

export default ({ getQuestionsQuery, show, setShow, sort, setSort, page, setPage, toggleShowAsk, selectedQuestion, setSelectedQuestion, categoryId, setCategoryId, snackbar, setSnackbar }) => {

	const theme = useTheme();

	return (
		<>
			<AnimPopIn
				delay={0.2}
				sx={{
					marginTop: "20px",
					padding: "36px 36px 18px 36px",
					borderRadius: "16px 16px 0 0",
					backgroundColor: "white",
					boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px",
				}}
			>
				<Box sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					gap: "20px"
				}}>
					<Box sx={{ color: "#3B3B3B", fontWeight: 600, fontSize: "18px", padding: "6px" }}>
						{getQuestionsQuery.data.data.numResults} Questions
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
						<Box sx={{ 
							display: "flex", 
							alignItems: "center", 
							gap: "10px", 
							fontWeight: 500, 
							color: theme.palette.secondary.light,
							fontSize: "13px",
							"& .MuiInputBase-input": { padding: "6px 22px 6px 10px" }
						}}>
							Sort by
							<Select
								size="small"
								value={sort}
								onChange={(e)=>setSort(e.target.value)}
							>
								<MenuItem value="-updatedAt">Recent</MenuItem>
								<MenuItem value="-views">Views</MenuItem>
								<MenuItem value="-likes.length">Likes</MenuItem>
							</Select>
						</Box>
						<Box sx={{ 
							display: "flex", 
							alignItems: "center", 
							gap: "10px", 
							fontWeight: 500, 
							color: theme.palette.secondary.light,
							fontSize: "13px",
							"& .MuiInputBase-input": { padding: "6px 22px 6px 10px" }
						}}>
							Show	
							<Select
								size="small"
								value={show}
								onChange={(e)=>setShow(e.target.value)}
							>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={20}>20</MenuItem>
							</Select>
						</Box>
					</Box>
					<Button 
						sx={{ width: "180px" }}
						variant="contained"
						onClick={()=>{ 
							if(categoryId===null) {
								setSnackbar({ msg: 'You must select a category first e.g "Engineering"', show: true });
							}
							else {
								toggleShowAsk(); 
							}
						}}
					>
						Ask a question
					</Button>
				</Box>
				<Box sx={{ height: "0.5px", width: "100%", backgroundColor: "#A4A4A4", margin: "24px 10px 0 10px" }}/>
			</AnimPopIn>
			<AnimPopIn 
				delay={0.2}
				sx={{
					padding: "10px 36px 36px 36px",
					borderRadius: "0 0 16px 16px",
					backgroundColor: "white",
					overflowY: "scroll",
					overflowX: "hidden",
					boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px"
				}}
			>
				{ getQuestionsQuery.data.data.questions.map((question, index) => (
					<>
						{ index > 0 && (
							<Box sx={{ height: "0.5px", width: "100%", backgroundColor: "#A4A4A4", margin: "24px 10px" }}/>
						)}
						<QuestionSummary
							setSelectedQuestion={setSelectedQuestion}
							setCategoryId={setCategoryId}
							id={question._id}
							userId={question.userId}
							heading={question.title}
							categories={getQuestionsQuery.data.data.categories}
							categoryId={question.categoryId}
							comments={question.comments.length}
							views={question.views}
							createdAt={question.createdAt}
							updatedAt={question.updatedAt}
						/>
					</>
				))}
				<Box sx={{ height: "0.5px", width: "100%", backgroundColor: "#A4A4A4", margin: "24px 10px" }}/>
				<Box sx={{ width: "100%", paddingTop: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Pagination
						count={Math.ceil(getQuestionsQuery.data.data.numResults/show)}	
						page={page}
						onChange={(event, value)=>setPage(value)}
					/>
				</Box>
			</AnimPopIn>
			<Snackbar 
				anchorOrigin={{vertical: "top", horizontal: "center" }} 
				open={snackbar.show} 
				onClose={()=>setSnackbar({ show: false })}
				message={snackbar.msg}
			/>
		</>
	)
}
