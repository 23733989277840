import React, { useState } from 'react';
import { Box, Accordion, AccordionSummary, AccordionDetails, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SidebarItem from './components/SidebarItem';
import { ReactComponent as GraduationCapIcon } from '../../assets/misc_icons/graduation-cap-solid.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/misc_icons/chevron-left-solid.svg';
import BookImg from '../../assets/misc_icons/book.png';
import PresentationImg from '../../assets/misc_icons/presentation.png';
import InterviewImg from '../../assets/misc_icons/interview.png';
import { motion } from 'framer-motion';
import { useMainContext } from '../../providers/MainProvider';
import { useLearningContext, PageStates } from './providers/LearningProvider';
import { useGetCareerSpecsByPath } from '../../api/careerSpecialisationApi';
import { useGetModulesByCareerSpec, useGetModule } from '../../api/modulesApi';

const Sidebar = () => {

	const theme = useTheme();
	const { selectedCareerPathId, selectedCareerSpecId, selectedModuleId } = useMainContext();
	const { 
		pageState, 
		careerSpecsOpen, 
		setCareerSpecsOpen, 
		modulesOpen,
		setModulesOpen,
		slidesOpen,
		setSlidesOpen,
		handleHomeClick,
		handleCareerSpecClick,
		handleModuleClick
	} = useLearningContext();

	const getCareerSpecsQuery = useGetCareerSpecsByPath(selectedCareerPathId);
	const getModulesQuery = useGetModulesByCareerSpec(selectedCareerSpecId);
	const getModuleQuery = useGetModule({ moduleId: selectedModuleId });

	const [ buttonHover, setButtonHover ] = useState(false);

	return (
		<Box sx={{ 
			width: "100%", 
			minWidth: "100%", 
			height: "100%", 
			padding: "20px 16px",
			display: "flex",
			flexDirection: "column",
			overflowY: "scroll"
		}}>
			<motion.div 
				style={{ 
					position: "relative",
					minHeight: "70px",
					backgroundColor: theme.palette.primary.main,
					boxShadow: "rgba(149, 157, 165, 0.35) 0px 2px 8px",
					borderRadius: "15px",
					padding: "7px 9px",
					display: "flex",
					alignItems: "center",
					color: "white",
					cursor: "pointer"
				}}
				whileHover={{ scale: 1.025 }}
				onMouseEnter={()=>setButtonHover(true)}
				onMouseLeave={()=>setButtonHover(false)}
				whileTap={{ scale: 0.985 }}
				transition = {{duration: 0.65, type: 'spring', bounce: 0.4 }}
				onClick={() => handleHomeClick()}
			>	
				<Box sx={{
					backgroundColor: "white",
					borderRadius: "12px",
					aspectRatio: "1/1",
					height: "100%",
				}}>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "12px", width: "100%" }}>
					<Box sx={{ display: "flex", flexDirection: "row", fontSize: "12px", fontWeight: 500}}>
						<GraduationCapIcon fill="white" style={{ width: "16px", marginRight: "4px" }}/>
						Course Overview
					</Box>
					<Box sx={{ fontWeight: 600 }}>Course Name Here</Box>
				</Box>
				<Box sx={{
					width: "26px",
					display: "flex",
					alignItems: "center",
					marginRight: "16px",
					transition: "all 200ms ease-out",
					transform: `translateX(${buttonHover ? "0px" : "12px"})`,
				}}>
					<ChevronLeftIcon fill="white"/>
				</Box>
			</motion.div>
			<Box sx={{ marginTop: "30px" }}>
				<Accordion expanded={careerSpecsOpen} onChange={()=>setCareerSpecsOpen((prev)=>!prev)}>
					<AccordionSummary>
						<Box sx={{ display: "flex", padding: "5px", gap: "14px", alignItems: "center", fontWeight: 600, color: "#3B3B3B" }}>
							<img src={InterviewImg} style={{ width: "30px" }}/>
							Career Specialisations
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						{ getCareerSpecsQuery.data.data.map((spec, index) => (
							<SidebarItem
								text={spec.name}
								number={`${index+1}`}
								handleClick={()=>handleCareerSpecClick(spec._id)}
							/>
						))}
						<Button sx={{
							width: "100%", 
							display: "flex", 
							justifyContent: "flex-start", 
							padding: "15px 20px", 
							gap: "12px", 
							color: "#A1A1A1",
						}}>
							<Box sx={{ 
								fontWeight: 650, 
								borderRadius: "50%", 
								backgroundColor: "#A1A1A1", 
								color: "white", 
								display: "flex", 
								justifyContent: "center", 
								alignItems: "center", 
								width: "22px", 
								height: "22px"
							}}>
								+
							</Box>
							<Box sx={{ fontWeight: 550 }}>Add Suggestion</Box>
						</Button>
					</AccordionDetails>
				</Accordion>
				<Box sx={{ marginTop: "8px" }} />
				<Accordion expanded={modulesOpen} onChange={()=>setModulesOpen((prev)=>!prev)}>
					<AccordionSummary>
						<Box sx={{ display: "flex", padding: "5px", gap: "14px", alignItems: "center", fontWeight: 600, color: "#3B3B3B" }}>
							<img src={BookImg} style={{ width: "30px" }}/>
							Modules
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						{ pageState < PageStates.HOME_MODULES ? (
							<Box sx={{ padding: "20px 20px", fontStyle: "italic", fontWeight: "450", fontSize: "13px" }}>No Career Specialisation Selected Yet</Box>
						) : (
							getModulesQuery.isSuccess ? (
								getModulesQuery.data.data.map((module, index) => (
									<SidebarItem
										text={module.name}
										number={`${index+1}`}
										handleClick={()=>handleModuleClick(module._id)}
									/>
								))
							) : (
								<Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
									<CircularProgress
										variant="indeterminate"
										size="60px"
									/>
								</Box>
							)
						)}
						<Button sx={{
							width: "100%", 
							display: "flex", 
							justifyContent: "flex-start", 
							padding: "15px 20px", 
							gap: "12px", 
							color: "#A1A1A1",
						}}>
							<Box sx={{ 
								fontWeight: 650, 
								borderRadius: "50%", 
								backgroundColor: "#A1A1A1", 
								color: "white", 
								display: "flex", 
								justifyContent: "center", 
								alignItems: "center", 
								width: "22px", 
								height: "22px"
							}}>
								+
							</Box>
							<Box sx={{ fontWeight: 550 }}>Add Module Suggestion</Box>
						</Button>
					</AccordionDetails>
				</Accordion>
				<Box sx={{ marginTop: "8px" }} />
				<Accordion expanded={slidesOpen} onChange={()=>setSlidesOpen((prev)=>!prev)}>
					<AccordionSummary>
						<Box sx={{ display: "flex", padding: "5px", gap: "14px", alignItems: "center", fontWeight: 600, color: "#3B3B3B" }}>
							<img src={PresentationImg} style={{ width: "30px", padding: "2px" }}/>
							Slides
						</Box>
					</AccordionSummary>
					<AccordionDetails>
						{ pageState < PageStates.CONTENT ? (
							<Box sx={{ padding: "20px 20px", fontStyle: "italic", fontWeight: "450", fontSize: "13px" }}>No Module Selected Yet</Box>
						) : (
							getModuleQuery.isSuccess ? (
								getModuleQuery.data.slides.map((slide, index) => (
									<SidebarItem
										text={slide.title}
										number={`${index+1}`}
									/>
								))
							) : (
								<Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
									<CircularProgress
										variant="indeterminate"
										size="60px"
									/>
								</Box>
							)
						)}
						<Button sx={{
							width: "100%", 
							display: "flex", 
							justifyContent: "flex-start", 
							padding: "15px 20px", 
							gap: "12px", 
							color: "#A1A1A1",
						}}>
							<Box sx={{ 
								fontWeight: 650, 
								borderRadius: "50%", 
								backgroundColor: "#A1A1A1", 
								color: "white", 
								display: "flex", 
								justifyContent: "center", 
								alignItems: "center", 
								width: "22px", 
								height: "22px"
							}}>
								+
							</Box>
							<Box sx={{ fontWeight: 550 }}>Add Module Suggestion</Box>
						</Button>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Box>
	)
}

export default React.memo(Sidebar);
