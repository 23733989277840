import { Box, Button, Radio } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default ({question, answers, updateAnswers, options, validation, correctAnswer, index}) => {
	
	const theme = useTheme();

	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
			<Box sx={{ 
				backgroundColor: "#F4F4F4", 
				borderRadius: "20px", 
				marginBottom: "16px", 
				padding: "20px", 
				width: "100%", 
				fontWeight: 450, 
				display: "flex", 
				justifyContent: "center" 
			}}>
				{ question }
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
				{ options.map((option) => (
					<Box sx={{ display: "flex", alignItems: "center", maxWidth: "380px"}}>
						<Radio
							size="medium"
							sx={{ color: "#E4E4E4" }}
							color={(!validation[index] && answers[index]===option && correctAnswer!==option) ? "error" : "primary"}
							label={option}
							value={option}
							onChange={(event)=>updateAnswers(index, event.target.value)}
							checked={answers[index]===option}
						/>
						<Box sx={{
							transition: "color 100ms ease-out",
							color: answers[index]===option ? ((correctAnswer!==option && !validation[index]) ? theme.palette.error.light : theme.palette.primary.main) : theme.palette.secondary.main
						}}>
							{ option }
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	)

}
