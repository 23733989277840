import { useState } from 'react';
import { useMainContext } from '../../providers/MainProvider';
import AnimWrapperFromHome from '../../global_components/Animated/AnimWrapperFromHome';
import AnimWrapperDelayed from '../../global_components/Animated/AnimWrapperDelayed';
import { Box, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import Carousel from '../../global_components/Animated/Carousel';
import { SwiperSlide } from 'swiper/react';
import CareerPathCarouselCard from './components/CareerPathCarouselCard';
import CareerPopup from '../career_popup/CareerPopup';
import { useGetCareerPaths } from '../../api/careerPathApi';

const breakpoints = {
	640: {
		slidesPerView: 2.5,
	},
	900: {
		slidesPerView: 3,
	},
	1280: {
		slidesPerView: 3.5,
	},
	1424: {
		slidesPerView: 4,
	},
	1680: {
		slidesPerView: 4.5,
	},
	1800: {
		slidesPerView: 5.25,
	},
	1900: {
		slidesPerView: 5.75
	},
	2100: {
		slidesPerView: 6,
	},
	2400: {
		slidesPerView: 7,
	},
}

export default () => {

	const getCareerPathsQuery = useGetCareerPaths();
	const { selectedCareerPathId, setSelectedCareerPathId, openPathPopup, setOpenPathPopup } = useMainContext();

	return (
		<>
			<AnimWrapperFromHome duration="0.4" delay={300}>
					<Box sx={{
						height: "100%",
						position: "relative",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center"
					}}>
						<Carousel breakpoints={breakpoints}>
							{ getCareerPathsQuery.isSuccess ? (
									getCareerPathsQuery.data.data.map((careerPath, index) => (
										<SwiperSlide>
											<CareerPathCarouselCard
												name={careerPath.name}
												desc={careerPath.descBrief}
												picture={careerPath.media.cardMedia}
												color={careerPath.color}
												index={index}
												id={careerPath._id}
												selectedCareerPathId={selectedCareerPathId}
												setSelectedCareerPathId={setSelectedCareerPathId}
												setOpen={setOpenPathPopup}
											/>
										</SwiperSlide>
									)).concat(
										<SwiperSlide>
											<Box sx={{ minWidth: "120px", maxWidth: "120px" }} />
										</SwiperSlide>
									)
							) : (
								<Box sx={{ 
									width: "100%", 
									height: "100%",
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}}>
									<CircularProgress
										variant="indeterminate"
										size="60px"
									/>
								</Box>
							)}
						</Carousel>
					</Box>
			</AnimWrapperFromHome>
			<CareerPopup 
				open={openPathPopup} 
				setOpen={setOpenPathPopup} 
				selectedCareerPathId={selectedCareerPathId}
			/>
		</>
	)
}
