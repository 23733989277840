import { Button, Box, CircularProgress, circularProgressClasses, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion';

export default ({ id, name, icon, desc, progress, index }) => {

    const [ movingProgress, setMovingProgress ] = useState(0);

    useEffect(()=> {
        setTimeout(
            () => setInterval(() => {
                setMovingProgress((prevProgress) => (prevProgress >= progress ? Math.round(progress) : Math.round(prevProgress + 5)));
            }, 20)
        , index * 300 + 1000) 
    }, [])

    if (progress === 100) {
        var color = '#8FD386'
    } else if (50 < progress) {
        var color = '#F7DB36'
    } else if (progress < 50) {
        var color = '#ED6F71'
    }

    return (
        <motion.div 
            layout
            initial = {{ opacity: 0, x: -30}}
            animate = {{ opacity: 1, x: 0}}
            transition = {{ duration: 0.65, type: 'spring', bounce: 0.4, delay: index*0.15 }}
					style = {{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0 20px 0', color: "#3b3b3b"}}
        >
            <Box style = {{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <Box>
									<Box sx = {{width: '50px', height: '50px', backgroundColor: '#F3F7FF', borderRadius: '12px', padding: "12px", "& svg": { fill: "#3B3B3B" } }}>
											<Box dangerouslySetInnerHTML={{ __html: icon}} />
										</Box>
                </Box>
                <Box sx = {{marginLeft: '20px'}}>
                    <Box sx = {{display: 'flex', flexDirection: 'column', width: '400px', justifyContent: 'center', alignItems: 'left'}}>
                        <h1 style = {{fontSize: 13, fontWeight: 600}}>{ name }</h1>
                        <p style = {{fontSize: 11, fontWeight: 400, color: '#A4A4A4'}}> { desc }</p>
                    </Box>
                </Box>
            </Box>
            <Box sx = {{position: 'relative', width: '50px', height: '50px'}}>
                <CircularProgress
                    variant = "determinate"
                    value = {100}
                    size = '50px'
                    sx = {{
                        width: '50px',
                        height: '50px',
                        color: '#F3F7FF',
                        borderRadius: '50%',
                        position: 'absolute',
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                />
                <CircularProgress 
                    variant = "determinate"
                    value = {movingProgress}
                    size = '50px'
                    thickness = {4}
                    sx = {{
                        width: '50px',
                        height: '50px',
                        color: color,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                }}
                />
                <Box sx = {{
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        position: 'absolute',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        
                    }}
                >
                        <Typography
                            variant = 'caption'
                            component = 'div'
                            color = '#2B2B2B'
                            sx = {{fontWeight: 600}}
                        > {`${movingProgress}`}
                        </Typography>
                </Box>
            </Box>

        </motion.div>
    )
}
