import React, { useState } from 'react';
import { Box, Button, Collapse, InputBase, CircularProgress, Typography, IconButton } from '@mui/material';
import { ImageOutlined, DeleteOutlined } from '@mui/icons-material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ForumProfile from './ForumProfile';
import ImagesSection from './ImagesSection';
import Dropzone from 'react-dropzone';
import AnimPopIn from '../../../global_components/Animated/AnimPopIn';
import { ReactComponent as LeftArrowIcon } from '../../../assets/misc_icons/left-arrow.svg';
import { useGetSelectedQuestion, useAnswerQuestion, useLikeQuestion } from '../../../api/questionApi';
import { useGetUser } from '../../../api/userApi';

const category= "Engineering"
const icon= "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d='M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z'/></svg>"

export default ({ selectedQuestion, setSelectedQuestion, selectedImg, setSelectedImg }) => {

	const getSelectedQuestionQuery = useGetSelectedQuestion(selectedQuestion);
	const getUserQuery = useGetUser();
	const likeQuestionMutation = useLikeQuestion(selectedQuestion);

	(getSelectedQuestionQuery.isSuccess && getUserQuery.isSuccess) && getSelectedQuestionQuery.data.data.likes.includes(getUserQuery.data.data.id) && console.log('WORKING')
	

	const [ show, setShow ] = useState(true);
	const [ image, setImage ] = useState(null);
	const [ isImage, setIsImage ] = useState(false);
	const [ liked, setLiked ] = useState(false);

	const createdDate = new Date(getSelectedQuestionQuery.isSuccess && getSelectedQuestionQuery.data.data.createdAt);
	const updatedDate = new Date(getSelectedQuestionQuery.isSuccess && getSelectedQuestionQuery.data.data.updatedAt);
	const currentDate = new Date();

	const postDate = Math.round((currentDate - createdDate)/86400000);
	
	const likeQuestion = () => {
		setLiked((prev) => !prev);
		likeQuestionMutation.mutate();
	}

	return getSelectedQuestionQuery.isSuccess ? (
		<AnimPopIn 
			sx={{
				padding: "24px 36px",
				borderRadius: "16px",
				backgroundColor: "white",
				flexShrink: 0,
				overflowY: "scroll",
				overflowX: "hidden",
				"&:hover": { "::-webkit-scrollbar-thumb": {
					background: "#888"
				}},
				boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px",
				display: "flex",
				flexDirection: "column",
				gap: "6px"
			}}
		>
			<Button 
				variant="contained"
				sx={{ 
					width: "90px", 
					height: "32px",
					display: "flex", 
					fontSize: "12px",
					gap: "4px", 
					color: "white", 
					fill: "white" 
				}}
				onClick={()=>setSelectedQuestion(null)}
			>
				<LeftArrowIcon width="18px"/>Back
			</Button>
			<Box sx={{
				display: "flex",
				gap: "16px",
				justifyContent: "space-between"
			}}>
				<Box sx={{
					width: "100%",
					display: "flex",
					flexDirection: "column",
					gap: "6px"
				}}>
					<Button sx={{ 
						fontWeight: 600, 
						fontSize: "18px",
						color: "#3B3B3B",
						textAlign: "left",
						width: '100$',
						justifyContent: 'flex-start'
					}}>
						{getSelectedQuestionQuery.data.data.title}
					</Button>
				</Box>
				<Box sx={{ paddingTop: "10px" }}>
					<ForumProfile
						userId={getSelectedQuestionQuery.data.data.userId}
						postDate={postDate}
					/>
				</Box>
			</Box>
			<Collapse in={show}>
				<Box sx={{ 
					display: "flex", 
					alignItems: "center", 
					color: "#A4A4A4",
					fontWeight: 500,
					fontSize: "14px",
					gap: "20px"
				}}>
					<Button 
						key={1}
						variant="light-blue-fill"
						sx={{ 
							display: "flex", 
							alignItems: "center",
							gap: "6px",
							width: "auto",
							padding: "8px 20px"
						}}
					>
						<Box 
							sx={{ width: "20px", display: "flex", alignItems: "center" }}
							dangerouslySetInnerHTML={{ __html: getSelectedQuestionQuery.data.data.category.icon }}
						/>
						{getSelectedQuestionQuery.data.data.category.name}
					</Button>
					<FavoriteIcon 
						onClick={likeQuestion}
						sx={{ "&:hover": { cursor: "pointer", color: '#1B56BA' }, color: getSelectedQuestionQuery.data.data.likes.includes(getUserQuery.data.data._id) ? "#1B56BA" : '#A4A4A4' }}
					/>
					<Box>{ getSelectedQuestionQuery.data.data.comments.length } Answers</Box>
					<Box>{ getSelectedQuestionQuery.data.data.views } Views</Box>
				</Box>
				<Box sx={{ padding: "16px 8px", display: "flex", flexDirection: "column", gap: "14px" }}>
					{ getSelectedQuestionQuery.data.data.description }
					<ImagesSection 
						imgs={getSelectedQuestionQuery.data.data.picturePath}
						selectedImg={selectedImg}
						setSelectedImg={setSelectedImg}
					/>
				</Box>
			</Collapse>
			<Box sx={{ 
				padding: "0 8px", 
				fontWeight: 500,
				display: "flex",
				alignItems: "center"
			}}>
				<Box sx={{ width: "100%"}}>
					{`${getSelectedQuestionQuery.data.data.comments.length} Answers`}
				</Box>
				<Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
					<Button 
						variant="contained"
						sx={{ 
							padding: "2px",
							width: "64px",
						}}
						onClick={()=>setShow((prev)=>!prev)}
					>
						<ExpandMoreIcon sx={{
							transform: `rotate(${show ? "180deg" : "0"})`,
							transition: "all 200ms ease-out"
						}}/>
					</Button>
				</Box>
				<Box sx={{ width: "100%", display: "flex", flexDirection: 'column'}}>
					<Collapse
						in={isImage}
					>
						<Dropzone
						acceptedFiles=".jpg,.jpeg,.png,.svg"
						multiple={false}   
						onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
					>
						{({ getRootProps, getInputProps }) => (
							<Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<Box
								{...getRootProps()}
								border={`2px dashed #A4A4A4`}
								p="1rem"
								width="100%"
								sx={{ "&:hover": { cursor: "pointer" }, borderRadius: '10px' }}
								>
								<input {...getInputProps()} />
								{!image ? (
									<p style={{color: '#3B3B3B'}}>Add Image Here</p>
								) : (
									<Box>
									<Typography>{image.name}</Typography>
									</Box>
								)}
								</Box>
								{image && (
									<IconButton
										onClick={() => setImage(null)}
										sx={{ width: "15%" }}
									>
										<DeleteOutlined 
											sx={{ "&:hover": { cursor: "pointer", backgroundColor: 'none' } }}
										/>
									</IconButton>
								)}
							</Box>
							)}
						</Dropzone>
					</Collapse>
				</Box>
			</Box>
		</AnimPopIn>
	) : (
		<Box sx={{
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		}}>
			<CircularProgress
				variant="indeterminate"
				size="60px"
			/>
		</Box>
	)
}
