import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import QuestionWhole from './components/QuestionWhole';
import AnswersSection from './components/AnswersSection';
import CommentSection from './components/CommentSection';
import { AnimatePresence } from 'framer-motion';
import Popup from '../../global_components/Animated/Popup';
import { useMainContext } from '../../providers/MainProvider';

export default ({ selectedQuestion, setSelectedQuestion }) => {

	const [ showAsk, setShowAsk ] = useState(false);
	const [ showAskReal, setShowAskReal ] = useState(false);
	const [ selectedImg, setSelectedImg ] = useState(false);

	const queryClient = useQueryClient();
	const { baseURL } = useMainContext();

	const toggleShowComment = () => {
		if(showAsk===false) { 
			setShowAsk(true) 
		}
		else { 
			setTimeout(()=>{ 
				setShowAsk(false) 
			}, 500);
			setShowAskReal(false);
		}
	}
	useEffect(() => {
		if(showAsk) {
			setTimeout(() => { setShowAskReal(true) }, 350); 
		}
		else {
			setShowAskReal(false);
		}
	}, [showAsk]);

	return queryClient.isFetching() ? (
		<Box sx={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		}}>
			<CircularProgress
				variant="indeterminate"
				width="60px"
			/>
		</Box>
	) : (
		<>
			<QuestionWhole 
				key={1}
				selectedQuestion={selectedQuestion}
				setSelectedQuestion={setSelectedQuestion}
				selectedImg={selectedImg}
				setSelectedImg={setSelectedImg}
			/>
			<CommentSection
				showAsk={showAsk}
				showAskReal={showAskReal}
				toggleShowComment={toggleShowComment}
				selectedQuestion={selectedQuestion}
			/>
			<AnswersSection
				key={2}
				selectedQuestion={selectedQuestion}
				toggleShowComment={toggleShowComment}
			/>	
			<AnimatePresence>
			{ selectedImg && (
				<Popup setOpen={setSelectedImg}>
					<Box sx={{ maxHeight: "800px", maxWidth: "1000px" }}>
						<img 
							src={`${baseURL}${selectedImg}`} 
							style={{ width: "100%", height: "100%" }}
						/>
					</Box>
				</Popup>
			)}		
			</AnimatePresence>
		</>
	)
}
