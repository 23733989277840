import { borderRadius } from '@mui/system';
import { useCallback } from 'react';
import { Handle, Position } from 'reactflow'

function HeadNode({ data }) {
    return (
        <div style = {{
                height: '70px',
                border: '1px solid #E4E4E4',
                width: '200px',
                padding: '5px 10px 5px 10px',
                backgroundColor: data.backgroundColor,
                borderRadius: '10px'
            }}
        >
            <div style={{position: 'absolute', top: '-20px', left: '20px'}}>
                <img 
                    src = { data.image }
                    style = {{
                        width: '35px',
                        height: '35px',
                        objectFit: 'cover',
                        borderRadius: '50%'
                    }}
                />
            </div>
            <div
                style = {{
                    padding: '18px 10px 10px 10px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <label htmlFor='text' style={{fontWeight:'450',fontSize:'13px',color:'#1B56BA'}}>{ data.label }</label>
                <label htmlFor='text' style={{fontWeight:'350',fontSize:'9px'}}>{ data.desc }</label>
            </div>
        </div>
    )
}

export default HeadNode
