import { AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';
import { Tooltip, Button, InputBase, Typography, MenuItem, Select, Box, CircularProgress } from '@mui/material';
import { useGetCareerPaths } from '../../../api/careerPathApi';
import { useGetCareerSpecsByPath, usePostCareerSpec } from '../../../api/careerSpecialisationApi';
import CareerSpecCardPreview from './CareerSpecCardPreview';
import CareerSpecPopupPreview from './CareerSpecPopupPreview';

export default ({setOpenBar, setBarContents}) => {

	const [ showCardPreview, setShowCardPreview ] = useState(false);
	const [ showPopupPreview, setShowPopupPreview ] = useState(false);
	const [ selectedCareerPathId, setSelectedCareerPathId ] = useState(null);
	const careerPathsQuery = useGetCareerPaths();
	const careerSpecsQuery = useGetCareerSpecsByPath(selectedCareerPathId);
	const postCareerSpecMutation = usePostCareerSpec({setOpenBar, setBarContents});

	useEffect(() => {
		if(showCardPreview) {
			setShowPopupPreview(false);
		}
		else if(showPopupPreview) {
			setShowCardPreview(false);
		}
	}, [showCardPreview, showPopupPreview]);

	const [ form, setForm ] = useState({
		name: "",
		media: {
			cardMedia: "",
			popupMedia: "",
			icon: ""
		},
		descLong: "",
		descBrief: "",
		color: "#1B56BA",
		duration: 45 
	});

	return (
		<>
			<Box sx={{ display: "flex", flexDirection: "column", marginBottom: "26px", alignItems: "flex-start" }}>
				<Typography sx={{ marginBottom: "10px" }}>Select a Career Path</Typography>
				<Select 
					onChange={(e) => setSelectedCareerPathId(e.target.value)}
					sx={{
						height: "36px"
					}}
				>
					{careerPathsQuery.isSuccess && careerPathsQuery.data.data.map((careerPath) => (
						<MenuItem value={careerPath._id}>{careerPath.name}</MenuItem>
					))}
				</Select>
			</Box>
			<Box sx={{
				marginBottom: "36px"
			}}>
				<Typography>View Career Specialisations</Typography>
				<AnimatePresence>
					{ showCardPreview && <CareerSpecCardPreview form={form} setOpen={setShowCardPreview}/>}
					{ showPopupPreview && <CareerSpecPopupPreview form={form} setOpen={setShowPopupPreview}/>}
				</AnimatePresence>
			</Box>
			<Box sx={{ 
				display: "flex", 
				flexDirection: "column",
				gap: "12px"
			}}>
				<Typography sx={{ marginBottom: "10px" }}>Add Career Specialisation</Typography>
				<Box sx={{ display: "flex" }}>
					<Box sx={{ width: "200px"}}>Name</Box>
					<InputBase 
						onChange={(e)=>setForm({...form, name: e.target.value})}
					/>
				</Box>
				<Tooltip title="Similar to HTML templates, e.g http://imgur.com/s5nSnOQ.png">
					<Box sx={{ display: "flex" }}>
						<Box sx={{ width: "200px"}}>Card Photo URL </Box>
						<InputBase 
							onChange={(e)=>setForm({...form, media: {...form.media, cardMedia: e.target.value}})}
						/>
					</Box>
				</Tooltip>
				<Tooltip title="e.g http://imgur.com/s5nSnOQ.png OR https://www.youtube.com/embed/bPjZmQAvk_8">
					<Box sx={{ display: "flex" }}>
						<Box sx={{ width: "200px"}}>Popup Photo URL or youtube video URL </Box>
						<InputBase 
							onChange={(e)=>setForm({...form, media: {...form.media, popupMedia: e.target.value}})}
						/>
					</Box>
				</Tooltip>
				<Tooltip title="Download a .svg file, open it with any text editor and then copy & paste the code into this field">
					<Box sx={{ display: "flex" }}>
						<Box sx={{ width: "200px"}}>SVG code</Box>
						<InputBase 
							onChange={(e)=>setForm({...form, media: {...form.media, icon: e.target.value}})}
						/>
					</Box>
				</Tooltip>
				<Box sx={{ display: "flex" }}>
					<Box sx={{ width: "200px"}}>Long Description</Box>
					<InputBase 
						onChange={(e)=>setForm({...form, descLong: e.target.value})}
					/>
				</Box>
				<Box sx={{ display: "flex" }}>
					<Box sx={{ width: "200px"}}>Short Description (Summarised)</Box>
					<InputBase 
						onChange={(e)=>setForm({...form, descBrief: e.target.value})}
					/>
				</Box>
				<Tooltip title="Must be a hex code (e.g #FFEE16)">
				<Box sx={{ display: "flex" }}>
					<Box sx={{ width: "200px"}}>Color</Box>
					<InputBase 
						value={form.color}
						onChange={(e)=>setForm({...form, color: e.target.value})}
					/>
				</Box>
				</Tooltip>
				<Tooltip title="Please approximate the total duration of the career specialisation (just put 50 minutes if unsure)">
				<Box sx={{ display: "flex" }}>
					<Box sx={{ width: "200px"}}>Duration (in mins)</Box>
					<InputBase 
						value={form.duration}
						onChange={(e)=>setForm({...form, duration: e.target.value})}
					/>
				</Box>
				</Tooltip>
				<Box sx={{ display: "flex", gap: "10px" }}>
					<Tooltip title="There is no confirmation, pressing this will immediately post the new career specialisation into the database">
						<Button 
							variant="contained" 
							onClick={async ()=>{
								if(selectedCareerPathId) {
									postCareerSpecMutation.mutate({
										careerPathId: selectedCareerPathId,
										name: form.name,
										media: form.media,
										descLong: form.descLong,
										descBrief: form.descBrief,
										color: form.color,
										duration: Number(form.duration)
									})
								}
								else {
									setOpenBar(true);
									setBarContents({ severity: "error", msg: "You must select a career path that this career specialisation falls under" });
								}
							}}
						>
							Post to database
						</Button>
					</Tooltip>
					<Button
						variant="outlined"
						onClick={()=>setShowCardPreview(true)}
					>
						Preview Card
					</Button>
					<Button
						variant="outlined"
						onClick={()=>setShowPopupPreview(true)}
					>
						Preview Popup
					</Button>
				</Box>
			</Box>
		</>
	)
}
