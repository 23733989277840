import { Box } from '@mui/material';
import { motion } from 'framer-motion';

export default ({sx, img}) => (
	<motion.div 
		style={{
			//boxShadow: 'rgba(0, 0, 0, 0.12) 0px 7px 29px 0px',
			borderRadius: "32px",
			overflow: 'hidden',
			width: "500px",
			height: "300px",
			position: "relative",
			backgroundImage: `url(${img})`,
			backgroundSize: "cover",
			...sx
		}}
		initial={{ y: 36, opacity: 0 }}
		whileInView={{ y: 0, opacity: 1 }}
		transition={{ 
			opacity: { delay: 0.25, type: "spring", stiffness: 200, damping: 28 },
			default: { delay: 0.25, type: "spring", stiffness: 180, damping: 10 }
		}}
	/>
)

