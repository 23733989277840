import { useState, useEffect } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useVerify } from '../../api/authApi.js';
import { useMainContext } from '../../providers/MainProvider';
import ErrorImg from '../../assets/img/error.jpg';
import WelcomeImg from '../../assets/img/welcome.jpg';

export default () => {

	const params = useParams();
	const theme = useTheme();
	const navigate = useNavigate();
	const verifyMutation = useVerify();
	const [ verified, setVerified ] = useState(false);
	const { setAuthType } = useMainContext();
	
	useEffect(() => {
		setAuthType("LOGIN");
		verifyMutation.mutate({ userId: params.userId });
	}, []);

	return (
		<Box sx={{
			display: "flex",
			width: "100%",
			height: "100%",
			margin: "auto 0",
			alignItems: "center",
			justifyContent: "center",
		}}>
			{ (verifyMutation.isLoading || verifyMutation.isIdle) && (
				<CircularProgress
					variant="indeterminate"
					width="100px"
				/>
			)}
			{ verifyMutation.isError && (
				<Box sx={{ 
					display: "flex", 
					flexDirection: "column", 
					alignItems: "center", 
					fontSize: "24px", 
					fontWeight: 550,
					gap: "20px",
					color: "#3B3B3B",
					width: "800px",
					textAlign: "center"
				}}>
					<img width="600px" src={ErrorImg} />
					Sorry, we have encountered an error. Please try login again.
				</Box>
			)}
			{ verifyMutation.isSuccess && (
				<Box sx={{ 
					display: "flex", 
					flexDirection: "column", 
					alignItems: "center",
					fontSize: "24px", 
					fontWeight: 550,
					gap: "20px",
					color: "#3B3B3B",
					width: "600px",
					textAlign: "center"
				}}>
					<img width="600px" src={WelcomeImg} />
					Welcome to Advance! Login with the button below to get started on your learning journey
					<Button 
						variant="contained"
						sx={{ width: "180px" }}
						onClick={()=>navigate("/app/careerpath")}
					>
						Get Started
					</Button>
				</Box>
			)}
		</Box>
	)
}
