import { Box, Button } from '@mui/material';
import { ReactComponent as LeftArrow} from '../../assets/misc_icons/left-arrow.svg';
import { useTheme } from '@mui/material/styles';
import { useMainContext } from '../../providers/MainProvider';
import { useLearningContext, PageStates } from './providers/LearningProvider';
import { useGetModule } from '../../api/modulesApi';

export default ({quizzes, quizActive, setQuizActive, moduleFinished, nextSlide, prevSlide, findNumTotalSlides, loaded}) => {

	const theme = useTheme();
	const { selectedModuleId } = useMainContext();
	const { swiperSlide, setSwiperSlide, handleBackClick, handleExitClick, handleNextSlide, handlePrevSlide, pageState } = useLearningContext();
	const getModuleQuery = useGetModule({ moduleId: selectedModuleId });

	return (
		<Box sx={{ 
			height: "100%", 
			width: "100%", 
			backgroundColor: "white", 
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between"
		}}> 	
			{ pageState===PageStates.CONTENT ? ( 
				<>
				<Box sx={{ 
					width: "95px",
					marginLeft: "20px"
				}}>
					<Button 
						variant="contained" 
						sx={{ 
							"svg": { minWidth: "18px", width: "20px", fill: "white" },
							display: "flex", 
							gap: "4px",
							padding: "4px",
							width: "100px",
							height: "40px"
						}}
						onClick={handleBackClick}
					>
						<LeftArrow />
						<Box>Back</Box>
					</Button>
				</Box>
				<Box sx={{ display: "flex", gap: "6px", alignItems: "center" }}>
					{ getModuleQuery.isSuccess ? (
						<>
							<Box sx={{ fontWeight: 600 }}>{getModuleQuery.data.slides[swiperSlide].title}</Box>
							<Box sx={{ fontStyle: "italic", fontSize: "14px" }}>{`${swiperSlide + 1} of ${getModuleQuery.data.slides.length}`}</Box>
						</>
					) : (
						<Box>Loading</Box>
					)}
				</Box>
				<Box sx={{ 
					width: "95px",
					marginRight: "30px"
				}}/>
				</>
			) : (
				<Box sx={{ fontWeight: 600, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px"}}>
					<Button 
						variant="contained" 
						sx={{ 
							"svg": { minWidth: "18px", width: "20px", fill: "white" },
							display: "flex", 
							gap: "4px",
							padding: "4px",
							width: "100px",
							height: "40px"
						}}
						onClick={handleBackClick}
					>
						<LeftArrow />
						<Box>Back</Box>
					</Button>
					<Box>Learning Hub</Box>
					<Box sx={{ width: "100px" }}/>
				</Box>
			)}
				<Box sx={{ 
					position: "absolute", 
					bottom: "0 !important", 
					transition: "250ms ease-out",
					width: pageState===PageStates.CONTENT ? (getModuleQuery.isSuccess ? `${100 * (swiperSlide+1)/getModuleQuery.data.slides.length}%` : "0") : ("100%"), 
					height: "3px", 
					backgroundColor: theme.palette.primary.main,
					boxShadow: "rgba(149, 157, 165, 0.35) 0px 4px 10px"
				}} />	
		</Box>
	)
}
