import { Box, Button, InputBase, Select, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default ({question, answers, correctAnswer, updateAnswersNested, options, validation, index}) => {
	
	const theme = useTheme();
	const questionSplit = question.split("*");

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Box sx={{ backgroundColor: "#F4F4F4", borderRadius: "20px", padding: "20px", fontWeight: 450, display: "flex", justifyContent: "center" }}>
				Fill in the blanks:
			</Box>
			<Box sx={{ padding: "20px" }}>
				{ questionSplit.map((split, indexInner) => (
					<>
						{ split }
						{ indexInner < questionSplit.length - 1 && (
						<Box sx={{ display: "inline-block" }}>
							<Select
								defaultValue=""
								value={answers[index][indexInner]}
								onChange={(event)=>{
									updateAnswersNested(index, indexInner, event.target.value);
								}}
								displayEmpty
								inputProps={{ 'aria-label': 'Without label' }}
								sx={{ 
									height: "40px",	
									boxShadow: "rgba(149, 157, 165, 0.35) 0px 2px 16px",
									borderRadius: "10px",
									borderWidth: "2px",
									"&:hover": { borderColor: theme.palette.secondary.main },
									borderColor: (!validation[index] && answers[index][indexInner] !== correctAnswer[indexInner]) && theme.palette.error.light 
								}}
							>
								{ options[indexInner].map((option) => (
									<MenuItem value={option}>{option}</MenuItem>
								))}
							</Select>
						</Box>
						)}
					</>
				))}
			</Box>
		</Box>
	)

}
