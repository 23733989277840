import { Box, Button, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default ({question, answers, updateAnswersNested, options, validation, correctAnswer, index}) => {
	
	const theme = useTheme();

	return (
		<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
			<Box sx={{ backgroundColor: "#F4F4F4", borderRadius: "20px", marginBottom: "20px", padding: "20px", width: "100%", fontWeight: 450, display: "flex", justifyContent: "center" }}>
				{ question }
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
				{ options.map((option, indexNested) => (
					<Box sx={{ display: "flex", alignItems: "center", maxWidth: "380px"}}>
						<Checkbox
							size="medium"
							sx={{ color: "#E4E4E4" }}
							// Must be validated = false, be selected, not exist within the correct answer array
							color={(!validation[index] && answers[index][indexNested]===option && !correctAnswer.includes(option)) ? "error" : "primary"}
							label={option}
							value={option}
							onChange={(event) => { 
								if(answers[index][indexNested]===option) {
									updateAnswersNested(index, indexNested, null);
								}
								else {
									updateAnswersNested(index, indexNested, event.target.value) 
								}
							}}
							checked={answers[index][indexNested]===option}
						/>
						<Box sx={{
							transition: "color 100ms ease-out",
							color: answers[index][indexNested]===option ? ( !correctAnswer.includes(option) && !validation[index] ? theme.palette.error.light : theme.palette.primary.main) : theme.palette.secondary.main
						}}>
							{ option }
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	)

}
