import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import StudentsImg from '../../assets/unsw_shoot/Sitting1.jpg';
import ImageContainer from '../../components/ImageContainer';
import TickBox from '../../components/TickBox';

export default () => (
	<Box sx={{
		display: "flex",
		justifyContent: "center"
	}}>
		<Box sx={{
			display: "flex",
			gap: "80px",
			width: "1000px",
			margin: "auto"
		}}>
			<Box sx={{
				display: "flex",
				flexDirection: "column",
				gap: "20px"
			}}>
				<motion.div 
					style={{
						fontWeight: 600,
						fontSize: "36px",
						lineHeight: "125%"
					}}
					initial={{ y: 30, opacity: 0 }}
					whileInView={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
				>
					Our Workshops
				</motion.div>
				<motion.div 
					style={{
						fontSize: "16px",
					}}
					initial={{ y: 30, opacity: 0 }}
					whileInView={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
				>
				Our in-person workshops are led by relatable university students who empower young people to make informed career decisions. We provide engaging and interactive learning experiences that aim to help students uncover their passions and strengths, and to pursue meaningful and fulfilling careers
				</motion.div>
				<motion.div 
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "6px",
						marginTop: "8px"
					}}
					initial={{ y: 30, opacity: 0 }}
					whileInView={{ y: 0, opacity: 1 }}
					transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
				>
				</motion.div>
			</Box>
			<Box sx={{
				display: "flex"
			}}>
				<ImageContainer 
					sx={{ width: "500px" }}
					img={StudentsImg}
				/>
			</Box>
		</Box>
	</Box>
)

