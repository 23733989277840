import { useState, useEffect } from 'react';
import { useGetCareerPath, useGetCareerPaths } from '../api/careerPathApi';
import { Box } from '@mui/material';
import AuthPopup from '../global_components/auth/AuthPopup';
import QuizFinished from './learning/components/QuizFinished';
import { useRegister } from '../api/authApi.js';
import { useGetUser } from '../api/userApi.js';

export default () => {

	return (
		<Box>
		</Box>
	)
}
