import { Box, Button } from '@mui/material';
import { useGetSelectedQuestion, useViewQuestion } from '../../../api/questionApi';
import { useMainContext } from '../../../providers/MainProvider';
import ForumProfile from './ForumProfile';

export default ({ setSelectedQuestion, id, userId, heading, categories, categoryId, setCategoryId, comments, views, createdAt, updatedAt }) => {

	const category = categories.find((c) => c._id === categoryId);

	const getViewQuestionMutation = useViewQuestion(id);

	const handleView = (id) => {
		getViewQuestionMutation.mutate();
	}

	// console.log(userId)
	
	const { calculateDate } = useMainContext();
	const postDate = calculateDate(createdAt);

	return (
		<Box
			sx={{
				display: "flex",
				gap: "16px",
				justifyContent: "space-between"
			}}
		>
			<Box sx={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				gap: "6px"
			}}>
				<Button 
					sx={{ 
						fontWeight: 600, 
						fontSize: "18px",
						color: "#3B3B3B",
						textAlign: "left",
						width: '100%',
						justifyContent: 'flex-start'
					}}
					onClick={()=>{
						setSelectedQuestion(id)
						handleView(id);
					}}
				>
					{heading}
				</Button>
				<Box sx={{ 
					display: "flex", 
					alignItems: "center", 
					color: "#A4A4A4",
					fontWeight: 500,
					fontSize: "14px",
					gap: "20px"
				}}>
					{ category && (
						<Button 
							variant="light-blue-fill"
							sx={{ 
								display: "flex", 
								alignItems: "center",
								gap: "6px",
								width: "auto",
								padding: "8px 20px"
							}}
							onClick={()=>setCategoryId(category._id)}
						>
							<Box 
								sx={{ width: "20px", display: "flex", alignItems: "center" }}
								dangerouslySetInnerHTML={{ __html: category.icon }}
							/>
							{category.name}
						</Button>
					)}
					<Box>{ comments } Answers</Box>
					<Box>{ views } Views</Box>
				</Box>
			</Box>
			<Box sx={{ paddingTop: "10px" }}>
				<ForumProfile
					userId={userId}
					postDate={postDate}
				/>
			</Box>
		</Box>
	)
}
