import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, LinearProgress } from '@mui/material';
import { ReactComponent as ReplyIcon } from '../../../assets/misc_icons/comment-solid.svg';
import { ReactComponent as QuestionIcon } from '../../../assets/misc_icons/circle-question-solid.svg';
import { ReactComponent as SuitcaseIcon } from '../../../assets/sidebar_icons/suitcase-solid.svg';
import { useMainContext } from '../../../providers/MainProvider';

export default ({activity, showDate}) => {

	const navigate = useNavigate();
	const { 
		selectedQuestion, 
		setSelectedQuestion, 
		setPrevPathname, 
		calculateDate,
		setSelectedCareerPathId,
		setSelectedCareerSpecId,
		setSelectedModuleId
	} = useMainContext();
	const [ loading, setLoading ] = useState(false);

	const handleClick = async () => {
		setLoading(true);	
		switch(activity.activityType) {
			case "QUESTION":
				setPrevPathname("/app");
				setSelectedQuestion(activity._id) 
				navigate("qa");
				break;
			case "COMMENT":
				setPrevPathname("/app");
				setSelectedQuestion(activity.questionId) 
				navigate("qa");
				break;
			case "CAREERPATH":
				setPrevPathname("/app");
				setSelectedCareerPathId(activity._id);
				navigate("learning");
				break;
			case "CAREERSPEC":
				setPrevPathname("/app");
				setSelectedCareerPathId(activity.careerPathId);
				setSelectedCareerSpecId(activity.career_id);
				navigate("learning");
				break;
			case "MODULE":
				setPrevPathname("/app");
				setSelectedCareerPathId(activity.careerPathId);
				setSelectedCareerSpecId(activity.careerSpecId);
				setSelectedModuleId(activity._id);
				navigate("learning");
				break;
		}
	}

	return (
		<Button
			sx={{
				width: "100%", 
				display: "flex", 
				justifyContent: "flex-start", 
				marginBottom: "8px", 
				padding: "15px 20px", 
				gap: "13px", 
				color: "#3b3b3b" 
			}}
			onClick={handleClick}
		>
			{ !loading ? (
				<>
				<Box sx={{ 
					fontWeight: 650, 
					borderRadius: "50%", 
					color: "white", 
					fill: "#3B3B3B",
					display: "flex", 
					justifyContent: "center", 
					alignItems: "center", 
					minWidth: "22px", 
					width: "22px",
				}}>
					{(() => {
						switch(activity.activityType) {
							case "QUESTION":
								return <QuestionIcon />
							case "COMMENT":
								return <ReplyIcon />
							case "CAREERPATH":
								return <SuitcaseIcon />
							case "CAREERSPEC":
								return <Box sx={{ width: "36px", display: "flex", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: activity.media.icon }} />
							case "MODULE":
								return <Box sx={{ width: "36px", display: "flex", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: activity.icon }} />
						}
					})()}
				</Box>
				<Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", gap: "10px" }}>
					<Box sx={{ fontWeight: 550, textAlign: "left"}}>
					{(() => {
						switch(activity.activityType) {
							case "QUESTION":
								return (
									<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
										<Box sx={{ fontSize: "12px" }}>
											{activity.title.length > 18 ? `${activity.title.substring(0, 18)}...` : activity.title}
										</Box>
										<Box sx={{ fontSize: "10px"}}>
											{activity.description.length > 48 ? `${activity.description.substring(0, 48)}...` : activity.description}
										</Box>
									</Box>
								)
							case "COMMENT":
								return ( 
									<Box sx={{ fontSize: "10px"}}>
										{activity.description.length > 48 ? `${activity.description.substring(0, 48)}...` : activity.descBrief}
									</Box>
								)
							case "CAREERPATH":
								return (
									<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
										<Box sx={{ fontSize: "12px" }}>
											{activity.name.length > 18 ? `${activity.name.substring(0, 18)}...` : activity.name}
										</Box>
										<Box sx={{ fontSize: "10px"}}>
											{activity.descBrief.length > 64 ? `${activity.descBrief.substring(0, 64)}...` : activity.descBrief}
										</Box>
									</Box>
								)
							case "CAREERSPEC":
								return (
									<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
										<Box sx={{ fontSize: "12px" }}>
											{activity.name.length > 24 ? `${activity.name.substring(0, 18)}...` : activity.name}
										</Box>
										<Box sx={{ fontSize: "10px"}}>
											{activity.descBrief.length > 64 ? `${activity.descBrief.substring(0, 64)}...` : activity.descBrief}
										</Box>
									</Box>
								)
							case "MODULE":
								return (
									<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
										<Box sx={{ fontSize: "12px" }}>
											{activity.name.length > 18 ? `${activity.name.substring(0, 18)}...` : activity.name}
										</Box>
										<Box sx={{ fontSize: "10px"}}>
											{activity.descBrief.length > 48 ? `${activity.descBrief.substring(0, 48)}...` : activity.descBrief}
										</Box>
									</Box>
								)
							}
						})()}
					</Box>
					{ showDate && 
					<Box sx={{ fontSize: "10px", whiteSpace: "nowrap" }}>{ calculateDate(activity.createdAt) }</Box>
					}
				</Box>
				</>
			) : (
				<Box sx={{ width: "100%", height: "24px", display: "flex", alignItems: "center" }}>
					<LinearProgress 
						sx={{ borderRadius: "2px", width: "100%" }}
						variant="indeterminate"
					/>
				</Box>
			)}
		</Button>
	)
}
