import { useContext } from 'react';
import { Box, Button, Select, MenuItem, Slider } from '@mui/material';
import InputField from './InputField';
import { FormContext } from './FormContextProvider';
import AutoCompleteSchools from './AutoCompleteSchools';

export default () => {

	const { state, dispatch } = useContext(FormContext);

	return (
		<Box sx={{ 
			display: "flex", 
			flexDirection: "column",
			justifyContent: "space-between",
			color: "#3B3B3B",
			width: "100%",
		}}>
			<Box sx={{
				flexDirection: "column",
				gap: "10px"
			}}>
				<Box sx={{ fontWeight: 550, fontSize: "30px", marginBottom: "24px" }}>Education Details</Box>
				<Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
					<AutoCompleteSchools
						label="School"
						placeholder="Enter your school"
						value={state.school.value}
						dispatch={dispatch}
						field="school"
					/>
					<Box sx={{ display: "flex", alignItems: "center", gap: "20px"}}>
						<Box sx={{ flex: 3, display: "flex", flexDirection: "column", fontSize:"12px", fontWeight: 600 }}>
							<Box sx={{ margin: "0 0 5px 3px"}}>Role</Box>
							<Select
								value={state.role.value}
								onChange={(event)=>dispatch({ type: "INPUT", field: "role", payload: event.target.value})}
								sx={{ height: "36px", minWidth: "122px" }}
							>
								<MenuItem value="STUDENT">Student</MenuItem>
								<MenuItem value="TEACHER">Teacher</MenuItem>
								<MenuItem value="PARENT">Parent</MenuItem>
							</Select>
						</Box>
						<Box sx={{ flex: 1}}>
							<InputField 
								label="Grade" 
								value={state.year.value!==-1 ? state.year.value : "N/A"}
								error={false}
							/>
						</Box>
						<Box sx={{ flex:6}}>
							<Box sx={{ height: "20px" }}/>	
							<Slider
								aria-label="Temperature"
								value={state.year.value}
								onChange={(event)=>dispatch({ 
									type: "INPUT", 
									field: "year", 
									payload: event.target.value 
								})}
								step={1}
								marks
								min={3}
								max={12}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
