import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Graphic from './Graphic';
import FloatingDesc from '../../components/FloatingDesc';
import GradientButton from '../../components/GradientButton';
import bg1Img from '../../../assets/bg_img/1.png';
import ecllipseImg from '../../../assets/bg_img/ecllipse_small.png';
import "../../components/animations.css";

export default () => {

	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<Box sx={{
			backgroundImage: `url(${bg1Img})`,
			backgroundPosition: "0 100%",
			backgroundRepeat: "no-repeat",
			width: "100vw",
			height: "95vh",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		}}>
			<Box sx={{
				display: "flex",
				width: "1125px",
				gap: "64px"
			}}>
				<Box sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					justifyContent: "center",
					color: "#3B3B3B"
				}}>
					<motion.div 
						style={{ fontSize: "36px", fontWeight: 700 }}
						initial={{ y: 36, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ type: "spring", stiffness: 140, damping: 12 }}
					>
						Founders' <span style={{ color: theme.palette.primary.main }}>Foreword</span>
					</motion.div>
					<motion.div 
						style={{ fontSize: "14px", marginTop: "24px" }}
						initial={{ y: 36, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ delay: 0.1, type: "spring", stiffness: 140, damping: 12 }}
					>
            Advance Careers was founded to address the challenges faced by Australian youth struggling to navigate their post-high school life and career paths. Drawing on our own past experiences, we strive to provide a central hub of information to facilitate their journey and exploration of life and careers post-high school. 
					</motion.div>
					<motion.div
						style={{ fontSize: "14px", marginTop: "12px" }}
						initial={{ y: 36, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ delay: 0.1, type: "spring", stiffness: 140, damping: 12 }}
					>
 						We firmly believe in nurturing today's youth as tomorrow's leaders by not only equipping them with essential skills and guidance, but also fostering qualities such as hard work, compassion, empathy, and curiosity. We are committed to making a difference together.
					</motion.div>
					<motion.div
						initial={{ y: 36, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
					>
						<GradientButton 
							boxShadow 
							sx={{ width: "200px", marginTop: "36px" }}
							clickHandler={()=>navigate("/contact")}
						>
							Contact us
						</GradientButton>
					</motion.div>
				</Box>
				<Graphic />
			</Box>
		</Box>
	)
}
