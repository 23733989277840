import React, { useState, useEffect } from 'react';
import { Box, InputBase, Collapse, Button, CircularProgress } from '@mui/material';
import { useGetReplies, useAddReply } from '../../../api/commentApi';
import ImagesSection from './ImagesSection';
import RepliesSection from './RepliesSection';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ForumProfile from './ForumProfile';

export default ({ answer, bestAnswer }) => {

	const [ reply, setReply ] = useState("");
	const addReplyMutation = useAddReply();
	const getRepliesQuery = useGetReplies(answer._id);

	const createdDate = new Date(answer.createdAt);
	const updatedDate = new Date(answer.updatedAt);
	const currentDate = new Date();

	const postDate = Math.round((currentDate - createdDate)/86400000);

	const submitReply = () => {
		addReplyMutation.mutate({
			description: reply,
			questionId: answer.questionId,
			commentId: answer._id,
			userId: answer.userId,
			replies: []
		})
		setReply("");
	}

	return (
		<Box sx={{
			display: "flex",
			flexDirection: "column"
		}}>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<ForumProfile
					userId={answer.userId}
					postDate={postDate}
				/>
			</Box>
			<Box sx={{ padding: "12px 6px" }}>
				{answer.description}
				<Box sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					marginTop: '20px'
				}}>
					<ImagesSection 
						imgs={answer.picturePath}
					/>
				</Box>
			</Box>
			<Box sx={{ padding: "0 6px", display: "flex", flexDirection: "column", gap: "10px" }}>
				<InputBase
					placeholder="Type reply..."
					value={reply}
					onChange={(e)=>setReply(e.target.value)}
				/>
				<Collapse in={reply!==""}>
					<Box sx={{ display: "flex", gap: "6px" }}>
						<Button 
							sx={{ width: "90px", height: "26px", fontSize: "12px" }}
							variant="contained"
							onClick={submitReply}
						>
							Reply
						</Button>
						<Button 
							sx={{ width: "90px", height: "26px", fontSize: "12px" }}
							variant="outlined"
							onClick={()=>setReply("")}
						>
							Cancel
						</Button>
					</Box>
				</Collapse>
			{ getRepliesQuery.isSuccess ? (
					<RepliesSection getRepliesQuery={getRepliesQuery}/>
			) : (
				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<CircularProgress
						variant="indeterminate"
						width="60px"
					/>
				</Box>
			)}
			</Box>
		</Box>
	)	
}
