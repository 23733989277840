import { useMainContext } from '../../providers/MainProvider';
import { useNavigate } from 'react-router-dom';
import { useReducer, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Slider, Button, Switch, Select, MenuItem, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputField from './components/InputField';
import InputFieldSearch from './components/InputFieldSearch';
import AutoCompleteSchools from './components/AutoCompleteSchools';
import { useRegister, useGetUser } from '../../api/authApi.js';

export default ({ setOpen, setShowError, errorMsg, setErrorMsg }) => {

	const navigate = useNavigate();
	const theme = useTheme();
	const { authType, setAuthType } = useMainContext();

	const initialFormState = {
		firstName: { value: "", valid: true },
		lastName: { value: "", valid: true },
		email: { value: "", valid: true },
		password: { value: "", valid: true },
		year: { value: 9, valid: true },
		school: { value: "", valid: true },
		subscribed: { value: false, valid: true },
		role: { value: "STUDENT", valid: true }
	}

	const regex = {
		firstName: /^[a-zA-Z]+$/,
		lastName: /^[a-zA-Z]+$/,
		email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
		password: /^.{6,}$/, 
		year: /^(-1|3|4|5|6|7|8|9|10|11|12)$/,
		school: /.+/,
		role: /.+/
	}

	const formReducer = (state, action) => {
		switch(action.type) {
			case "INPUT":
				return {...state, [action.field]: { ...state[action.field], value: action.payload }}
			case "VALIDATE":
				return { 
					...state, 
					[action.field]: { 
						...state[action.field], 
						valid: regex[action.field].test(action.payload) 
					}
				}
		}
	}

	const queryClient = useQueryClient();
	const [ form, dispatchForm ] = useReducer(formReducer, initialFormState);
	const registerMutation = useRegister({ navigate, setShowError, setErrorMsg });

	useEffect(() => {
		if(form.role.value==="STUDENT") {
			dispatchForm({ type: "INPUT", field: "year", payload: initialFormState.year.value });	
			dispatchForm({ type: "INPUT", field: "school", payload: initialFormState.school.value });	
		}
		else {
			dispatchForm({ type: "INPUT", field: "year", payload: -1 });	
			dispatchForm({ type: "INPUT", field: "school", payload: "N/A" });	
		}
	}, [form.role]);


	if(registerMutation.isSuccess) {		
		return (
			<Box sx={{ fontWeight: 500, fontSize: "18px", width: "400px", textAlign: "center" }}>
				<Box sx={{ fontSize: "22px" }}>Success!</Box> 
				Please check your email 
				<Box sx={{ color: theme.palette.primary.main }}>{form.email.value}</Box> 
				for further instructions
			</Box>
		)
	}
	else if(registerMutation.isError) {		
		return (
			<Box sx={{ 
				display: "flex", 
				flexDirection: "column", 
				alignItems: "center", 
				gap: "14px", 
				width: "400px" 
			}}>
				<Box sx={{ fontWeight: 500, fontSize: "18px", textAlign: "center" }}>{ errorMsg }</Box>
				<Button 
					variant="contained"
					sx={{ width: "160px" }}
					onClick={()=>registerMutation.reset()}
				>
					Try Again
				</Button>
			</Box>
		)
	}
	else if(registerMutation.isLoading) {
		return (
			<Box sx={{ 
				width: "100%", 
				height: "100%", 
				display: "flex", 
				alignItems: "center", 
				justifyContent: "center"
			}}>
				<CircularProgress
					variant="indeterminate"
					size="60px"
				/>
			</Box>
		)
	}
	else {
		return (
			<Box sx={{ 
				width: "500px",
				display: "flex",
				flexDirection: "column",
				borderRadius: "32px",
				color: "#3b3b3b",
				backgroundColor: "white"
			}}>
				<Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginBottom: "16px" }}>
					<Box sx={{ fontSize: "28px", fontWeight: 650 }}>Register</Box>
					<Box sx={{ fontSize: "16px", fontWeight: 400 }}>Get started today!</Box>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column"}}>
					<Box sx={{ display: "flex", justifyContent: "stretch", margin: "10px 0" }}>
						<InputField 
							label="First Name" 
							placeholder="Enter First Name"
							field="firstName"
							value={form.firstName.value}
							error={!form.firstName.valid}
							dispatch={dispatchForm}
						/>
						<Box sx={{ width: "30px" }}/>
						<InputField 
							label="Last Name" 
							placeholder="Enter Last Name"
							field="lastName"
							value={form.lastName.value}
							error={!form.lastName.valid}
							dispatch={dispatchForm}
						/>
					</Box>
					<Box sx={{ margin: "8px 0"}}>
						<InputField 
							label="Email Address" 
							placeholder="Enter your email address"
							field="email"
							value={form.email.value}
							error={!form.email.valid}
							dispatch={dispatchForm}
						/>
					</Box>
					<Box sx={{ marginTop: "8px"}}>
						<InputField 
							label="Password" 
							placeholder="******" 
							type="password" 
							tip="Must be at least 6 characters"
							field="password"
							value={form.password.value}
							error={!form.password.valid}
							dispatch={dispatchForm}
						/>
					</Box>
					<Box sx={{ marginTop: "8px", display: "flex", justifyContent: "stretch", alignItems: "center", gap: "10px"}}>
						<AutoCompleteSchools
							label="School" 
							placeholder="Enter your school" 
							field="school"
							value={form.school.value}
							dispatch={dispatchForm}
							disabled={form.year.value===-1}
						/>
					</Box>
					<Box sx={{ margin: "14px 0", display: "flex", alignItems: "center", gap: "20px"}}>
						<Box sx={{ flex: 3, display: "flex", flexDirection: "column", fontSize:"12px", fontWeight: 600 }}>
							<Box sx={{ margin: "0 0 5px 3px"}}>Role</Box>
							<Select
								value={form.role.value}
								onChange={(event)=>dispatchForm({ type: "INPUT", field: "role", payload: event.target.value})}
								displayEmpty
								sx={{ height: "36px" }}
							>
								<MenuItem value="STUDENT">Student</MenuItem>
								<MenuItem value="UNI STUDENT"> Uni Student</MenuItem>
								<MenuItem value="TEACHER">Teacher</MenuItem>
								<MenuItem value="PARENT">Parent</MenuItem>
							</Select>
						</Box>
						<Box sx={{ flex: 1}}>
							<InputField 
								label="Grade" 
								field="year"
								value={form.year.value!==-1 ? form.year.value : "N/A"}
								disabled={form.year.value===-1}
								error={!form.year.valid}
								dispatch={dispatchForm}
							/>
						</Box>
						<Box sx={{ flex:6}}>
							<Box sx={{ height: "20px" }}/>	
							<Slider
								aria-label="Temperature"
								value={form.year.value}
								disabled={form.year.value===-1}
								onChange={(event)=>dispatchForm({ 
									type: "INPUT", 
									field: "year", 
									payload: event.target.value 
								})}
								step={1}
								marks
								min={3}
								max={12}
							/>
						</Box>
					</Box>
				</Box>
				<Box sx={{ margin: "10px 0", display: "flex", alignItems: "center", fontSize: "12px", fontWeight: 500 }}>
					<Switch value={form.subscribed.value}/>
					<Box>Sign me up for notifications and news</Box>
				</Box>
				<Box sx={{ display: "flex", marginTop: "24px" }}>
					<Button 
						variant="contained" 
						sx={{ width: "100%" }}
						onClick={async ()=>{
							let valid = true;
							Object.keys(form).forEach((key)=>{
								if(typeof form[key].value === "string" ) {
									dispatchForm({ type: "VALIDATE", field: key, payload: form[key].value });
								}
								valid = valid && form[key].valid && form[key].value!=="";
							});						
							if(valid) {
								await registerMutation.mutate({
									name: form.firstName.value + " " + form.lastName.value,
									email: form.email.value,
									password: form.password.value,
									year: Number(form.year.value),
									school: form.school.value,
									subscribed: form.subscribed.value,
									role: form.role.value
								});
							}
							else {
								setErrorMsg("Invalid entries");
								setShowError(true);	
							}
						}}
					>
						Register
					</Button>
				</Box>
				<Box sx={{ display: "flex", fontSize: "12px", fontWeight: 500, marginTop: "10px" }}>
					Already have an account?
					<Box 
						sx={{ 
							marginLeft: "5px", 
							fontWeight: 600, 
							color: theme.palette.primary.main,
							cursor: "pointer"
						}}
						onClick={()=>{
							setAuthType("LOGIN");
						}}
					>
						Sign in
					</Box>
				</Box>
			</Box>
		)
	}
}
