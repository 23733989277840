import React, { useState } from 'react';
import { useMainContext } from '../../../providers/MainProvider';
import { AnimatePresence } from 'framer-motion';
import { Tab, Tabs, Button, Box } from '@mui/material';

export default ({imgs, selectedImg, setSelectedImg}) => {

	const { baseURL } = useMainContext();

	return (
		<Box sx={{ display: "flex", gap: "8px" }}>
			{ imgs.map((img) => (
				<Box
					key={img} 
					variant="light-blue-fill"
					sx={{ 
						display: "flex", 
						alignItems: "center",
						gap: "6px",
						height: "60px",
						cursor: "pointer"
					}}
					onClick={()=>setSelectedImg(img)}
				>
					<img 
						src={`${baseURL}${img}`} 
						style={{ height: "100%", borderRadius: "4px" }}
					/>
				</Box>
			))}
		</Box>
	)	
}
